import * as React from "react";
import {createRef, CSSProperties} from "react";
import {CV_32S, Mat} from "mirada";

type CBARDebugViewProps = {
    style?: CSSProperties
    children?:React.ReactNode
}

type CBARDebugViewState = {
    debugText:string|undefined
}

const BASE_OPACITY = 1.0;

export class CBARDebugView extends React.Component<CBARDebugViewProps, CBARDebugViewState> {

    private canvas = createRef<HTMLCanvasElement>();

    public imShow(image:Mat, text?:string) {
        if (this.canvas.current) {
            //if (image.type())
            if (image.depth() === cv.CV_8U) {
                cv.imshow(this.canvas.current, image);
            } else {
                const img = new cv.Mat();
                image.convertTo(img, cv.CV_8U, 1, 0);
                cv.imshow(this.canvas.current, img);
                img.delete();
            }
        }
        if (text) {
            this.debugText = text;
        }
        this.visible = true;
    }

    debugView = createRef<HTMLDivElement>()

    public get visible() {
        return this.debugView.current?.style.visibility !== "hidden";
    }

    public set visible(value) {
        if (this.debugView.current) {
            this.debugView.current.style.visibility = value ? "visible" : "hidden";
        }
    }

    public get debugText() {
        return this.state ? this.state.debugText : undefined;
    }

    public set debugText(value) {
        this.setState({debugText:value});
        this.visible = true;
    }

    onMouse = (over:boolean) => {
        if (this.debugView.current) {
            this.debugView.current.style.opacity = `${(over ? 1.0 : BASE_OPACITY).toFixed(2)}`;
        }
    }

    render() {
        return (
            <div id={"debug-view"} ref={this.debugView}
                 style={{
                     userSelect:"none", position: "absolute",
                     top:0, right:0,
                     pointerEvents:"auto",
                     visibility:"hidden",
                     display:"flex",
                     flexDirection:"column",
                     border:'1px solid rgba(255,255,255, 0.7)',
                     opacity:BASE_OPACITY,
                     ...this.props.style}}
                 onMouseOver={()=>this.onMouse(true)} onMouseOut={()=>this.onMouse(false)}>
                <div id={"debug-view-status-bar"} style={{
                    width:"100%",
                    color:"#fff", background:"#0af", padding:3,
                    display:"flex",
                    justifyContent:"flex-end"
                }}>
                    {this.props.children}
                    <div onClick={()=>{this.visible = false}} style={{cursor:"pointer", border:"1px solid #fff", padding:7}}>&nbsp;X&nbsp;</div>
                </div>
                <div style={{position:"relative"}}>
                    <canvas ref={this.canvas} style={{width:"100%", height:"100%"}} />
                    {this.state && this.state.debugText && <div style={{position: "absolute", top:10, left:20, color:"#fff"}}>{this.state.debugText}</div>}
                </div>
            </div>)
    }
}