import {SceneCollectionConfig} from "cambrian-base";
import {DataItem, MonitoredArray} from "./DataItem";
import {SwatchItem} from "./SwatchItem";
import {ProductBrand} from "./ProductBrand";
import {SceneInfo} from "./SceneInfo";

export class SceneCollection extends DataItem implements SwatchItem {

    constructor(dataUrl?:string) {
        super(dataUrl);
        this.description = "scene collection";
    }

    public _brand?: ProductBrand;
    public collection?: SceneCollection;

    public _collections: MonitoredArray<SceneCollection> = new MonitoredArray(()=>this.needsUpdate());
    public get collections() {
        return this._collections.sort((a, b) => a.orderIndex - b.orderIndex)
    }
    public set collections(value) {
        this._collections = new MonitoredArray(()=>this.needsUpdate(), ...value)
    }

    public _scenes: MonitoredArray<SceneInfo> = new MonitoredArray(()=>this.needsUpdate());
    public get scenes() {
        return this._scenes.sort((a, b) => a.orderIndex - b.orderIndex)
    }
    public set scenes(value) {
        this._scenes = new MonitoredArray(()=>this.needsUpdate(), ...value)
    }

    public get brand(): ProductBrand {
        return this.collection ? this.collection.brand : this._brand!
    }

    public set brand(value) {
        if (!this.collection) {
            this._brand = value
        }
    }

    public get path(): string {
        return `${this.dataUrl ? this.dataUrl : this.code}`
    }

    public get parent():DataItem|undefined {
        return this.collection ? this.collection : this._brand
    }

    public get children(): DataItem[] {
        return this._collections.length ? this.collections : this.scenes
    }

    public childName(plural:boolean): string {
        return plural ? "Scenes" : "Scene"
    }

    public get hasColumns(): boolean {
        return true
    }

    public load(json:SceneCollectionConfig) {
        super.load(json);

        this._collections.length = 0;
        if (json.collections) {
            for (const collectionJson of json.collections) {
                const collection = new SceneCollection();
                collection.collection = this;
                collection.load(collectionJson);
                if (collection.enabled) {
                    this._collections.push(collection)
                }
            }
        }

        this._scenes.length = 0;
        if (json.scenes) {
            for (const sceneJson of json.scenes) {
                const scene = new SceneInfo();
                scene.collection = this;
                scene.load(sceneJson);
                if (scene.enabled) {
                    this._scenes.push(scene)
                }
            }
        }
    }
}