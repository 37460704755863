import {CBARObject} from "./CBARObject";
import {CBARCollection} from "../CBARCollection";
import {CBARImage} from "./CBARImage";
import * as THREE from "three";

export type CBARImageDictionary = {[name:string]:string}

export class CBARImageCollection extends CBARObject<CBARImageCollection> implements CBARCollection<CBARImage> {

    private _images: { [id: string] : CBARImage; } = {};

    public get values()  {
        return this._images
    }

    public add(asset:CBARImage) {
        if (!asset.name) {
            asset.name = THREE.MathUtils.generateUUID()
        }
        this._images[asset.name] = asset
    }

    public containsKey(key:string) : boolean {
        return this._images.hasOwnProperty(key);
    }

    public remove(asset:CBARImage) {
        if (!asset.name || !this.containsKey(asset.name)) return;
        delete this._images[asset.name];
    }

    public length(): number {
        return Object.keys(this._images).length
    }

    public clearAll() {
        for (const image of this.all()) {
            image.unload()
        }
        this._images = {}
    }

    public first() {
        const length = this.length();
        if (length) {
            return this.all()[0];
        }
    }

    public last() {
        const length = this.length();
        if (length) {
            return this.all()[length-1];
        }
    }

    public all() {
        return Object.values(this._images)
    }

    private _basePath?:string;

    // @ts-ignore clean this up later
    load(basePath:string|undefined, json:CBARImageDictionary, room?:string|null|undefined, subroom?:string|null|undefined) : Promise<CBARImageCollection> {
        this._basePath = basePath;
        const promises:any[] = [];

        for (const name in json) {
            if (!json.hasOwnProperty(name)) continue

            let path = json[name] as any;
            if (typeof path === 'string') {
                if (room && subroom) {
                    const token = "/";
                    const filename = path.substr(path.lastIndexOf(token) + token.length);

                    const imageName = filename.substring(0, filename.lastIndexOf('.') > 0 ? filename.lastIndexOf('.') : undefined).replace(/[^0-9a-z]/gi, '');
                    const root = path.substr(0, path.indexOf(room));
                    path = `${root}${room}/${subroom}/${imageName}`
                    //console.log(`Load image ${path} new path:${newPath}`)
                }
                const image = new CBARImage(this.context, name);
                promises.push(image.load(basePath, path));
                this.add(image);
            } //otherwise, maybe another image collection? see scene.images.masks
        }

        return new Promise<CBARImageCollection>((resolve, reject) => {
            Promise.all(promises).then(()=>{
                resolve(this)
            }).catch(error=>{
                reject(error)
            })
        })
    }

    public data() : any {
        const imageJSON:any = {};
        for (const key in this._images) {
            imageJSON[key] = this._images[key].path
        }

        return imageJSON
    }

    get description() : string {
        return "Image collection"
    }

    unload() {
        this.clearAll()
    }
}