import {Brand, ImageCrop, ProductItemConfig} from "cambrian-base";
import {DataItem} from "./DataItem";
import {ProductTexture} from "./ProductTexture";
import {Product} from "./Product";
import {ProductCollection} from "./ProductCollection";

export abstract class ProductItem extends DataItem {
    protected _ppi?:number|undefined;
    protected _scale:number|undefined;
    protected _textures:ProductTexture[] = [];
    protected _mirrored:boolean|undefined;
    protected _mirroredX:boolean|undefined;
    protected _mirroredY:boolean|undefined;
    protected _crop:ImageCrop|undefined;

    public get ppi():number|undefined {
        return this._ppi
    }

    public set ppi(value) {
        this._ppi = value
    }

    public get scale():number|undefined {
        return this._scale
    }

    public set scale(value) {
        this._scale = value
    }

    public get textures():ProductTexture[] {
        return this._textures
    }

    public get mirrored():boolean|undefined {
        return this._mirrored
    }

    public set mirrored(value) {
        this._mirrored = value
    }

    public get mirroredX():boolean|undefined {
        return this._mirroredX
    }

    public set mirroredX(value) {
        this._mirroredX = value
    }

    public get mirroredY():boolean|undefined {
        return this._mirroredY
    }

    public set mirroredY(value) {
        this._mirroredY = value
    }

    public get crop():ImageCrop|undefined {
        return this._crop
    }

    public set crop(value) {
        this._crop = value
    }

    public abstract get product():Product|undefined;

    public abstract get collection():ProductCollection|undefined;

    public load(json:ProductItemConfig) {
        super.load(json);

        this._ppi = json.ppi;
        this._scale = json.scale;
        this._details = json.details;
        this._mirrored = json.mirrored;
        this._mirroredX = json.mirroredX;
        this._mirroredY = json.mirroredY;
        this._crop = json.crop;

        if (json.albedo) {
            //just load a single texture
            const texture = new ProductTexture(this);
            texture.load(json);
            this._textures.push(texture)
        } else if (json.textures) {
            for (let textureJson of json.textures) {
                const texture = new ProductTexture(this);
                texture.load(textureJson);
                if (texture.enabled) {
                    this._textures.push(texture)
                }
            }
        }
    }
}