import {CBARProcessNode} from "./CBARProcessNode";
import {CBARFrame} from "../CBARFrame";
import {CBARPipeline, CBARPipelineTask} from "./CBARPipeline";
import {CBAROpticalFlowNode, OpticalFlowCluster, OpticalFlowResult} from "./CBAROpticalFlowNode";
import {euclideanDistSq} from "../Math";
import {CBARTrackedObject} from "./CBARTrackedObject";
import * as gm from "gammacv";
import {CBARDebug} from "../../CBARContext";
import {getConfig} from "../../../backend";

export type CBARFeatureTrackerConfig = {
    maxFeatures?: number,
}

const _defaults:CBARFeatureTrackerConfig = {
    maxFeatures: 30,
}

export class CBARFeatureTrackerNode extends CBARProcessNode {

    public config:CBARFeatureTrackerConfig;

    constructor(context:CBARPipeline, _config?:CBARFeatureTrackerConfig) {
        super(context)

        this.config = {..._defaults, ..._config};

        this.pipeline.subscribeToTask<OpticalFlowResult>(CBARPipelineTask.OpticalFlow, (result)=>{
            this.updateIntersections(result);
            return this.enabled;
        })
    }

    private _candidates:CBARTrackedObject[] = [];
    private _trackedObjects:CBARTrackedObject[] = [];

    track(obj:CBARTrackedObject) {
        this._candidates.push(obj)
    }

    get trackedObjects() {
        return this._trackedObjects;
    }

    update(frame:CBARFrame):void {

        let oflow = this.pipeline.nodes.find(n=>n instanceof CBAROpticalFlowNode) as CBAROpticalFlowNode;

        if (!oflow) {
            oflow = new CBAROpticalFlowNode(this.pipeline)
            this.pipeline.nodes.push(oflow);
        }

        this._candidates.forEach(candidate=>{

            const matches = this._trackedObjects.filter(obj=>{
                return obj.isMatch(candidate);
            }).sort((a,b)=>{
                const distanceA = euclideanDistSq(a.midpoint, candidate.midpoint);
                const distanceB = euclideanDistSq(b.midpoint, candidate.midpoint);
                return distanceA === distanceB ? 0 : (distanceA < distanceB ? -1 : 1);
            });

            if (matches.length) {
                const match = matches[0];
                match.trackingFrameIndex = match.frameIndex = frame.index;
                match.frameTime = frame.time;
                match.confirmations ? match.confirmations++ : match.confirmations=1;
                match.merge(candidate);
                if (match.cluster && oflow) {
                    match.cluster.roi = candidate.roi;
                }
            } else {
                //New point
                this._trackedObjects.push(candidate);

                if (oflow && candidate.roi.area) {
                    //set to bounds of detected roi
                    candidate.cluster = oflow.trackRegion(new OpticalFlowCluster(candidate.roi, oflow.config.qualityLevel / 2.0));
                }
            }
        })

        this._candidates = [];

        const trackedObjects = this._trackedObjects.filter(obj=>{
            const age = frame.time - obj.frameTime;
            return age < obj.maxAge;
        }).slice(0, this.config.maxFeatures);

        if (oflow) {
            //untrack no longer used regions
            this._trackedObjects.filter(o=>!!o.cluster && trackedObjects.indexOf(o) < 0).map(o=>o.cluster!).forEach(region=>{
                oflow.untrackRegion(region);
            })
        }

        this._trackedObjects = trackedObjects;
    }

    updateIntersections(flow:OpticalFlowResult) {

        this._trackedObjects.forEach(obj=>{

            //invalidate all removed flow points
            flow.removed.forEach(o=>obj.invalidate(o))

            //assign new an optical flow point for each tracked line point
            obj.assign(obj.cluster ? obj.cluster.features : flow.points);
        })
    }

    debug(canvas:HTMLCanvasElement):void {

        if (CBARDebug.TrackedLines === (this.pipeline.config.debug & CBARDebug.TrackedLines)) {
            this._trackedObjects.filter(i=>i.confirmations).forEach(feature=>{
                const value = feature.confirmations!;
                let color = 'rgba(255, 140, 0, 1.0)';
                if (value > 20) color = 'rgba(50, 255, 0, 1.0)';
                else if (value > 15) color = 'rgba(180, 255, 0, 0.9)';
                else if (value > 5) color = 'rgba(255, 255, 0, 0.8)';
                else if (value > 1) color = 'rgba(255, 255, 0, 0.8)';

                for (let i=0; i<feature.points.length; i++) {
                    const pointA = feature.points[i].point;
                    const pointB = feature.points[(i+1) % feature.points.length].point;
                    gm.canvasDrawLine(canvas, [pointA.x, pointA.y, pointB.x, pointB.y], color, 5);
                }
            })
        }
    }

    destroy() {
        this._trackedObjects = [];
    }
}