import {
    CBServerApi,
    CBServerConfig,
    getConfig,
    GetUploadUrlsRequest,
} from "./CBServerApi";
import {
    CBARScene, CBARSurfaceType,
    CBARUploadNames,
    Dictionary
} from "../core";

export type UploadFileRequests = {[name: string]: ImageSource}

export type UploadFileResult = {
    name:string
    url:string
}

export enum UploadStatus {
    Connected = "Contacting Server",
    Uploading = "Photo is being uploaded",
    Processing = "Running Algorithms",
    Completed = "Complete",
    Failed = "Upload failed"
}

if (!(window as any)._contentManager) {
    getConfig().then(config=>{
        (window as any)._contentManager = new CBContentManager(config)
    })
}

export type ImageSource = HTMLImageElement | HTMLCanvasElement | OffscreenCanvas

export type UrlDict = {[name: string]: string}

export class CBContentManager {

    private _roomId:string|undefined;
    public get roomId() {
        return this._roomId;
    }

    private roomUploadUrl:string|undefined;

    private _subroomId:string|undefined;
    public get subroomId() {
        return this._subroomId;
    }

    private _tempSubroomId:string|undefined;

    private roomSubUploadUrls:{[name: string]: string} = {};
    private _uploadNames:string[] = Object.values(CBARUploadNames);
    private _uploadsNeedSync = true;

    private readonly imageProcessor:CBServerApi;

    public static get default(): CBContentManager | undefined {
        return (window as any)._contentManager
    }

    constructor(private config:CBServerConfig) {
        this.imageProcessor = new CBServerApi(config.processingUrl)
    }

    public registerImage(name:string) {
        if (this._uploadNames.indexOf(name) >= 0) return;

        //console.log("Register image", name);

        this._uploadsNeedSync = true;
        this._uploadNames.push(name);
    }

    public registerImages(names:string[]) {
        names.forEach(name=>this.registerImage(name))
    }

    public unregisterImage(name:string) {
        const index = this._uploadNames.indexOf(name);
        if (index >= 0) {
            this._uploadNames.splice(index, 1);
        }
    }

    public get uploadNames() {
        return this._uploadNames;
    }

    private initiateUploads() : Promise<string> {
        const uploadNamesString = this.uploadNames.join(',');
        //console.log(`initiating uploads for ${uploadNamesString}`);

        return new Promise((resolve,reject)=>{

            const request: GetUploadUrlsRequest = {names: this._uploadNames};

            if (this.roomId) {
                request.key = this.roomId
            }

            this.imageProcessor.getUploadUrls(request).then(response=>{

                if (!response) {
                    reject(new Error("Image uploader unavailable."));
                    return;
                }

                if (!this._roomId) {
                    this._roomId = response.key;
                    this.roomUploadUrl = response.uploadUrl || undefined;
                    this._tempSubroomId = response.subKey;
                    console.log(`room ID now ${this.roomId}`)
                } else {
                    this._subroomId = response.subKey;
                    console.log(`subroom ID now ${this.subroomId}`)
                }

                this.roomSubUploadUrls = response.subUploadUrls;

                //console.log(`subroom ID now ${this.subroomId}`);
                //console.log(`Cambrian AR uploader initialized for ${uploadNamesString}`);

                resolve(uploadNamesString);
            }).catch(error=>{
                reject(error);
            });
        });
    }

    public resetScene() {
        //console.log("Clear Room ID, Subroom ID");
        this._roomId = undefined;
        this._subroomId = undefined;
        this.roomSubUploadUrls = {}
    }

    public synchronize(searchObject:any) : any {
        if (!searchObject) {
            searchObject = {}
        }
        const roomId = searchObject.room as string;
        if (roomId && !this._roomId) {
            this._roomId = roomId;
            this._subroomId = undefined;//clear
            console.log(`Room ID now ${this._roomId}`);
        }

        const subroomId = searchObject.subroom as string;
        if (subroomId && !this.subroomId) {
            this._subroomId = subroomId;
            console.log(`Subroom ID now ${this._subroomId}`);
        }

        if (this.roomId) {
            searchObject.room = this.roomId;
        }

        if (this.subroomId) {
            searchObject.subroom = this.subroomId;
        }
        return searchObject
    }

    public async uploadRoom(image:File | Blob, fov:number|undefined, acceleration:[number, number, number]|undefined, onProgress?:(progress:number, status:UploadStatus)=>void) : Promise<string|null> {

        if (onProgress) onProgress(0.1, UploadStatus.Connected);

        if (!this.roomUploadUrl || !this.roomId) {
            await this.initiateUploads();
        }

        if (!this.roomUploadUrl) {
            console.error("No room upload url");
            return null
        }

        if (!this.roomId) {
            console.error("No room ID");
            return null
        }

        // Upload the dropped image
        if (onProgress) onProgress(0.3, UploadStatus.Uploading);

        let params: { [key: string]: string} = {};

        if (fov) {
            params['fov'] = `${fov}`;
        }

        //console.log(fov ? "fov was supplied" : "fov was NOT supplied");

        if (acceleration) {
            params['ax'] = `${acceleration[0]}`;
            params['ay'] = `${acceleration[1]}`;
            params['az'] = `${acceleration[2]}`;
        }

        const success = await this.imageProcessor.upload(this.roomUploadUrl, image);

        if (!success) {
            console.error("imageUploader Failed");
            return null
        }

        if (onProgress) onProgress(0.75, UploadStatus.Processing);

        const results = await this.imageProcessor.processImage(this.roomId, params);

        if (onProgress) onProgress(1.0, UploadStatus.Completed);

        return results
    }

    public async uploadFile(image: ImageSource, name: string) {
        this.registerImage(name);

        if (this._uploadsNeedSync) {
            await this.initiateUploads();
        }

        return this._uploadFile(image, name);
    }

    public async uploadFiles(files: {[name: string]: ImageSource}) : Promise<UrlDict> {
        const names = Object.keys(files)
        this.registerImages(names);

        if (this._uploadsNeedSync) {
            await this.initiateUploads();
        }

        const promises:Promise<string>[] = []
        for (const name in files) {
            promises.push(this._uploadFile(files[name], name))
        }
        return Promise.all(promises).then(urls=>{
            const results: UrlDict = {}
            urls.forEach((url, index)=>results[names[index]] = url)
            return results
        })
    }

    private _uploadFile(image: ImageSource, name: string) : Promise<string> {
        if (!this.subroomId && this._tempSubroomId) {
            this._subroomId = this._tempSubroomId;
        }

        return new Promise<string>((resolve, reject)=>{
            const url = this.roomSubUploadUrls[name];

            if (!this.roomId) {
                reject(new Error(`Upload of ${name} is impossible without room ID.`));
                return null;
            }

            if (!this.subroomId) {
                reject(new Error(`Upload of ${name} is impossible without subroom ID.`));
                return null;
            }

            const mediaPath = `${this.config.hostingUrl}/${this.roomId}/${this.subroomId}/${name}`;

            if (!url) {
                reject(new Error(`Upload of ${name} is an unknown name. Was it passed during initialization?`));
                return
            }

            const img = CBContentManager.imageToCanvasContext(image);
            if (!img) {
                reject(new Error(`Upload failed, likely client side memory problems`));
                return
            }

            // Try to convert the edited mask canvas to a blob
            const filePromise = new Promise<Blob>((resolve, reject) => {
                img.canvas.toBlob(blob => {
                    if (blob) {
                        resolve(blob)
                    } else {
                        reject(new Error("Invalid blob data"));
                    }
                }, "image/jpeg", 80)
            });

            filePromise.then(file=>{
                //console.log(`Uploading file "${name}"`);
                const uploadPromise = this.imageProcessor.upload(url, file);

                if (!uploadPromise) {
                    reject(new Error(`Upload of ${name} FAILED`));
                    return null
                }
                uploadPromise.then(success=>{
                    if (success) {
                        console.log(`File "${name}" uploaded successfully`);
                        delete this.roomSubUploadUrls[name];
                        resolve(mediaPath);
                    } else {
                        reject(new Error(`Upload of ${name} failed`));
                    }
                })

            }).catch(error=>reject(error));
        })
    }

    public uploadScene(scene:CBARScene, images:UploadFileRequests, onProgress?:(progress:number, result:UploadFileResult|null)=>void) {

        if (onProgress) {
            onProgress(0.0, null);
        }

        const viableSurfaces = scene.geometry.surfaces.filter(s=>s.type === CBARSurfaceType.Floor || s.type === CBARSurfaceType.Wall);
        const uploadRequests:UploadFileRequests = {...images};

        //all masks needing update
        for (const surface of viableSurfaces) {
            if (surface.maskImage?.image.src && surface.maskTexture?.canvas) {
                const fullPath = surface.maskImage.image.src;
                const token = "/";
                const filename = fullPath.substr(fullPath.lastIndexOf(token) + token.length);
                const imageName = filename.substring(0, filename.lastIndexOf('.') > 0 ? filename.lastIndexOf('.') : undefined).replace(/[^0-9a-z]/gi, '');
                uploadRequests[imageName] = surface.maskTexture.canvas;
            }
        }

        //register all
        for (const imageName of Object.keys(uploadRequests)) {
            //console.log("Uploading", imageName);
            this.registerImage(imageName);
        }

        return new Promise(()=>{
            this.initiateUploads().then(()=>{

                const uploadPromises:Promise<string>[] = [];

                for (const imageName of Object.keys(uploadRequests)) {
                    uploadPromises.push(this._uploadFile(uploadRequests[imageName], imageName))
                }

                return Promise.all(uploadPromises)
            });
        });
    }

    // https://stackoverflow.com/a/5100158/4332314
    public dataURItoBlob(dataURI: string) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i)
        }

        return new Blob([ia], { type: mimeString })
    }

    public static get hasMobileShare() {
        const nav: any = window.navigator;
        return !!(nav && nav.share)
    }

    public static getImageCrop(image:ImageSource, width:number, height:number) {
        const imageAspectRatio = image.width / image.height;
        const canvasAspectRatio = width / height;

        let cropW = image.width, cropH = image.height, cropX = 0, cropY = 0;

        if (imageAspectRatio < canvasAspectRatio) {
            cropH = cropW / canvasAspectRatio;
            cropY = (image.height - cropH) / 2 //centered
        } else {
            cropW = cropH * canvasAspectRatio;
            cropX = (image.width - cropW) / 2 //centered horizontally
        }

        return {x:cropX, y:cropY, width:cropW, height:cropH}
    }

    public static getResizedImage(image:ImageSource, width:number, height:number) {

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d')!;

        const crop = this.getImageCrop(image, width, height);

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(image,
            crop.x, crop.y, crop.width, crop.height,
            0, 0, canvas.width, canvas.height);

        return canvas.toDataURL("image/jpeg", 80)
    }

    public static imageToCanvasContext(image: ImageSource) : CanvasRenderingContext2D | null {
        const ctx = document.createElement("canvas").getContext("2d");

        if (!ctx) return null;

        ctx.canvas.width = image.width as number;
        ctx.canvas.height = image.height as number;

        //draw after onto the image below before.
        ctx.drawImage(image, 0, 0);

        return ctx
    }

    public static dataUrlToImage(data: string) : Promise<ImageSource> {
        return new Promise<ImageSource>((resolve, reject) => {
            const image = new Image();
            image.src = data;
            image.onload = () => {
                resolve(image);
            };
            image.onerror = (error) => {
                reject(error);
            };
        });
    }

    public static dataUrlToCanvasContext(data: string) : Promise<CanvasRenderingContext2D> {
        return new Promise<CanvasRenderingContext2D>((resolve, reject) => {
            this.dataUrlToImage(data).then(image=>{
                const canvas = this.imageToCanvasContext(image);
                if (canvas) {
                    resolve(canvas);
                } else {
                    reject(new Error("Invalid image"));
                }
            }).catch(error=>{
                reject(error);
            })
        });
    }

    public static blobToDataUri(blob: Blob) : Promise<string> {
        return new Promise<string>((resolve,reject)=> {
                const fileReader = new FileReader();

                fileReader.onload = () => {
                    const dataUri = fileReader.result as string
                    if (dataUri) {
                        resolve(dataUri)
                    } else {
                        reject()
                    }
                }
                fileReader.onerror = reject
                fileReader.readAsDataURL(blob);
            }
        )
    }
}

export const drawBeforeAfter = (beforeContext:CanvasImageSource, afterContext:CanvasImageSource, isHorizontal?:boolean) : CanvasRenderingContext2D | null => {
    const ctx = document.createElement("canvas").getContext("2d");

    if (!ctx) return null;

    const beforeWidth = beforeContext.width as number;
    const beforeHeight = beforeContext.height as number;
    const beforeAspectRatio = beforeWidth / beforeHeight;

    const afterWidth = afterContext.width as number;
    const afterHeight = afterContext.height as number;
    const afterAspectRatio = afterWidth / afterHeight;

    const isWider = beforeAspectRatio < afterAspectRatio;

    if (isHorizontal) {
        //make image same width and twice the height of the render (afterContext)
        ctx.canvas.width = afterWidth * 2.0;
        ctx.canvas.height = afterHeight;

        const srcWidth = isWider ? beforeWidth : beforeHeight * afterAspectRatio;
        const srcHeight = isWider ? beforeWidth / afterAspectRatio : beforeHeight;
        const offsetX = isWider ? 0 : 0.5 * (beforeWidth - srcWidth);
        const offsetY = isWider ? 0.5 * (beforeHeight - srcHeight) : 0;

        //draw before image at the left
        ctx.drawImage(beforeContext, offsetX, offsetY, srcWidth, srcHeight, 0, 0, 0.5 * ctx.canvas.width, ctx.canvas.height);

        //draw after image onto the context at the bottom, starting at the middle
        ctx.drawImage(afterContext, 0.5 * ctx.canvas.width, 0);
    } else {
        //make image same width and twice the height of the render (afterContext)
        ctx.canvas.width = afterWidth;
        ctx.canvas.height = afterHeight * 2.0;

        const srcWidth = isWider ? beforeWidth : beforeHeight * afterAspectRatio;
        const srcHeight = isWider ? beforeWidth / afterAspectRatio : beforeHeight;
        const offsetX = isWider ? 0 : 0.5 * (beforeWidth - srcWidth);
        const offsetY = isWider ? 0.5 * (beforeHeight - srcHeight) : 0;

        //draw before image at the top
        ctx.drawImage(beforeContext, offsetX, offsetY, srcWidth, srcHeight, 0, 0, ctx.canvas.width, 0.5 * ctx.canvas.height);

        //draw after image onto the context at the bottom, starting at the middle
        ctx.drawImage(afterContext, 0, 0.5 * ctx.canvas.height);
    }

    return ctx;
}