import {CBARTexture} from "./CBARTexture";
import {CBARImage} from "./CBARImage";
import * as THREE from "three";

export class CBARMaskTexture extends CBARTexture {
    public displayCanvas = document.createElement("canvas");

    loadImage(image:CBARImage) : boolean {
        const success = super.loadImage(image, true);
        if (success && this.threeTexture && this.canvas) {
            this.threeTexture.format = THREE.LuminanceFormat;

            this.threeTexture.magFilter = THREE.NearestFilter;
            this.threeTexture.minFilter = THREE.NearestFilter;

            this.displayCanvas.width = this.canvas.width;
            this.displayCanvas.height = this.canvas.height;

            const ctx = this.displayCanvas.getContext("2d", {willReadFrequently:true});
            if (ctx) {
                ctx.drawImage(this.canvas, 0, 0, this.canvas.width, this.canvas.height);
            }
            this.threeTexture.image = this.displayCanvas;

            this.isEditing = false;//refresh
        }

        return success
    }

    private _isEditing?:boolean;

    public blendResults = true;

    get isEditing() {
        return !!this._isEditing;
    }

    set isEditing(val:boolean) {
        if (this._isEditing === val) return;

        this._isEditing = val;
        if (!this._isEditing) {
            this.apply();
        }

        const canvas = this._isEditing ? this.canvas : this.displayCanvas;
        if (canvas) {
            this.showChanges(canvas);
        }
    }

    public showChanges(canvas:HTMLCanvasElement) {
        if (this.threeTexture) {
            this.threeTexture.image = canvas;
            this.threeTexture.needsUpdate = true;
            this.context.refresh();
        }
    }

    protected get blurAmount() {
        if (!this.canvas) return 0;
        return Math.round(Math.hypot(this.canvas.width, this.canvas.height) / 3000);
    }

    private apply() {
        // const src = this.canvas?.getContext('2d');
        // const dest = this.displayCanvas.getContext('2d');
        // if (src && dest && this.blurAmount) {
        //     //dest.filter = `blur(${this.blurAmount.toFixed(0)}px)`;
        //     dest.fillStyle = 'black';
        //     dest.fillRect(0, 0, this.displayCanvas.width, this.displayCanvas.height);
        //     dest.drawImage(src.canvas, 0, 0);
        //     dest.save();
        // }
    }
}