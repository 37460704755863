import {CBARAssetProperties, CBARTangibleAsset} from "./CBARAsset";
import {CBARSurface} from "../components/CBARSurface";
import * as THREE from "three";
import {CBAREvent, CBAREventType, CBARHighlightState, CBARMouseEvent, CBARToolMode} from "../CBARTypes";

export interface CBARSurfaceAssetProperties extends CBARAssetProperties {

}

enum DragMethod {
    None,
    Unknown,
    Rotation,
    Translation
}

export abstract class CBARSurfaceAsset extends CBARTangibleAsset {

    protected _surface?:CBARSurface;

    public get surface() {
        return this._surface
    }

    public set surface(surface:CBARSurface|undefined) {
        this._surface = surface
    }

    public existsAtPoint(coords:THREE.Vector2) : boolean {
        if (!this.surface) return false;
        return this.surface.existsAtPoint(coords)
    }

    load(basePath:string|undefined, json:CBARSurfaceAssetProperties) : Promise<CBARSurfaceAsset> {

        return new Promise<CBARSurfaceAsset>((resolve, reject) => {
            super.load(basePath, json).then(()=>{
                resolve(this)
            }).catch(error=>{
                this.rejectPromise(reject, error)
            })
        })
    }

    public data() : any {
        const data:any = super.data();

        return data
    }

    get description() : string {
        return "Surface Asset"
    }

    abstract addedToSurface(surface:CBARSurface) : void

    removeFromScene() : void {
        super.removeFromScene();

        if (this.surface) {
            (this.surface as any).internal_removeKey(this.id)
        }
    }

    get surfaceRotation() : number {
        return this.rotation.z
    }

    set surfaceRotation(value:number) {
        this.rotation = new THREE.Euler(this.rotation.x, this.rotation.y, value)
        this.needsUpdate();
    }

    get surfacePosition() : THREE.Vector2 {
        return new THREE.Vector2(this.position.x, this.position.y)
    }

    set surfacePosition(value:THREE.Vector2) {
        this.setSurfacePosition(value.x, value.y)
    }

    setSurfacePosition(xPos:number, yPos:number) {
        this.position = new THREE.Vector3(xPos, yPos, this.position.z)
        this.needsUpdate();
    }

    get surfaceElevation() {
        return this.position.z
    }

    set surfaceElevation(value:number) {
        this.position = new THREE.Vector3(this.position.x, this.position.y, value);
        this.needsUpdate();
    }

    private _dragStartPosition = new THREE.Vector2();
    private _dragStartRotation = 0.0;

    private _dragMethod = DragMethod.None;

    public get menuPoint() {
        return this.surface?.menuPoint
    }

    public handleEvent(event: CBAREvent) {
        super.handleEvent(event);

        if (this.context.isDrawing || !this.canMove) return;

        if (event.type === CBAREventType.DragStart) {

            if (event.toolMode === CBARToolMode.Rotate) {
                this._dragMethod = DragMethod.Rotation;
            } else if (event.toolMode === CBARToolMode.Translate) {
                this._dragMethod = DragMethod.Translation;
            } else if (event.toolMode === CBARToolMode.None) {
                this._dragMethod = DragMethod.Unknown;
            } else {
                this._dragMethod = DragMethod.None;
            }

            this._dragStartPosition = this.surfacePosition;
            this._dragStartRotation = this.surfaceRotation;
            //console.log("Drag start", this._dragStartPosition, this._dragStartRotation);

        } else if ((event.type === CBAREventType.DragMove || event.type === CBAREventType.DragEnd) && this.surface && this.surface.planeNormal) {

            this.surface.handleEvent(event);

            const drag = this.getState(CBARHighlightState.Drag);
            const dragStart = drag.event as CBARMouseEvent;
            const dragNow = event as CBARMouseEvent;

            if (drag.active && dragStart?.intersections?.length && dragNow?.intersections?.length) {

                const center = this.surfacePosition;
                const startPoint2D = this.surface.screenToSurfacePosition(dragStart.point);
                const currentPoint2D = this.surface.screenToSurfacePosition(dragNow.point);
                const distance2D = new THREE.Vector2(dragStart.point.x, dragStart.point.y).sub(dragNow.point).length();
                //console.log(center, startPoint2D, currentPoint2D);

                const v1 = new THREE.Vector2(startPoint2D.x - center.x, startPoint2D.y - center.y);
                const v1Norm = v1.clone().normalize();
                const v2 = new THREE.Vector2(currentPoint2D.x - center.x, currentPoint2D.y - center.y);
                const v2Norm = v2.clone().normalize();
                const pulledTranslation = new THREE.Vector2(currentPoint2D.x - startPoint2D.x, currentPoint2D.y - startPoint2D.y);

                const dot = v1Norm.dot(v2Norm);
                const det = v1Norm.x * v2Norm.y - v1Norm.y * v2Norm.x;
                const angle = Math.atan2(det, dot);
                const radius = pulledTranslation.length();
                const deltaRadius = 0.5 * Math.abs(v2.length() - v1.length());

                const arcLength = Math.abs(2.0 * Math.PI * radius * angle);
                const arcLengthPart = 1.5 * arcLength;
                const checkLength = 0.1;

                //const assetCenter = getSurfacePosition(this.position;
                const distanceFromCenter = startPoint2D.sub(center).length();

                //console.log("distance2d", distance2D);

                if (this._dragMethod === DragMethod.Unknown && distance2D > 0.01) {

                    const newEvent = {...event};

                    if (distanceFromCenter < 0.6) {
                        this._dragMethod = DragMethod.Translation;
                        newEvent.type = CBAREventType.Translate;
                        event.scene.handleEvent(newEvent);
                    }
                    else if (deltaRadius > checkLength || arcLengthPart > checkLength) {

                        if ( arcLengthPart > deltaRadius) {
                            this._dragMethod = DragMethod.Rotation;
                            newEvent.type = CBAREventType.Rotate;
                        } else {
                            this._dragMethod = DragMethod.Translation;
                            newEvent.type = CBAREventType.Translate;
                        }

                        event.scene.handleEvent(newEvent);
                    }
                    // console.log(`Determined drag method to be ${(this._dragMethod === DragMethod.Translation ? "Translation" : "Rotation")}, deltaRadius: ${deltaRadius}, len: ${arcLengthPart}`);
                    // console.log("startPoint2D", startPoint2D);
                    // console.log("currentPoint2D", currentPoint2D);
                    // console.log("v1", v1);
                    // console.log("v2", v2);
                }

                if (this._dragMethod === DragMethod.Rotation) {
                    this.surfaceRotation = Number(this._dragStartRotation) + Number(angle);
                } else {
                    this.surfaceRotation = Number(this._dragStartRotation);
                }

                if (this._dragMethod === DragMethod.Translation) {
                    this.setSurfacePosition(Number(this._dragStartPosition.x) + Number(pulledTranslation.x), Number(this._dragStartPosition.y) + Number(pulledTranslation.y));
                } else {
                    this.setSurfacePosition(Number(this._dragStartPosition.x), Number(this._dragStartPosition.y));
                }

            }
        }
    }
}