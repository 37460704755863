import {BackendConfig} from "cambrian-base";
import {CBARDebug, ZoomState} from "../core";
import {DebugLevel, LogLevel} from "../core/internal/GlobalLogger";

const ApiPaths = {
    process:"ImageProcessing/planes",
    registerUploads:"RegisterUploads",
    projectExists:"ProjectExists"
}

export type GetUploadUrlsRequest = {
    key?: string // When defined, will request sub-urls for existing room
    names: string[] // Sub-names like mask, preview
}

export type GetUploadUrlsResult = {
    key: string // Room id
    uploadUrl?: string // Upload url for roomId's background
    subKey: string // Sub-room id for the room id
    subUploadUrls: { [name: string]: string } // Sub-upload urls for requested names for subRoomId
}

export interface ImageUploader {
    getUploadUrls(request: GetUploadUrlsRequest): Promise<GetUploadUrlsResult | null>
    upload(url: string, content: File | Blob): Promise<boolean>
}

export class CBServerApi  {
    constructor(readonly apiUrl: string) {

    }

    public async processImage(imageId: string, params: { [key: string]: string}) {
        let segmentUrl = `${this.apiUrl}/${ApiPaths.process}/${imageId}?`;

        Object.keys(params).forEach((key, index)=>{
            const kvString = `${key}=${params[key]}`;
            segmentUrl += (index ? `&${kvString}` : kvString);
        });

        console.log(`Segmenting at ${segmentUrl}`);

        return this.getProject(segmentUrl, imageId)
    }

    private async getProject(segmentUrl:string, imageId:string) {

        try {
            await fetch(segmentUrl, {mode:"no-cors"});//content isn't even useful, but integration response inside cloud formation is having cors problems: no access control header
        } catch (error) {
            console.error(error)
        }

        return this.awaitProject(segmentUrl, imageId)
    }

    private async awaitProject(segmentUrl:string, imageId:string, debounce = 1000, start=Date.now()): Promise<string | null> {
        //it will appear soon at s3
        const config = await getConfig();

        const timeout = config.timeout ? config.timeout : 60000
        const existsUrl = `${this.apiUrl}/${ApiPaths.projectExists}/?key=${imageId}`
        const elapsed = Date.now() - start;

        const response = await fetch(existsUrl);
        if (response.ok) {
            const data = await response.json();
            if (data.exists) {
                const seconds = elapsed / 1000;
                console.log(`Ready after ${seconds.toFixed(2)} seconds`)
                //await usleep(2000); //wait one more second
                const resultsBucket = config.hostingUrl;
                const projectBucket = `${resultsBucket}/${imageId}`;
                return `${projectBucket}/data.json`
            }
        }

        //console.log(`Waited for ${elapsed}ms`);

        if (elapsed < timeout) { //5xx status code e.g 504 (timeout) 500 (server error)
            await usleep(debounce);
            return this.awaitProject(segmentUrl, imageId, debounce, start)
        }

        return null
    }

    async getUploadUrls(req: GetUploadUrlsRequest): Promise<GetUploadUrlsResult | null> {

        let url = `${this.apiUrl}/${ApiPaths.registerUploads}/?imagenames=${req.names.join(",")}`;
        if (req.key) {
            url += `&key=${req.key}`
        }

        const presignedResponse = await fetch(url);
        if (!presignedResponse.ok) {
            console.warn("Response was invalid");
            return null
        }

        const presignedResponseData = await presignedResponse.json();

        if (presignedResponseData.key) {
            return {
                key: presignedResponseData.key,
                uploadUrl: presignedResponseData.uploadUrl,
                subKey: presignedResponseData.subKey,
                subUploadUrls: presignedResponseData.subUploadUrls
            }
        }

        return null
    }

    async upload(url: string, content: File | Blob): Promise<boolean> {
        // Upload the content to the presigned url
        const uploadResponse = await fetch(url, {
            method: "PUT",
            body: content
        });

        return uploadResponse.ok
    }
}

export type CBServerConfigExternal = BackendConfig & {
    logLevel?:DebugLevel

    //appearance
    initialZoom?:ZoomState,
    placeholderPath?:string,
    hoverBGOpacity?:number,
    clickBGOpacity?:number,
    selectedBGOpacity?:number,

    hoverOutlineOpacity?:number,
    clickOutlineOpacity?:number,
    selectedOutlineOpacity?:number,

    //specific
    classifierPath?:string
    classifierMaxRegions?:number,
    classifierUpdateFrequency?:number,
    classifierMinSize?:[number, number],
    classifierMaxSize?:[number, number],

    debug?:CBARDebug
}

export type CBServerConfig = {
    //appearance
    initialZoom:ZoomState,
    hoverBGOpacity:number,
    clickBGOpacity:number,
    selectedBGOpacity:number,

    hoverOutlineOpacity:number,
    clickOutlineOpacity:number,
    selectedOutlineOpacity:number,

    classifierMaxRegions:number,
    classifierUpdateFrequency:number,
    debug:CBARDebug

} & CBServerConfigExternal

let _config:CBServerConfig|undefined;

// Make sure either direct upload url or presigned upload url are defined, and not both (exclusive or).
export function cbInitialize(config:CBServerConfigExternal) {

    if (config.logLevel) {
        LogLevel(config.logLevel);
    }

    _config = { ...config,
        initialZoom: config.initialZoom ? config.initialZoom : ZoomState.ZoomedOut,
        hoverBGOpacity: config.hoverBGOpacity ? config.hoverBGOpacity : 0.0,
        clickBGOpacity: config.clickBGOpacity ? config.clickBGOpacity : 0.0,
        selectedBGOpacity: config.selectedBGOpacity ? config.selectedBGOpacity : 0.0,

        hoverOutlineOpacity: config.hoverOutlineOpacity ? config.hoverOutlineOpacity : 0.0,
        clickOutlineOpacity: config.clickOutlineOpacity ? config.clickOutlineOpacity : 1.0,
        selectedOutlineOpacity: config.selectedOutlineOpacity ? config.selectedOutlineOpacity : 0.5,

        classifierMaxRegions:config.classifierMaxRegions !== undefined ? config.classifierMaxRegions : 1,
        classifierUpdateFrequency:config.classifierUpdateFrequency !== undefined ? config.classifierUpdateFrequency : 1000,

        debug:config.debug ? config.debug : CBARDebug.None,
    };
}

export const usleep = (ms=1000) => new Promise<void>(resolve => setTimeout(() => resolve(), ms))

export async function getConfig() : Promise<CBServerConfig> {
    while (!_config)
        await usleep(500);
    return _config
}