import {ProductConfig} from "cambrian-base";
import {ProductItem} from "./ProductItem";
import {SwatchItem} from "./SwatchItem";
import {ProductCollection} from "./ProductCollection";
import {DataItem, MonitoredArray} from "./DataItem";
import {ProductColor} from "./ProductColor";
import {ProductBrand} from "./ProductBrand";

export class Product extends ProductItem implements SwatchItem {

    constructor(dataUrl?:string) {
        super(dataUrl);
        this.description = "product";
    }

    public collection!: ProductCollection;
    private _colors: MonitoredArray<ProductColor> = new MonitoredArray(()=>this.needsUpdate());

    public get product():Product|undefined {
        return this
    }

    public get colors() {
        return this._colors.sort((a, b) => a.orderIndex - b.orderIndex)
    }
    public set colors(value) {
        this._colors = new MonitoredArray(()=>this.needsUpdate(), ...value)
    }

    public get defaultColor() {
        return this.colors.find(c=>c.isDefault);
    }

    public get brand(): ProductBrand {
        return this.collection.brand
    }

    public get parent():DataItem|undefined {
        return this.collection
    }

    public get children(): DataItem[] {
        return this.colors
    }

    public get hasColumns(): boolean {
        return this.colors.length > 0
    }

    public load(json:ProductConfig) {
        super.load(json);

        if (json.colors && json.colors.length) {
            this._colors.length = 0;
            for (const colorJson of json.colors) {
                const color = new ProductColor();
                color.load(colorJson);
                color.product = this;
                if (color.enabled) {
                    this._colors.push(color)
                }
            }
        }
    }
}