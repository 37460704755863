import * as THREE from "three";
import * as React from "react";
import {createRef} from "react";
import * as gm from "gammacv";
import {CBARMode} from "../CBARTypes";
import {getDeviceInfo} from "./Utils";
import {CBARCameraFacing, CBARCameraResolution, CBARContext, CBARFeatureTracking} from "../CBARContext";
import {DeviceOrientationControls} from "three/examples/jsm/controls/DeviceOrientationControls";
import {Point} from "mirada";
import {CBARPipeline} from "./pipeline/CBARPipeline";
import {VerboseLog} from "./GlobalLogger";
import {getConfig} from "../../backend";

export type CBARMediaInfo = {
    width:number,
    height:number
}

type CBARMediaViewProps = {
    onMediaReady:(view:CBARMediaView) => void
    onMediaUpdated:(view:CBARMediaView, info:CBARMediaInfo) => void
}

enum CBARMediaViewErrorCode {
    PermissionDenied='PermissionDenied'
}

enum CBARMediaViewErrorLevel {
    Message,
    Warning,
    Critical,
}

type CBARMediaViewError = {
    code:CBARMediaViewErrorCode,
    message?:string,
    level:CBARMediaViewErrorLevel
}

type CBARMediaViewState = {
    error?:CBARMediaViewError
    hasCameraAccess?:boolean
}

export class CBARMediaView extends React.Component<CBARMediaViewProps, CBARMediaViewState> {

    private container = createRef<HTMLDivElement>();
    public canvasTexture = new THREE.Texture(gm.canvasCreate(1024,1024));

    constructor(props:CBARMediaViewProps) {
        super(props);

        this.state = {
            hasCameraAccess:false
        }
    }

    componentDidMount(): void {

    }

    hasInitialized = false;
    device = getDeviceInfo();

    componentDidUpdate(props:CBARMediaViewProps) {

        if (!this.hasInitialized) {
            this.hasInitialized = true;
            this.canvasTexture.minFilter = THREE.LinearFilter;
            this.canvasTexture.magFilter = THREE.LinearFilter;
            this.canvasTexture.format = THREE.RGBFormat;

            const canvas = this.canvasTexture.image;

            canvas.width = 1080;
            canvas.height = 1080;

            this.props.onMediaReady(this)
        }
    }

    protected getPipeline(input: gm.Tensor) : gm.Operation {
        //identity
        return gm.norm(input, 'minmax')
    }

    public loadImage(image:HTMLImageElement) {
        const canvas = this.canvasTexture.image;
        const ctx = canvas.getContext('2d');
        canvas.width = image.width;
        canvas.height = image.height;

        if (ctx) {
            ctx.drawImage(image, 0,0);
            this.stopVideoCamera();
        }
        //console.log(`Scene image loaded at ${image.width} x ${image.height}`);
        this._mode = CBARMode.Image;

        this.props.onMediaUpdated(this, {width:canvas.width, height:canvas.height})
    }

    private _mode = CBARMode.None;

    public get mode() : CBARMode {
        return this._mode
    }

    public async startVideoCamera(context:CBARContext, tracking: CBARFeatureTracking, facing:CBARCameraFacing, resolution=CBARCameraResolution.Default) {
        const config = await getConfig()
        return new Promise<void>((resolve, reject)=>{
            if (!this.container.current) {
                reject();
                return;
            }
            const container = this.container.current;
            const canvas = this.canvasTexture.image;

            this._mode = CBARMode.Video;
            this._featureTracking = tracking;
            this._facing = facing;

            try {

                this.canvasTexture.image.width = container.offsetWidth;
                this.canvasTexture.image.height = container.offsetHeight;


                this.pipeline = new CBARPipeline(context, config, canvas, tracking);

                this.pipeline.start(resolution, facing).then(()=>{

                    //flip view if facing other dir
                    this.canvasTexture.wrapS = THREE.RepeatWrapping;

                    //console.log(`Video camera started at ${props.width} x ${props.height}`);
                    if (this.device.type === "desktop" || this.pipeline?.facing === CBARCameraFacing.User) {
                        this.canvasTexture.repeat.x = - 1;
                    } else {
                        this.canvasTexture.repeat.x = 1;
                    }

                    this.setState({ hasCameraAccess: true });

                    this.props.onMediaUpdated(this, {width:canvas.width, height:canvas.height})
                    resolve();
                }).catch(error=>{
                    this.setState({ error: {code:CBARMediaViewErrorCode.PermissionDenied, message:error.message, level:CBARMediaViewErrorLevel.Critical}});
                    this.pipeline?.release();
                    this.pipeline = undefined;
                    reject(error);
                })
            } catch (error:any) {
                this.setState({ error: {code:CBARMediaViewErrorCode.PermissionDenied, message:error.message, level:CBARMediaViewErrorLevel.Critical}});
                reject(error);
            }
        })

    }

    public stopVideoCamera() {
        if (this.pipeline) {
            this.pipeline.release();
            this.pipeline = undefined;
            this.orientationControls?.dispose();
            this.orientationControls = undefined;
            this._mode = CBARMode.None;
            VerboseLog(`Video camera stopped`)
        }
    }

    private capture:HTMLCanvasElement|undefined = undefined

    public captureImage() : Promise<HTMLImageElement> {

        if (this.pipeline) {
            this.capture = this.pipeline.captureRawOutput();
            if (this.capture) {
                console.log("Got canvas: ", this.capture.width, this.capture.height);
            }
        }

        const canvas = this.canvasTexture.image;
        const url = canvas.toDataURL();

        return new Promise(resolve => {
            const img = document.createElement("img");

            img.onload = () => {
                VerboseLog(`Image captured at ${img.width} x ${img.height}`);
                // no longer need to read the blob so it's revoked
                resolve(img)
            };

            img.src = url;
        })
    }

    private _featureTracking = CBARFeatureTracking.None;

    public get featureTracking() {
        return this._featureTracking;
    }

    private _facing = CBARCameraFacing.Environment;

    public get facing() {
        return this._facing;
    }

    private pipeline?:CBARPipeline;

    //https://github.com/opencv/opencv/blob/68d15fc62edad980f1ffa15ee478438335f39cc3/modules/imgproc/src/hough.cpp

    _trackingPoints:Point[] = [];

    private orientationControls?:DeviceOrientationControls

    // private getAccelerometer = () => {
    //     //only on non desktop devices
    //
    //     const dm = DeviceMotionEvent as any;
    //     if (dm) {
    //         if (dm.requestPermission) {
    //             //promptGyroPermission()
    //         } else {
    //             this.orientationControls = new DeviceOrientationControls(this.context.gl.camera);
    //         }
    //     }
    // }

    update = () => {
        this.orientationControls?.update();
        this.pipeline?.update();
        this.canvasTexture.needsUpdate = true
    };

    render() {
        return <div ref={this.container} className={"media"} style={{position:"fixed", visibility:"hidden", width:"100%", height:"100%"}} />
    }
}