import * as React from "react"
import {createRef, useCallback, useEffect, useMemo, useRef, useState} from "react"
import {CBARMediaInfo, CBARMediaView} from "./internal";
import * as THREE from "three";
import {OrthographicCamera} from "three";
import {
    DeferredExecutor,
    hfovToVFov,
    INT_MAX,
    normalizedToScreen,
    overlayMask,
    vfovToHFov
} from "./internal/Utils";
import {ResizeObserver as Polyfill} from '@juggle/resize-observer';
import {
    CBARCameraFacing,
    CBARContext,
    CBARFeatureTracking,
    CBARReceiver,
    ImageExportOptions,
    isDrawingTool,
    ZoomState
} from "./CBARContext";
import {ReactZoomPanPinchRef, TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import {
    CBAREventHandler,
    CBAREventType,
    CBARHighlightState,
    CBARIntersection,
    CBARMode,
    CBARMouseEvent,
    CBARMouseEventHandler,
    CBARRenderContext,
    CBARSurfaceType,
    CBARToolMode,
    Point2D
} from "./CBARTypes";
import {CBARObject3D, CBARScene, CBARSceneProperties, CBARSurface, DRAW_RADIUS} from "./components";
import {CBARAmbientLight, CBARDirectionalLight, CBARSurfaceAsset} from "./assets";
import {CBARDebugView} from "./internal/CBARDebugView";
import {MessageLog} from "./internal/GlobalLogger";
import {Mat} from "mirada";
import {getConfig, usleep} from "../backend";
import {GUI} from "dat.gui";

let staticDebugView:CBARDebugView|null;

let leftMouseButtonOnlyDown = false;

const setLeftButtonState = (e:any) => {
    leftMouseButtonOnlyDown = e.buttons === undefined
        ? e.which === 1
        : e.buttons === 1;
}

document.body.addEventListener("mousedown", setLeftButtonState);
document.body.addEventListener("mouseup", setLeftButtonState);
document.body.addEventListener("mousemove", setLeftButtonState);

export const imShow = (image:Mat, text?:string)=>{
    staticDebugView?.imShow(image, text);
}

export const imShowOverlay = (background:Mat, overlay:Mat, text?:string)=>{
    const image = background.clone();
    overlayMask(overlay, image);
    staticDebugView?.imShow(image, text);
    image.delete();
}

export const showDebugText = (text:string)=>{
    if (staticDebugView) {
        staticDebugView.debugText = text;
    }
}

const ResizeObserver = (window as any).ResizeObserver || Polyfill;

export enum ZoomFeatures {
    None,
    ZoomIn = 1 << 0,
    ZoomOut = 1 << 1,
    Pan = 1 << 2,
    SnapTo = 1 << 3,
    All = 0xff
}

const ZoomFeatures_default = ZoomFeatures.All & ~ZoomFeatures.Pan;

const SHADOWS_ENABLED = true;

export const isTouchDevice = 'ontouchstart' in window || !!navigator.maxTouchPoints;

interface CBARViewProps {
    className?:string,
    onContextCreated:(context:CBARContext)=>void,
    toolMode?:CBARToolMode
    zoom?:ZoomFeatures
    children?:React.ReactNode

    onTouchDown?:CBARMouseEventHandler
    onTouchMove?:CBARMouseEventHandler
    onTouchUp?:CBARMouseEventHandler
    onTouchLeave?:CBARMouseEventHandler

    onMouseOver?:CBARMouseEventHandler
    onMouseOut?:CBARMouseEventHandler

    onRotate?:CBAREventHandler
    onTranslate?:CBAREventHandler

    showGui?:boolean
}

export function CBARView(props: CBARViewProps) {

    const [renderContext, setRenderContext] = useState<CBARRenderContext>();
    const [receiver, setReceiver] = useState<CBARReceiver>();

    const isMobile = window.outerWidth < 400;
    const [initialZoom, setInitialZoom] = useState(isMobile ? ZoomState.FitScreen : ZoomState.ZoomedOut);

    const context = useMemo(()=>{
        if (renderContext && receiver) {
            MessageLog(`react-home-ar (0.1.75) by cambrian\ncambrian.io`);
            return new CBARContext(renderContext, receiver)
        }
    }, [renderContext, receiver])

    useEffect(()=>{
        getConfig().then(c=>setInitialZoom(c.initialZoom))
    }, [])

    const [mediaProperties, setMediaProperties] = useState<CBARMediaInfo>();

    const doRender = useRef<boolean>(false);

    const [scene, setScene] = useState<CBARScene>();

    const [contentRect, setContentRect] = useState<DOMRect>();

    const resizeObserver = useMemo<ResizeObserver>(()=>{
        return new ResizeObserver((entries:ResizeObserverEntry[]) => {
            if (mounted.current && entries.length) {
                const rect = entries[0].contentRect;
                if (rect.width != contentRect?.width)
                setContentRect(rect);
            }
        })
    }, [contentRect])

    const primaryContainer = useRef<HTMLDivElement>();
    const transformer = useRef<ReactZoomPanPinchRef>();
    const wrapperContainer = useRef<HTMLDivElement>();
    const canvasElement = createRef<HTMLCanvasElement>();
    const drawingOverlay = createRef<HTMLDivElement>();

    const media = useRef<CBARMediaView>();
    const debugView = createRef<CBARDebugView>();
    const raycaster = useMemo(()=>new THREE.Raycaster(), []);
    const isVideo = media.current?.mode === CBARMode.Video;
    const pixelRatio = window.screen.availWidth / document.documentElement.clientWidth;

    const [directionalLight, setDirectionalLight] = useState<CBARDirectionalLight>();

    const camera = useMemo(()=>new THREE.PerspectiveCamera( 43.1, 1280 / 720, 0.1, 1000 ), []);
    const sceneJS = useMemo(()=>new THREE.Scene(), []);
    const [viewSize, setViewSize] = useState<THREE.Vector2>();
    const scaleToFit = useRef(1.0);

    const screenshotExecutor = useRef<DeferredExecutor<string>>();
    const screenshotOptions = useRef<ImageExportOptions>();

    const toolMode = props.toolMode ? props.toolMode : CBARToolMode.None;
    //TODO:dependencies broken somehow, explaining weirdness here
    const _toolMode = useRef(CBARToolMode.None);
    useEffect(()=>{
        _toolMode.current = toolMode;
    }, [toolMode, _toolMode]);

    const getToolMode = useCallback(() => {
        return _toolMode.current;
    }, [_toolMode]);

    const [isDrawMode, setIsDrawMode] = useState(false);
    const [isActivelyDrawing, setActivelyDrawing] = useState(false);
    const [isOffscreen, setIsOffScreen] = useState(false);
    const [isOffCanvas, setIsOffCanvas] = useState(false);
    const [gui, setGui] = useState<GUI>();

    const makeId = useCallback((event: THREE.Intersection) => {
        return event.object.uuid + '/' + event.index
    }, []);

    const [needsRefresh, setNeedsRefresh] = useState(false);
    const mounted = useRef<boolean>();

    const refresh = useCallback(()=>{
        doRender.current = true;
    }, [doRender])

    let frameIndex = 0;

    const tick = useCallback(() => {

        if (!mounted.current) return;

        requestAnimationFrame(()=>tick());

        const isScreenshot = !!screenshotExecutor.current

        if (!media.current || !scene || (!doRender.current && !isScreenshot && !scene.animating)) {
            return;
        }

        const canvas = scene.context.gl.renderer.domElement;
        if (!canvas) return;

        scene.context.gl.resolution.x = canvas.width;
        scene.context.gl.resolution.y = canvas.height;
        media.current.update();

        scene.render({isScreenshot:isScreenshot});

        if (screenshotExecutor.current) {
            const options = screenshotOptions.current ? screenshotOptions.current : {format:'jpeg', quality:0.9};
            MessageLog(`Exporting ${options.format} image, dimensions ${canvas.width}x${canvas.height}`);
            const screenshot = canvas.toDataURL(`image/${options.format}`, options.quality);

            if (screenshot.length > 15000) {
                screenshotExecutor.current.resolve(screenshot)
            } else {
                screenshotExecutor.current.reject(new Error("Invalid screenshot"));
            }
            screenshotExecutor.current = undefined;
        }

        doRender.current = media.current.mode === CBARMode.Video;
        frameIndex ++;

    }, [scene, frameIndex]);

    useEffect(() => {
        if (!mounted.current || !scene) return;
        scene.toolModeChanged(toolMode);
        setIsDrawMode(isDrawingTool(toolMode));
        setActivelyDrawing(false);
    }, [mounted, toolMode, scene]);

    useEffect(()=>{
        if (!mounted.current) return;
        if (needsRefresh) {
            doRender.current = true;
            setNeedsRefresh(false);
        }
    }, [mounted, needsRefresh]);

    const zoom = props.zoom === undefined ? ZoomFeatures_default : props.zoom;
    const canZoomIn = (zoom & ZoomFeatures.ZoomIn) > 0;
    const canZoomOut = (zoom & ZoomFeatures.ZoomOut) > 0;
    const canPan = (zoom & ZoomFeatures.Pan) > 0;
    const canSnapTo = (zoom & ZoomFeatures.SnapTo) > 0;
    const canZoom = canZoomIn || canZoomOut;

    const snapToZoomRange = canSnapTo ? 0.1 : 0.0;

    const minZoomScale = useMemo(()=>{
        return canZoomOut ? 1.0 : scaleToFit.current;
    }, [canZoomOut, scaleToFit]);

    const maxZoomScale = useMemo(()=>{
        return canZoomIn ? 4.0 : scaleToFit.current;
    }, [canZoomIn, scaleToFit]);

    const getZoomScale = useCallback(()=>{
        return transformer.current ? transformer.current.state.scale : 1.0;
    }, [transformer])

    const setZoomScale = useCallback((scale:number)=>{
        if (transformer.current) {
            const _zoomScale = Math.min(Math.max(minZoomScale, scale), maxZoomScale);
            transformer.current.centerView(_zoomScale);
        }
    }, [minZoomScale, maxZoomScale, transformer])

    const isZooming = useRef(false);

    const getZoomState = useCallback(()=>{
        const zoomScale = getZoomScale();
        if (Math.abs(zoomScale - scaleToFit.current) < 0.05) {
            return ZoomState.FitScreen;
        }
        return zoomScale > scaleToFit.current ? ZoomState.ZoomedIn : ZoomState.ZoomedOut;
    }, [getZoomScale])

    const setZoomState = useCallback((state:ZoomState)=>{
        if (!mounted.current) return;

        switch (state) {
            case ZoomState.ZoomedIn:
                setZoomScale(maxZoomScale);
                break;
            case ZoomState.ZoomedOut:
                setZoomScale(minZoomScale);
                break;
            case ZoomState.FitScreen:
                setZoomScale(scaleToFit.current);
                break;
        }
    }, [maxZoomScale, minZoomScale, scaleToFit, setZoomScale])

    const onZoomStart = useCallback(() => {
        isZooming.current = true;
    }, [isZooming]);

    const onZoomStop = useCallback(() => {
        const zoomScale = getZoomScale()
        if (Math.abs(zoomScale - scaleToFit.current) <= snapToZoomRange && zoomScale != minZoomScale && zoomScale != maxZoomScale) {
            setZoomState(ZoomState.FitScreen)
        }
        isZooming.current = false;
    }, [getZoomScale, maxZoomScale, minZoomScale, setZoomState, snapToZoomRange]);

    useEffect(() => {
        if (!mounted.current) return;

        if (mediaProperties && contentRect && context) {
            const mediaAspectRatio = mediaProperties.width / mediaProperties.height;
            const viewAspectRatio = contentRect.width / contentRect.height;

            const width = (mediaAspectRatio > viewAspectRatio) ? contentRect.width : contentRect.height * mediaAspectRatio;
            const height = (mediaAspectRatio > viewAspectRatio) ? contentRect.width / mediaAspectRatio : contentRect.height;
            const newSize = new THREE.Vector2(Math.round(width), Math.round(height));

            if (!viewSize || viewSize.width != newSize.width) {
                //const zoomScale = getZoomScale();
                scaleToFit.current = Math.max(mediaAspectRatio / viewAspectRatio, viewAspectRatio / mediaAspectRatio);
                setViewSize(newSize);
                context.gl.renderer.setSize(newSize.width, newSize.height);
                // const maxDimension = Math.max(newSize.width, newSize.height);
                // const _pixelRatio = isVideo ? 1.0 : pixelRatio * window.devicePixelRatio * zoomScale;
                // const maxTextureDimension = Math.min(4096, context.gl.renderer.capabilities.maxTextureSize / 4);
                // const factor = Math.min(maxTextureDimension / (_pixelRatio * maxDimension), 1.0);
                // //console.log("maxTextureDimensions", maxTextureDimensions);
                // const pr = _pixelRatio * factor;
                context.gl.renderer.setPixelRatio(2.0);
                setNeedsRefresh(true);
                console.log(`Rendering at ${newSize.width}x${newSize.height} at density 2.0`)

                setZoomState(initialZoom)
            }
        }
    }, [contentRect, context, isVideo, mediaProperties, pixelRatio, viewSize, getZoomScale, setZoomState, initialZoom]);

    const {onTouchDown, onTouchMove, onTouchUp, onTouchLeave, onMouseOver, onMouseOut, onRotate, onTranslate} = {...props}

    const eventHandlers = useRef<{[key: number]: CBAREventHandler}>({})

    const wireUnwire = useCallback((ctx:CBARContext, eventType:CBAREventType, func:CBARMouseEventHandler|CBAREventHandler|undefined)=>{
        //always remove what was there, replace
        if (eventHandlers.current.hasOwnProperty(eventType)) {
            ctx.removeHandler(eventType, eventHandlers.current[eventType])
        }
        //if adding:
        if (func) {
            eventHandlers.current[eventType] = func as CBAREventHandler
            ctx.addHandler(eventType, eventHandlers.current[eventType])
        }
    }, [eventHandlers])

    const lastMoveEvent = useRef<CBARMouseEvent>()
    const [overSurface, setOverSurface] = useState<CBARSurface>()
    const [overAsset, setOverAsset] = useState<CBARSurfaceAsset>()
    const [lastOverSurface, setLastOverSurface] = useState<CBARSurface>()
    const [lastOverAsset, setLastOverAsset] = useState<CBARSurfaceAsset>()
    const mouseOut = useRef(false)

    const _onTouchMove = useCallback((event:CBARMouseEvent)=>{
        lastMoveEvent.current = event

        setLastOverSurface(overSurface)
        setLastOverAsset(overAsset)

        setOverSurface(event.surface)
        setOverAsset(event.asset)

        mouseOut.current = false

        if (onTouchMove) {
            onTouchMove(event);
        }
    }, [onTouchMove, overSurface, overAsset, mouseOut])

    const _onTouchLeave = useCallback((event:CBARMouseEvent)=>{
        if (onTouchLeave) {
            onTouchLeave(event);
        }

        if (scene && lastOverSurface) {
            const mouseEvent = Object.create(event) as CBARMouseEvent;
            mouseEvent.type = CBAREventType.MouseOut;
            mouseEvent._overwrite(lastOverSurface, lastOverAsset)
            scene.handleEvent(mouseEvent);

            mouseOut.current = true;
            usleep(300).then(()=>{
                if (mouseOut.current) {
                    scene.geometry.surfaces.forEach(s=>{s.highlighted = false})
                }
            });
        }

    }, [onTouchLeave, scene, lastOverSurface, lastOverAsset])

    useEffect(()=>{
        const event = lastMoveEvent.current;
        if (!scene || !event) return;

        if (lastOverSurface && overSurface !== lastOverSurface) {
            //console.log("Proposed out", lastOverSurface.description)
            const mouseEvent = Object.create(event);
            mouseEvent.type = CBAREventType.MouseOut;
            mouseEvent._overwrite(lastOverSurface, lastOverAsset)
            scene.handleEvent(mouseEvent);
        }

    }, [scene, lastMoveEvent, overSurface, lastOverSurface, lastOverAsset])

    useEffect(()=>{
        const event = lastMoveEvent.current;
        if (!scene || !event) return;

        const mouseEvent = Object.create(event);
        mouseEvent.type = overSurface ? CBAREventType.MouseOver : CBAREventType.MouseOut;
        mouseEvent._overwrite(overSurface, overAsset)

        scene.handleEvent(mouseEvent);

    }, [scene, lastMoveEvent, overSurface, overAsset])

    useEffect(()=>{
        if (context) {
            wireUnwire(context, CBAREventType.TouchDown, onTouchDown)
        }
    }, [context, onTouchDown, wireUnwire])

    useEffect(()=>{
        if (context) {
            wireUnwire(context, CBAREventType.TouchMove, _onTouchMove)
        }
    }, [context, _onTouchMove, wireUnwire])

    useEffect(()=>{
        if (context) {
            wireUnwire(context, CBAREventType.TouchUp, onTouchUp)
        }
    }, [context, onTouchUp, wireUnwire])

    useEffect(()=>{
        if (context) {
            wireUnwire(context, CBAREventType.TouchLeave, _onTouchLeave)
        }
    }, [context, _onTouchLeave, wireUnwire])

    useEffect(()=>{
        if (context) {
            wireUnwire(context, CBAREventType.MouseOver, onMouseOver)
        }
    }, [context, onMouseOver, wireUnwire])

    useEffect(()=>{
        if (context) {
            wireUnwire(context, CBAREventType.MouseOut, onMouseOut)
        }
    }, [context, onMouseOut, wireUnwire])

    useEffect(()=>{
        if (context) {
            wireUnwire(context, CBAREventType.Rotate, onRotate)
        }
    }, [context, onRotate, wireUnwire])

    useEffect(()=>{
        if (context) {
            wireUnwire(context, CBAREventType.Translate, onTranslate)
        }
    }, [context, onTranslate, wireUnwire])

    useEffect(() => {
        const cont = primaryContainer.current;
        if (cont && resizeObserver) {
            resizeObserver.observe(cont);
        }
        return () => {
            if (resizeObserver && cont) {
                resizeObserver.disconnect();
            }
        };
    }, [primaryContainer, resizeObserver]);

    const startVideoCamera = useCallback((context:CBARContext, tracking:CBARFeatureTracking, facing:CBARCameraFacing) => {
        const _media = media.current!;
        if (scene) {
            return _media.startVideoCamera(context,tracking, facing);
        } else {
            return new Promise<void>((resolve,reject)=>{
                setSceneResolver({
                    resolve:() => {
                        _media.startVideoCamera(context, tracking, facing).then(()=>{
                            resolve();
                        }).catch(error=>{
                            reject(error);
                        });
                    },
                    reject
                })
                setScene(new CBARScene(context));
            })
        }
    }, [media, scene]);

    const stopVideoCamera = useCallback(() => {
        return media.current!.stopVideoCamera();
    }, [media]);

    const captureImage = useCallback(() => {
        return media.current!.captureImage()
    }, [media]);

    const captureScreenshot = useCallback((context:CBARContext, options?:ImageExportOptions) => {
        return new Promise<string>((resolve, reject) => {
            screenshotOptions.current = options;
            screenshotExecutor.current = {resolve, reject};
            setNeedsRefresh(true);
        });
    }, [screenshotExecutor, screenshotOptions]);

    const loadImage = useCallback((context:CBARContext, image:HTMLImageElement) => {
        if (media.current) {
            media.current.loadImage(image)
        }
    }, []);

    const raycast = useCallback((normalizedPoint:THREE.Vector2, searchObjects?:THREE.Object3D[])=>{

        const intersections: CBARIntersection[] = [];

        if (!context || !scene) return intersections;

        raycaster.setFromCamera(normalizedToScreen(normalizedPoint), context.gl.camera);

        if (!searchObjects) {
            searchObjects = scene.getEventObjects(CBAREventType.TouchDown);
        }

        const seen = new Set<string>();

        const intersects = raycaster
            .intersectObjects(searchObjects, true)
            .filter(item => {
                const id = makeId(item);
                if (seen.has(id)) return false;
                seen.add(id);
                return true
            });

        const selectedObject = scene.surfaceFor(CBARHighlightState.Selected)

        for (const intersect of intersects) {
            let eventObject: THREE.Object3D = intersect.object;

            let cbarObject: CBARObject3D<any>|undefined;
            while (eventObject.parent) {
                let obj = (eventObject as any).__cbarObject as CBARObject3D<any>|undefined;

                if (obj && obj.rootObject().receivesEvents && (obj.rootObject().existsAtPoint(normalizedPoint))) {
                    cbarObject = obj.rootObject();
                    break
                }

                eventObject = eventObject.parent
            }

            if (selectedObject && isDrawMode) {
                intersections.push({
                    intersection:intersect,
                    // @ts-ignore
                    object:selectedObject
                })
            }
            else if (cbarObject) {
                intersections.push({
                    intersection:intersect,
                    object:cbarObject
                })
            }
        }

        return intersections;

    }, [context, scene, raycaster, makeId, isDrawMode])

    useEffect(()=>{
        if (directionalLight && scene && camera && directionalLight.position.length() === 0.0) {
            const direction = new THREE.Vector3(3, 10, -5);
            directionalLight.position = direction.project(camera);
        }
    }, [camera, directionalLight, scene]);

    useEffect(() => {

        if (scene && context) {
            if (scene.backgroundImage) {
                loadImage(context, scene.backgroundImage.image)
            }

            // //minimum level
            new CBARAmbientLight(context).load(undefined, {
                "intensity": 1.0,
                "color": "0xffffff"
            }).then((asset)=>{
                scene.assets.add(asset);
            });

            new CBARDirectionalLight(context).load(undefined, {
                "intensity": 2.5,
                "color": "0xffffff",
                "position": [0,0,0]
            }).then((asset)=>{
                asset.light.castShadow = SHADOWS_ENABLED;
                asset.light.shadow.camera = new OrthographicCamera(-3,3,3,-3, 0.1, 1000);
                asset.light.shadow.mapSize = new THREE.Vector2(2048,2048);
                asset.light.target.position.set(3,10,-20);
                scene.assets.add(asset);
                setDirectionalLight(asset);
            });
        }

    }, [scene, context, loadImage]);

    const isLoading = useRef(false);

    const onSceneLoading = useCallback(() => {
        isLoading.current = true;
        if (primaryContainer.current) {
            primaryContainer.current.style.visibility = "hidden"
        }
    }, [primaryContainer]);

    const onSceneLoaded = useCallback(() => {
        isLoading.current = false;

        let zoom = initialZoom;
        if (viewSize && mediaProperties && zoom == ZoomState.FitScreen) {
            const viewAspectRatio = viewSize.width / viewSize.height;
            const mediaAspectRatio = mediaProperties.width / mediaProperties.height;
            const value = Math.max(viewAspectRatio, mediaAspectRatio) / Math.min(viewAspectRatio, mediaAspectRatio);
            const threshold = isMobile ? 1.5 : 1.33;
            if (value > threshold) {
                zoom = ZoomState.ZoomedOut;
            }
        }

        setZoomState(zoom);

        if (primaryContainer.current) {
            primaryContainer.current.style.visibility = "visible"
        }

    }, [initialZoom, viewSize, mediaProperties, setZoomState, primaryContainer, isMobile]);

    const [sceneResolver, setSceneResolver] = useState<DeferredExecutor<CBARScene>>();

    const loadSceneData = useCallback((context:CBARContext, json:CBARSceneProperties, surfaceTypes?:CBARSurfaceType[], room?:string|null|undefined, subroom?:string|null|undefined) => {

        return new Promise<CBARScene>((resolve, reject) => {
            if (!context || isLoading.current) {
                reject(new Error("No context supplied"));
                return
            }

            onSceneLoading();

            new CBARScene(context).load(undefined, json, surfaceTypes, room, subroom).then(s=>{
                setScene(s);
                setSceneResolver({resolve, reject})
            }).catch(error=>{
                reject(error)
            }).finally(()=>{
                onSceneLoaded();
            })
        })

    }, [onSceneLoading, onSceneLoaded]);

    const loadSceneAtPath = useCallback((context:CBARContext, dataPath:string, surfaceTypes?:CBARSurfaceType[]) => {

        let path = dataPath.split("/").slice(0,-1).join("/");

        return new Promise<CBARScene>((resolve, reject) => {
            if (!context || isLoading.current) {
                reject(new Error("No context supplied"));
                return
            }
            onSceneLoading();
            fetch(dataPath).then((resp) => {
                resp.json().then((json: string) => {
                    new CBARScene(context).load(path, json, surfaceTypes).then(s=>{
                        if (!mounted.current) return;
                        setScene(s);
                        setSceneResolver({resolve, reject})
                    })
                }).catch((error)=>{
                    reject(new Error(`Invalid json data or path: ${dataPath}\n${error.message}\n`))
                }).finally(()=>{
                    onSceneLoaded();
                })
            })
        })

    }, [mounted, onSceneLoading, onSceneLoaded, isLoading]);

    const onContextCreated = props.onContextCreated;
    const mediaReady = useCallback((view:CBARMediaView) => {
        if (context) {
            media.current = view;
            context.gl.scene.background = view.canvasTexture;
            onContextCreated(context)
        }

    }, [context, media, onContextCreated]);

    useEffect(()=>{
        if (debugView.current && !staticDebugView) {
            staticDebugView = debugView.current;
        }
    }, [debugView]);

    const mediaPropertiesChanged = useCallback((view:CBARMediaView, props:CBARMediaInfo) => {
        if (scene) {
            scene.cameraProperties.setMediaProperties(props)
        }
        setMediaProperties(props);
    }, [scene]);

    useEffect(() => {
        if (!mounted.current) return;
        if (sceneResolver && scene) {
            //finish any setup:
            scene.sceneLoaded()
            sceneResolver.resolve(scene);
            setSceneResolver(undefined);
            tick();
        }
    }, [sceneResolver, scene, tick, mounted, context]);

    const getMode = useCallback(() => {
        if (media.current) {
            return media.current.mode
        }
        return CBARMode.None
    }, [media]);

    const onDeviceEvent = useCallback((e:React.MouseEvent|React.TouchEvent, eventType:CBAREventType, position:THREE.Vector2) => {

        if (scene && context && wrapperContainer.current && mounted.current) {
            const rect = (e.target as HTMLElement).getBoundingClientRect();
            const { left, right, top, bottom } = rect;

            const normalizedPoint = new THREE.Vector2((position.x - left) / (right - left), (position.y - top) / (bottom - top));
            const searchObjects = scene.getEventObjects(eventType);

            const intersections = raycast(normalizedPoint, searchObjects);

            const event = new CBARMouseEvent(
                eventType,
                context,
                scene,
                toolMode,
                e,
                normalizedPoint,
                intersections
            )

            scene.handleEvent(event);

            if (isDrawMode) {
                if (!isTouchDevice) {
                    setActivelyDrawing(leftMouseButtonOnlyDown);
                }

                if (drawingOverlay.current && wrapperContainer.current && transformer.current) {
                    const parentSize = wrapperContainer.current.getBoundingClientRect();
                    const radius = DRAW_RADIUS / transformer.current.state.scale;
                    const radiusPixels = Math.ceil(radius * parentSize.width);
                    const diameterPixels = Math.ceil(2 * radius * parentSize.width);

                    drawingOverlay.current.style.width = `${diameterPixels}px`;
                    drawingOverlay.current.style.height = `${diameterPixels}px`;

                    drawingOverlay.current.style.left = `${position.x - radiusPixels}px`;
                    drawingOverlay.current.style.top = `${position.y - radiusPixels}px`;

                    drawingOverlay.current.style.borderRadius = `50%`
                }

                if (eventType === CBAREventType.TouchDown) {
                    setActivelyDrawing(true);
                    setIsOffCanvas(false);
                } else if (eventType == CBAREventType.TouchUp) {
                    setActivelyDrawing(false);
                } else if (eventType === CBAREventType.TouchLeave) {
                    const buffer = 0.05;
                    if (normalizedPoint.x < buffer && normalizedPoint.x > (1 - buffer) && normalizedPoint.y < buffer && normalizedPoint.y > (1 - buffer)) {
                        setIsOffScreen(true);
                    }
                    setIsOffCanvas(true);
                } else if (eventType === CBAREventType.TouchMove) {
                    setIsOffScreen(false);
                    setIsOffCanvas(false);
                }
            }

        }
    }, [scene, context, raycast, toolMode, wrapperContainer, drawingOverlay, isDrawMode, transformer, mounted]);

    useEffect(()=>{
        if (!mounted.current) return;
        if (isActivelyDrawing) {
            setIsOffScreen(false);
        }
        //console.log("Is actively drawing", isActivelyDrawing);
    }, [isActivelyDrawing, mounted])

    useEffect(()=>{

        if (drawingOverlay.current && wrapperContainer.current) {
            const show = isDrawMode && !isOffscreen && !isOffCanvas;
            wrapperContainer.current.style.cursor = show ? "none" : "default";
            drawingOverlay.current.style.visibility = show ? "visible" : "hidden";
        }

    }, [wrapperContainer, drawingOverlay, isDrawMode, isOffscreen, isOffCanvas])

    const onMouseEvent = useCallback((e:React.MouseEvent, eventType:CBAREventType) => {
        onDeviceEvent(e, eventType, new THREE.Vector2(e.clientX, e.clientY));
    }, [onDeviceEvent])

    const lastPosition = useRef<THREE.Vector2>();
    const onTouchEvent = useCallback((e:React.TouchEvent, eventType:CBAREventType) => {
        if (isZooming.current) return;

        const pos = lastPosition.current;
        if (e.touches.length === 1) {
            const position = new THREE.Vector2(e.touches[0].clientX, e.touches[0].clientY);
            onDeviceEvent(e, eventType, position);
            lastPosition.current = position;
        } else if (pos) {
            onDeviceEvent(e, eventType, pos);
            if (eventType === CBAREventType.TouchUp || eventType === CBAREventType.TouchLeave) {
                lastPosition.current = undefined;
            }
        }
    }, [onDeviceEvent, lastPosition, isZooming])


    const autoRenderHandle = useRef(0);

    const initialize = useCallback(() => {
        if (!wrapperContainer.current || !canvasElement.current) return;

        const renderer = new THREE.WebGLRenderer({canvas:canvasElement.current});
        renderer.autoClearColor = false;
        renderer.sortObjects = false;
        renderer.autoClear = false;

        const width = wrapperContainer.current.clientWidth * pixelRatio;
        const height = wrapperContainer.current.clientHeight * pixelRatio;

        renderer.physicallyCorrectLights = true;
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        renderer.shadowMap.autoUpdate = true;

        //depth buffer
        const target = new THREE.WebGLRenderTarget(width, height);
        target.texture.format = THREE.RGBFormat;
        //target.texture.minFilter = THREE.NearestFilter;
        //target.texture.magFilter = THREE.NearestFilter;
        //target.texture.generateMipmaps = false;

        target.stencilBuffer = false;
        target.depthBuffer = false;
        target.depthTexture = new THREE.DepthTexture(width, height);
        target.depthTexture.format = THREE.DepthFormat;
        target.depthTexture.type = THREE.UnsignedShortType;

        if (media.current?.canvasTexture?.image) {
            const mediaCanvas =  media.current.canvasTexture.image as HTMLCanvasElement;
            canvasElement.current.width = mediaCanvas.width;
            canvasElement.current.height = mediaCanvas.height;
        }

        const renderWidth = isVideo ? wrapperContainer.current.clientWidth : width;
        const renderHeight = isVideo ? wrapperContainer.current.clientHeight : height;

        renderer.setSize(renderWidth, renderHeight);
        setRenderContext(new CBARRenderContext(camera, sceneJS, renderer, target, new THREE.Vector2(renderWidth, renderHeight)));

        autoRenderHandle.current = window.setInterval(()=>{
            doRender.current = true;
        }, 2000);

    }, [wrapperContainer, canvasElement, pixelRatio, isVideo, camera, sceneJS]);

    const initializeRef = useRef(initialize);
    useEffect(() => {
        initializeRef.current = initialize;
    }, [initialize]);

    useEffect(() => {
        if (initializeRef.current) {
            initializeRef.current()
        }
    }, [initializeRef]);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
            media.current = undefined;
            if (autoRenderHandle.current) {
                window.clearInterval(autoRenderHandle.current);
            }
        }
    }, []);

    const refScene = useRef<CBARScene>();

    const getScene = useCallback(()=>{
        return scene ? scene : refScene.current;
    }, [scene, refScene])

    useEffect(()=>{
        refScene.current = scene;
    },[scene])

    const pointToScreenPosition = useCallback((point:Point2D)=>{
        if (transformer.current && primaryContainer.current) {
            const rect  = primaryContainer.current.getBoundingClientRect();

            return {x:rect.width * point.x * transformer.current.state.scale + transformer.current.state.positionX,
                    y:rect.height * point.y * transformer.current.state.scale + transformer.current.state.positionY}
        }
        return point
    }, [primaryContainer, transformer])

    useMemo(()=>{
        if (!receiver) {
            setReceiver({
                loadSceneAtPath, loadSceneData, startVideoCamera, stopVideoCamera, captureImage, captureScreenshot, pointToScreenPosition,
                loadImage, refresh, getMode, getToolMode, getScene, getZoomScale, setZoomScale, scaleToFit:scaleToFit.current, getZoomState, setZoomState})
        }
    }, [receiver, loadSceneAtPath, loadSceneData, startVideoCamera, stopVideoCamera, captureImage, captureScreenshot, pointToScreenPosition, loadImage, refresh, getMode, getToolMode, getScene, getZoomScale, setZoomScale, scaleToFit, getZoomState, setZoomState]);

    const touchEvents = useMemo(()=>{
        return isTouchDevice ? {
            onTouchStart:(e:React.TouchEvent)=>onTouchEvent(e, CBAREventType.TouchDown),
            onTouchEnd:(e:React.TouchEvent)=>onTouchEvent(e, CBAREventType.TouchUp),
            onTouchMove:(e:React.TouchEvent)=>onTouchEvent(e, CBAREventType.TouchMove),
            onTouchCancel:(e:React.TouchEvent)=>onTouchEvent(e, CBAREventType.TouchLeave)
        } : {
            onContextMenu:(e:React.MouseEvent)=>onMouseEvent(e, CBAREventType.ContextMenu),
            onWheel:(e:React.MouseEvent)=>onMouseEvent(e, CBAREventType.Wheel),
            onPointerDown:(e:React.MouseEvent)=>onMouseEvent(e, CBAREventType.TouchDown),
            onPointerUp:(e:React.MouseEvent)=>onMouseEvent(e, CBAREventType.TouchUp),
            onPointerLeave:(e:React.MouseEvent)=>onMouseEvent(e, CBAREventType.TouchLeave),
            onPointerMove:(e:React.MouseEvent)=>onMouseEvent(e, CBAREventType.TouchMove)
        }
    }, [onMouseEvent, onTouchEvent])

    //required due to issues inside TransformWrapper
    const wirePrimary = useCallback((element:HTMLDivElement) => {
        primaryContainer.current = element
    }, [primaryContainer]);

    const wireWrapper = useCallback((element:HTMLDivElement) => {
        wrapperContainer.current = element
    }, [wrapperContainer]);

    const wireTransformer = useCallback((element:ReactZoomPanPinchRef) => {
        transformer.current = element
    }, [transformer]);

    const floor = useMemo(()=>{
        return scene?.geometry.surfaces.find(s=>s.type == CBARSurfaceType.Floor);
    }, [scene?.geometry.surfaces])

    useEffect(() => {

        if (mediaProperties) {
            const gui = new GUI({width:600});

            setGui(gui);

            const cameraFolder = gui.addFolder('Camera')

            const controls = {
                //Camera
                get fov() {
                    return vfovToHFov(camera.fov, camera.aspect);
                },
                set fov(hFOV){
                    camera.fov = hfovToVFov(hFOV, camera.aspect);
                    camera.updateProjectionMatrix();
                    setNeedsRefresh(true);
                },
                get elevation() {
                    return camera.position.y;
                },
                set elevation(value){
                    camera.position.y = value;
                    camera.updateMatrixWorld();
                    setNeedsRefresh(true);
                },
                get rotationX() {
                    return camera.rotation.x;
                },
                set rotationX(value){
                    camera.rotation.x = value;
                    camera.updateMatrixWorld();
                    setNeedsRefresh(true);
                },

                //Floor
                get offset() {
                    return floor?.planeOffset;
                },
                set offset(value){
                    if (floor) {
                        floor.planeOffset = value;
                        setNeedsRefresh(true);
                    }
                },

                get normalX() {
                    return floor?.planeNormal.x;
                },
                set normalX(value){
                    if (floor && value !== undefined) {
                        floor.planeNormal = new THREE.Vector3(value, floor.planeNormal.y, floor.planeNormal.z);
                        setNeedsRefresh(true);
                    }
                },
                get normalY() {
                    return floor?.planeNormal.y;
                },
                set normalY(value){
                    if (floor && value !== undefined) {
                        floor.planeNormal = new THREE.Vector3(floor.planeNormal.x, value, floor.planeNormal.z);
                        setNeedsRefresh(true);
                    }
                },
                get normalZ() {
                    return floor?.planeNormal.z;
                },
                set normalZ(value){
                    if (floor && value !== undefined) {
                        floor.planeNormal = new THREE.Vector3(floor.planeNormal.x, floor.planeNormal.y, value);
                        setNeedsRefresh(true);
                    }
                },
            };

            cameraFolder.add(controls, 'fov', 20, 120, 0.1);
            cameraFolder.add(controls, 'elevation', -5.0, 5.0, 0.01);
            cameraFolder.add(controls, 'rotationX', -0.5, 0.5,0.01);
            cameraFolder.open();

            if (floor) {
                const floorFolder = gui.addFolder('Floor');
                floorFolder.add(controls, 'offset', -5.0, 5.0, 0.01);

                floorFolder.add(controls, 'normalX', -0.5, 0.5, 0.01);
                floorFolder.add(controls, 'normalY', 0.5, 1.5, 0.01);
                floorFolder.add(controls, 'normalZ', -0.5, 0.5, 0.01);
                floorFolder.open();
            }

            gui.hide();

            return () => {
                gui.destroy();
            }
        }
    }, [camera, floor, mediaProperties]);

    useEffect(()=>{
        if (gui && props.showGui !== undefined) {
            if (props.showGui) {
                gui.show();
            } else {
                gui.hide();
            }
        }
    }, [gui, props.showGui])

    return (
        <div ref={wirePrimary} className={props.className} style={{background:"#333", width:"100%", height:"100%", position:"relative"}}>
            <div ref={drawingOverlay} style={{visibility:"hidden", pointerEvents: "none", userSelect:"none",
                position: "fixed", zIndex:1, top:0, left:0, backgroundColor: "#ffa", borderRadius:"50%", display:"inline-block"}} />

            <div ref={wireWrapper}>
                <TransformWrapper ref={wireTransformer}
                                  onZoomStart={onZoomStart}
                                  onZoomStop={onZoomStop}
                                  disabled={isVideo || props.toolMode !== CBARToolMode.None || isDrawMode || !canZoom}
                                  doubleClick={{disabled:true}}
                                  wheel={{step:0.03}} pinch={{step:0.03}} minScale={minZoomScale} maxScale={maxZoomScale} panning={{disabled:!canPan}}>
                    <TransformComponent wrapperStyle={{background:"#333", width:"100vw", height:"100vh"}}>
                        <canvas ref={canvasElement} style={{width:"100%", height:"100%"}} {...touchEvents} />
                    </TransformComponent>
                </TransformWrapper>
                {props.children}
            </div>

            <CBARDebugView ref={debugView} style={{position: "fixed", zIndex:INT_MAX+1, top:0, right:0,
                maxWidth:"50%", maxHeight:"50%", minWidth:"25%", imageRendering:"pixelated"}} />

            <CBARMediaView onMediaReady={mediaReady} onMediaUpdated={mediaPropertiesChanged} />
        </div>
    )
}
