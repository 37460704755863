import {CBARObject, CBARSurface} from "./components";

export interface iCBARCollection<T> {

    values : { [id: string] : T; };

    add(asset:T) : void;

    containsKey(key:string) : boolean;

    remove(asset:T) : void;

    clearAll() : void;

    length() : number;

    first() : T|undefined

    last() : T|undefined

    all() : T[]

    forEach(callbackfn: (value: T, index: number, array: T[]) => void, thisArg?: any): void;
}

export abstract class CBARCollection<T> extends CBARObject<T> implements iCBARCollection<T> {
    public [Symbol.iterator](): Iterator<T, any, undefined> {
        return this.all()[Symbol.iterator]();
    }

    public forEach(callbackfn: (value: T, index: number, array: T[]) => void, thisArg?: any) {
        return this.all().forEach(callbackfn, thisArg)
    }

    abstract values: { [p: string]: T };

    abstract add(asset: T): void;

    abstract all(): T[];

    abstract clearAll(): void;

    abstract containsKey(key: string): boolean;

    abstract first(): T | undefined;

    abstract last(): T | undefined;

    abstract length(): number;

    abstract remove(asset: T): void;
}