import {CBARSurfaceAsset, CBARSurfaceAssetProperties} from "./CBARSurfaceAsset";
import {CBARMaterialProperties, CBARMaterialProperty, CBARObject, CBARPaintMaterial, CBARSurface} from "../components";
import {CBARContext} from "../CBARContext";
import * as THREE from "three";
import {CBARAssetType} from "../CBARTypes";
import {CBARError, CBARErrorCode} from "../CBARError";

export interface CBARPaintAssetProperties extends CBARSurfaceAssetProperties {
    material:CBARMaterialProperties
}

export class CBARPaintAsset extends CBARSurfaceAsset {

    private sharedMaterial:CBARPaintMaterial;
    private _geometry:THREE.PlaneBufferGeometry;
    private _plane:THREE.Mesh;

    constructor(context:CBARContext) {
        super(context);

        this.sharedMaterial = new CBARPaintMaterial(context);
        this.sharedMaterial.setMaterialProperty(CBARMaterialProperty.color, 'rgb(241, 242, 235)');
        this.sharedMaterial.threeMaterial.depthTest = false;
        this.sharedMaterial.threeMaterial.clipShadows = true;
        this.sharedMaterial.threeMaterial.depthWrite = false;

        this._geometry = new THREE.PlaneBufferGeometry( 1, 1, 4);
        this._plane = new THREE.Mesh(this._geometry, this.sharedMaterial.threeMaterial);
        this.setRenderObject(this._plane)
    }

    public get type() : CBARAssetType {
        return CBARAssetType.PaintSurface
    }

    public get canMove() {
        return false
    }

    load(basePath: string|undefined, json:CBARPaintAssetProperties) : Promise<CBARPaintAsset> {

        const promises: Promise<CBARObject<any>>[] = [];

        if (json.material) {
            promises.push(this.sharedMaterial.load(basePath, json.material))
        }

        return new Promise<CBARPaintAsset>((resolve, reject) => {
            super.load(basePath, json).then(() => {
                Promise.all(promises).then(() => {
                    this.layoutSubTiles();
                    resolve(this)
                }).catch((error)=>{
                    reject(error)
                })
            }).catch(() => {
                this.rejectPromise(reject, new CBARError(`Cannot load ${this.description}`, CBARErrorCode.AssetLoad, this));
            })
        })
    }

    private layoutSubTiles() {
        if (!this.renderObject || !this.surface) return;

        const diameter = 2.0 * Math.sqrt(Math.pow(this.surface.extent.x, 2.0) + Math.pow(this.surface.extent.y, 2.0));

        this._geometry = new THREE.PlaneBufferGeometry( diameter, diameter, 2);
        this._plane.geometry = this._geometry;
        this.sharedMaterial.threeMaterial.depthTest = false;
    }

    addedToSurface(surface: CBARSurface): void {

        if (!this.context.scene) return;

        if (this.context.scene.envMap) {
            this.sharedMaterial.threeMaterial.envMap = this.context.scene.envMap;
            this.sharedMaterial.threeMaterial.envMapIntensity = 0.5;
            this.sharedMaterial.threeMaterial.needsUpdate = true;
        }

        const mask = surface.maskTexture?.threeTexture
        if (mask) {
            this.sharedMaterial.threeMaterial.alphaMap = mask;
        }

        // if (this.context.scene?.lightingTexture?.threeTexture) {
        //     this.sharedMaterial.threeMaterial.lightMap = this.context.scene.lightingTexture.threeTexture
        // } else if (this.context.gl.scene.background instanceof THREE.Texture) {
        //     this.sharedMaterial.threeMaterial.lightMap = this.context.gl.scene.background;
        // }

        if (this.context.gl.scene.background instanceof THREE.Texture) {
            this.sharedMaterial.threeMaterial.lightMap = this.context.gl.scene.background;
        }

        this.sharedMaterial.maskIndex = surface.isLegacyMask ? -1 : surface.maskIndex;
        this.sharedMaterial.maskBlendDistance = 2.0;

        if (surface.lightingMeanIntensity) {
            //since paint uses the background image intensity instead of the generated lighting,
            //calculate the offset and factor needed to provide the same intensities:

            const maxValue = 1.4;
            const intensityFactor = 1.1 * this.context.scene.lightingFactor * (1.0 + (surface.lightingMeanIntensity - surface.backgroundMeanIntensity) / 255.0);
            const offset = this.context.scene.lightingOffset + Math.max(intensityFactor - maxValue, 0.0);
            this.sharedMaterial.lightingFactor = Math.min(intensityFactor, maxValue);
            this.sharedMaterial.lightingOffset = offset;

            // this.sharedMaterial.lightingFactor = 1.0;
            // this.sharedMaterial.lightingOffset = 0;

            //console.log("lighting factor", this.sharedMaterial.lightingFactor, "offset", this.sharedMaterial.lightingOffset);

            // console.log("Scene lighting factor", this.context.scene.lightingFactor);
            // console.log("background mean", surface.backgroundMeanIntensity, "lighting mean", surface.lightingMeanIntensity);

            //this.sharedMaterial.logUniforms()
        }

        this.layoutSubTiles()
    }

    public get color() {
        return this.sharedMaterial.getMaterialProperty(CBARMaterialProperty.color)
    }

    public set color(value) {
        this.sharedMaterial.setMaterialProperty(CBARMaterialProperty.color, value)
    }

    public get opacity() {
        return this.sharedMaterial.getMaterialProperty(CBARMaterialProperty.opacity)
    }

    public set opacity(value) {
        this.sharedMaterial.setMaterialProperty(CBARMaterialProperty.opacity, value)
    }

    get description(): string {
        return "Paint Asset"
    }
}