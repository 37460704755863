import {CBARProcessNode} from "./CBARProcessNode";
import {CBARFrame} from "../CBARFrame";
import {CBARPipeline} from "./CBARPipeline";
import {Mat, Point} from "mirada";
import {Line, Rect} from "gammacv";
import {CBARFeatureTrackerNode} from "./CBARFeatureTrackerNode";
import {CBARTrackedObject, CBARTrackedPoint} from "./CBARTrackedObject";
import {rectsOverlap} from "../Math";
import {angleBetweenLines, getPoints} from "../Line";
import {pointsToContour} from "../Utils";
import {imShow} from "../../CBARView";

export const NUM_CLUSTERS = 7;

class CBARPageRegion extends CBARTrackedObject {

    public scale:number;

    constructor(rect:Rect, frameIndex:number, frameTime:number, maxAge:number) {
        const points:CBARTrackedPoint[] = [];

        points.push(new CBARTrackedPoint(new cv.Point(rect.ax,rect.ay)));
        points.push(new CBARTrackedPoint(new cv.Point(rect.bx,rect.by)));
        points.push(new CBARTrackedPoint(new cv.Point(rect.cx,rect.cy)));
        points.push(new CBARTrackedPoint(new cv.Point(rect.dx,rect.dy)));
        super(points, frameIndex, frameTime, maxAge);

        this.scale = 0.95;
    }

    private rectFromPoints(points:Point[]):Rect {
        return new Rect(
            points[0].x, points[0].y,
            points[1].x, points[1].y,
            points[2].x, points[2].y,
            points[3].x, points[3].y,
        );
    }

    public get rect():Rect {
        return this.rectFromPoints(this.points.map(tp=>tp.point));
    }

    public get roi():Rect {
        const midpoint = this.midpoint;
        const points = this.points.map(tp=>{
            const offsetX = tp.point.x - midpoint.x;
            const offsetY = tp.point.y - midpoint.y;
            return new cv.Point(midpoint.x + offsetX * this.scale, midpoint.y + offsetY * this.scale);
        });
        return this.rectFromPoints(points);
    }

    isMatch(candidate: CBARPageRegion): boolean {
        return rectsOverlap(this.roi, candidate.roi)
    }
}

export type CBARPageFinderNodeConfig = {
    updateMS:number
    scale:number
}

const _defaults = {
    updateMS: 1000,
    scale:0.5
}

export class CBARPageFinderNode extends CBARProcessNode {

    public config:CBARPageFinderNodeConfig;

    constructor(context:CBARPipeline, _config:CBARPageFinderNodeConfig=_defaults) {
        super(context)

        this.config = {..._defaults, ..._config};

        this._tracker = new CBARFeatureTrackerNode(this.pipeline, {
            maxFeatures:1
        });
    }

    private _tracker:CBARFeatureTrackerNode;

    private _lastExecTime?:number;
    private _busy = false;

    update(frame:CBARFrame):void {
        const age = this._lastExecTime ? frame.time - this._lastExecTime : Number.MAX_SAFE_INTEGER;

        this._tracker.update(frame);

        if (this._busy || age < this.config.updateMS) return;

        if (frame.colorSegmentationImage.empty()) return;

        this._busy = true;
        this._lastExecTime = frame.time;

        let img = new cv.Mat();
        let colorSegmentation = new cv.Mat();
        if (this.config.scale !== 1.0) {
            const size =  new cv.Size(this.config.scale * frame.colorSegmentationImage.cols,this.config.scale * frame.colorSegmentationImage.rows);
            cv.resize(frame.colorSegmentationImage, colorSegmentation, size, 0,0, cv.INTER_NEAREST);
            cv.resize(frame.greyscaleImage, img, size, 0,0, cv.INTER_NEAREST);
        } else {
            img = frame.greyscaleImage.clone();
            colorSegmentation = frame.colorSegmentationImage.clone();
        }
        const rescale = this.config.scale / this.pipeline.downsample;

        this.detect(img, colorSegmentation, rescale, frame.index, frame.time).finally(()=>{
            colorSegmentation.delete();
            img.delete();
            this._busy = false;
        })
    }

    private async detect(img:Mat, colorSegmentation:Mat, downscale:number, frameIndex:number, frameTime:number) {

        imShow(colorSegmentation);

        for (let k=Math.max(NUM_CLUSTERS-3, 1); k<NUM_CLUSTERS; k++) {
            const lowerRange = Math.floor(255 * k / NUM_CLUSTERS) - 2;
            const upperRange = Math.floor(255 * (k + 1) / NUM_CLUSTERS);
            const thresh = new cv.Mat();
            cv.threshold(colorSegmentation, thresh, lowerRange, upperRange, cv.THRESH_BINARY);

            const contoursResult = new cv.MatVector();
            const hierarchy = new cv.Mat();
            cv.findContours(thresh, contoursResult, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_NONE);
            const length = (contoursResult.size() as any) as number;

            const angleThreshold = Math.PI / 8.0;

            for (let i = 0; i < length; i++) {
                const contour = contoursResult.get(i);

                let poly = new cv.Mat();
                const length = cv.arcLength(contour, true);
                if (length < 100) continue;

                const epsilon = length / 8;
                cv.approxPolyDP(contour, poly, epsilon, true);

                if (poly.data32S.length > 2 * 3) {
                    const lines:Line[] = [];
                    for (let j = 0; j < poly.data32S.length; j+=2) {
                        const line = new Line(poly.data32S[j] / downscale, poly.data32S[j+1] / downscale,
                            poly.data32S[(j+2) % poly.data32S.length] / downscale, poly.data32S[(j+3) % poly.data32S.length] / downscale);
                        lines.push(line);
                    }

                    let isOK = true;
                    //todo: more proper check for a planar rect by angle:
                    for (let j=0; isOK && j<lines.length; j++) {
                        const angle = angleBetweenLines(lines[j], lines[(j+1) % lines.length]);
                        if (!isNaN(angle) && Math.abs(angle - Math.PI / 2.0) > angleThreshold) {
                            isOK = false;
                        }
                    }

                    if (isOK && lines.length === 3 || lines.length === 4) {
                        if (lines.length === 3) {
                            lines.push(new Line(lines[2].x2, lines[2].y2, lines[0].x1, lines[0].y1))
                        }

                        const points = getPoints(lines);
                        poly = pointsToContour(points);

                        //console.log("poly type", cvType2String(poly.type()), "test type", cvType2String(test.type())); //32SC20

                        //Validate interior/exterior for paper-ness
                        if (this.getPaperScore(colorSegmentation, img, poly)) {
                            const rect = new Rect();
                            rect.fromLines(lines[0], lines[1], lines[2], lines[3]);
                            this._tracker.track(new CBARPageRegion(rect, frameIndex, frameTime, this.config.updateMS * 3));
                        }
                    }
                }

                poly.delete();
            }

            //console.log(polygons.length > 0 ? `got ${polygons.length} polygons` : "none");

            contoursResult.delete();
            hierarchy.delete();
            thresh.delete();
        }

    }

    getPaperScore(seg:Mat, img:Mat, contour:Mat) {

        //Validate interior/exterior for paper-ness
        let checkMask = cv.Mat.zeros(img.rows, img.cols, cv.CV_8UC1);
        const cont = new cv.MatVector();
        cont.push_back(contour);
        cv.drawContours(checkMask, cont, 0, [255, 255, 255, 255], cv.FILLED);
        cont.delete();

        //const total = cv.countNonZero(checkMask);

        let insideM = new cv.Mat();
        let insideS = new cv.Mat();
        cv.meanStdDev(img, insideM, insideS, checkMask);
        const insideMean = [insideM.data64F[0], insideM.data64F[1], insideM.data64F[2]];
        const insideStd = [insideS.data64F[0], insideS.data64F[1], insideS.data64F[2]];

        let outsideM = new cv.Mat();
        let outsideS = new cv.Mat();
        cv.bitwise_not(checkMask, checkMask);
        cv.meanStdDev(img, outsideM, outsideS, checkMask);
        const outsideMean = [outsideM.data64F[0], outsideM.data64F[1], outsideM.data64F[2]];
        const outsideStd = [outsideS.data64F[0], outsideS.data64F[1], outsideS.data64F[2]];

        checkMask.delete();
        insideM.delete();
        insideS.delete();
        outsideM.delete();
        outsideS.delete();

        const meanDiff = Math.abs(insideMean[0] - outsideMean[0]) / (insideMean[0] + outsideMean[0]);
        const stdDiff = Math.abs(insideStd[0] - outsideStd[0]) / (insideStd[0] + outsideStd[0]);

        //console.log("stdDiff", stdDiff)
        //comparisons – 8.32755905511811 – 40.0625393081761 – 2.9387092663317866 – 43.76799248170926

        //console.log("comparisons", insideMean[0], outsideMean[0], insideStd[0], outsideStd[0]); //compared – 21.16888923144078 – 48.98305356273749
        //console.log("meanDiff", meanDiff);

        return meanDiff > 0.33 && stdDiff > 0.33;
    }

    debug(canvas:HTMLCanvasElement):void {
        this._tracker.debug(canvas);
    }

    destroy() {
        this._tracker.destroy();
    }
}