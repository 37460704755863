import * as THREE from "three";
import {CBARSurfaceAsset, CBARSurfaceAssetProperties} from "./CBARSurfaceAsset";
import {CBARError, CBARErrorCode} from "../CBARError";
import {CBARObject} from "../components/CBARObject";
import {CBARStandardMaterial} from "../components/CBARStandardMaterial";
import {CBARMaterialProperties, CBARSurface, RenderOptions} from "../components";
import {CBARContext} from "../CBARContext";
import {CBARAssetType, CBMaterialProperties} from "../CBARTypes";
import {CBARTile} from "./CBARTile";
import {CBARCircularTile} from "./CBARCircularTile";

export enum TiledGridType
{
    Monolithic = 'monolithic',
    Ashlar = 'ashlar',
    Stagger = 'stagger',
    Herringbone = 'herringbone',
    Brick = 'brick',
    Basketweave = 'basketweave',
}

export interface CBARTiledAssetProperties extends CBARSurfaceAssetProperties {
    materials:CBARMaterialProperties[]|CBMaterialProperties[]
    gridType?:TiledGridType
}

function checkInstanceOf<T>(object: any): object is T {
    return object.hasOwnProperty('materials');
}

export abstract class CBARTiledAsset extends CBARSurfaceAsset {

    protected _container = new THREE.Object3D();
    public sharedMaterials:CBARStandardMaterial[] = [];

    constructor(context:CBARContext) {
        super(context);
        this.setRenderObject(this._container);
    }

    public abstract get type() : CBARAssetType;

    private _gridType = TiledGridType.Monolithic;

    public get gridType() : TiledGridType {
        return this._gridType
    }

    public set gridType(value:TiledGridType) {
        this._gridType = value;
        this.layoutSubTiles().then();
    }

    private _shape = CBARRugShape.Rectangle

    public get shape() : CBARRugShape {
        return this._shape;
    }

    public set shape(value) {
        this._shape = value;
        this.layoutSubTiles().then(()=>{
            this.context.refresh();
        });
    }

    load(basePath: string|undefined, json:CBARTiledAssetProperties) : Promise<CBARTiledAsset> {

        if (!checkInstanceOf(json)) {
            return new Promise<CBARTiledAsset>((resolve, reject) => {reject(new Error('Not of type CBARTiledAssetProperties'))})
        }

        const promises: Promise<CBARObject<any>>[] = [];

        if (json.materials) {
            let index = 0;
            for (const props of json.materials) {
                const exists = index < this.sharedMaterials.length;
                const material = exists ? this.sharedMaterials[index] : new CBARStandardMaterial(this.context);

                promises.push(material.load(basePath, props));

                if (!exists) {
                    this.sharedMaterials.push(material);
                }

                index ++
            }
        }

        if (json.gridType) {
            this._gridType = json.gridType
        }

        return new Promise<CBARTiledAsset>((resolve, reject) => {
            super.load(basePath, json).then(() => {
                Promise.all(promises).then(() => {
                    this.setupMasking();
                    this.layoutSubTiles().then(()=>{
                        this.needsUpdate();
                        resolve(this)
                    });
                }).catch((error)=>{
                    reject(error)
                })
            }).catch(() => {
                this.rejectPromise(reject, new CBARError(`Cannot load ${this.description}`, CBARErrorCode.AssetLoad, this));
            })
        })
    }

    public unload() {
        super.unload();
        this.sharedMaterials.forEach(tex=>{
            tex.unload();
        });
        this.sharedMaterials = [];
    }

    public data(): any {
        const data: any = super.data();

        return data
    }

    get description(): string {
        return "Repeating Tiled Asset"
    }

    protected subTiles: CBARTile[] = [];

    protected get individualTileSize() : THREE.Vector2 {
        if (this.sharedMaterials.length && this.sharedMaterials[0].physicalDimensions) {
            return this.sharedMaterials[0].physicalDimensions
        }
        return new THREE.Vector2(1,1)
    }

    //number of tiles in x,y
    protected abstract get tiledCount() : THREE.Vector2;

    protected getGridRange(bounds:THREE.Box2, tileSize:THREE.Vector2) : THREE.Box2 {
        let range = new THREE.Box2();

        switch (this._gridType)
        {
            case TiledGridType.Brick:
                range.min.x = bounds.min.x / tileSize.y;
                range.max.x = bounds.max.x / tileSize.y;

                range.min.y = bounds.min.y / tileSize.x;
                range.max.y = bounds.max.y / tileSize.x;
                break;

            case TiledGridType.Ashlar:
            case TiledGridType.Stagger:
            case TiledGridType.Herringbone:
            case TiledGridType.Monolithic:

            default:
                range.min.x = bounds.min.x / tileSize.x;
                range.max.x = bounds.max.x / tileSize.x;

                range.min.y = bounds.min.y / tileSize.y;
                range.max.y = bounds.max.y / tileSize.y;
                break;
        }

        return range
    }

    protected getPositionAndRotation(x:number, y:number, tileSize:THREE.Vector2) : [THREE.Vector2, number] {
        let position = new THREE.Vector2();
        let angle = 0;

        switch (this._gridType) {
            case TiledGridType.Ashlar: {
                let yOffset = x % 2 == 0 ? 0.0 : -tileSize.y / 2.0;
                position = new THREE.Vector2(x * tileSize.x, y * tileSize.y + yOffset);
                break;
            }
            case TiledGridType.Stagger: {
                let yOffset = 0.0;
                switch (Math.abs(x) % 5)
                {
                    case 1:
                        yOffset = -tileSize.y / 5.0;
                        break;
                    case 2:
                        yOffset = -2.0 * tileSize.y / 5.0;
                        break;
                    case 3:
                        yOffset = -3.0 * tileSize.y / 5.0;
                        break;
                    case 4:
                        yOffset = tileSize.y / 5.0;
                        break;
                    default:
                        yOffset = 0.0;
                        break;
                }
                position = new THREE.Vector2(x * tileSize.x, y * tileSize.y + yOffset);
                break;
            }
            case TiledGridType.Brick:
            {
                let xOffset = y % 2 == 0 ? 0.0 : tileSize.y / 2.0;
                position = new THREE.Vector2(x * tileSize.y + xOffset, y * tileSize.x);
                angle = Math.PI/2.0;
                break;
            }

            case TiledGridType.Herringbone:
            case TiledGridType.Monolithic:

            default:
            {
                position = new THREE.Vector2(x * tileSize.x, y * tileSize.y);
                break;
            }
        }

        return [position, angle]
    }

    protected generateTile(material:CBARStandardMaterial) : CBARTile {
        if (this._shape === CBARRugShape.Rectangle) {
            return new CBARTile(this, this.context, material);
        }
        return new CBARCircularTile(this, this.context, material);
    }

    protected async layoutSubTiles() {
        if (!this.renderObject || !this.surface || !this.sharedMaterials.length || !this.sharedMaterials[0].physicalDimensions) return;

        this.subTiles.forEach(tile=>{
            this.renderObject!.remove(tile.renderObject!)
        });
        this.subTiles = [];

        const tileSize = this.dimensions;

        const bounds = new THREE.Box2(
            new THREE.Vector2(-tileSize.x/2.0, -tileSize.y/2.0),
            new THREE.Vector2(tileSize.x/2.0, tileSize.y/2.0));

        let range = this.getGridRange(bounds, tileSize);

        let count = 0;

        const repeat =  new THREE.Vector2(this.dimensions.x / this.individualTileSize.x, this.dimensions.y / this.individualTileSize.y);

        for (let y = range.min.y; y < range.max.y; y++) {
            for (let x = range.min.x; x < range.max.x; x++) {
                let [pos, rotation] = this.getPositionAndRotation(x , y, tileSize);

                const index = this.sharedMaterials.length > 1 ? Math.floor(Math.random() * (this.sharedMaterials.length - 1)) : 0;
                const tile = this.generateTile(this.sharedMaterials[index]);
                tile.size = tileSize;
                tile.offset = new THREE.Vector2(pos.x + 0.5 * tileSize.x, pos.y + 0.5 * tileSize.y);
                tile.rotation = rotation;
                tile.mesh.castShadow = this.castShadow;
                tile.mesh.receiveShadow = this.receiveShadow;
                tile.material.repeat = tile.material.wrappingX === THREE.ClampToEdgeWrapping ? new THREE.Vector2(1,1) : repeat;

                this.subTiles.push(tile);
                this.renderObject.add(tile.renderObject);
                count ++;
            }
        }

        //this._container.position.set(0,0,0);

        //console.log("Placed self at ", this._container.position)

        //console.log(`Rendered ${count} tiles`, range.getSize(new THREE.Vector2()), bounds.getSize(new THREE.Vector2()), tileSize);
    }

    public render(options:RenderOptions) {
        super.render(options);
        this.subTiles.forEach(tile=>tile.render(options));
    }

    private setupMasking() {
        if (!this.surface) return;

        let lightingFactor = 1.5;
        let lightingOffset = 0.0;
        const maxValue = 1.5;

        if (this.context.scene && this.surface) {
            lightingFactor = this.context.scene.lightingFactor * (1.0 + (this.surface.lightingMeanIntensity - this.surface.backgroundMeanIntensity) / 255.0);
            lightingOffset = this.context.scene.lightingOffset + Math.max(lightingFactor - maxValue, 0.0) * 255.0;
        }

        for (const material of this.sharedMaterials) {
            if (this.context.scene?.envMap) {
                material.threeMaterial.envMap = this.context.scene.envMap;
                material.threeMaterial.envMapIntensity = 0.5;
                material.threeMaterial.needsUpdate = true
            }

            if (this.surface.maskTexture?.threeTexture) {
                material.threeMaterial.alphaMap = this.surface.maskTexture.threeTexture
            }

            if (this.context.scene?.lightingTexture?.threeTexture) {
                material.threeMaterial.lightMap = this.context.scene.lightingTexture.threeTexture
            }

            material.maskIndex = this.surface.isLegacyMask ? -1 : this.surface.maskIndex;
            material.threeMaterial.depthTest = false;

            material.lightingFactor = lightingFactor;
            material.lightingOffset = lightingOffset;
        }
    }

    addedToSurface(surface: CBARSurface): void {
        this.setupMasking();
        this.layoutSubTiles().then(()=>{
            this.context.refresh();
        });
    }

    public get receiveShadow() {
        return this._container.receiveShadow;
    }

    public set receiveShadow(value:boolean) {
        this._container.receiveShadow = value;
        this.subTiles.forEach(child=>child.mesh.receiveShadow = value);
    }

    public get castShadow() {
        return this._container.castShadow;
    }

    public set castShadow(value:boolean) {
        this._container.castShadow = value;
        this.subTiles.forEach(child=>child.mesh.castShadow = value);
    }

    protected _dimensions = new THREE.Vector2();

    //2D dimensions of this object
    public get dimensions() : THREE.Vector2 {
        return this._dimensions;
    }

    public set dimensions(value:THREE.Vector2) {
        this._dimensions = value;
        this.layoutSubTiles().then(()=>{
            this.needsUpdate();
        });
    }
}

export class CBARSingleTile extends CBARTiledAsset {
    public get type() : CBARAssetType {
        return CBARAssetType.TiledSurface
    }

    protected get tiledCount() : THREE.Vector2 {
        return new THREE.Vector2(1,1)
    }

    get description(): string {
        return "Single Tiled Asset"
    }
}

export class CBARTiledRectangle extends CBARTiledAsset {

    public get type() : CBARAssetType {
        return CBARAssetType.TiledRectangle
    }

    protected get tiledCount() : THREE.Vector2 {
        return new THREE.Vector2(Math.ceil(this.dimensions.width / this.individualTileSize.width),
            Math.ceil(this.dimensions.height / this.individualTileSize.height));
    }
    get description(): string {
        return "Tiled Rectangle Asset"
    }
}

export class CBARFilledTiledAsset extends CBARSingleTile {

    public get type() : CBARAssetType {
        return CBARAssetType.TiledSurface
    }

    public get dimensions() : THREE.Vector2 {
        if (this._dimensions.x > 0) {
            return this._dimensions;
        }
        else if (this.surface) {
            //square of hypotenuse, double for good measure (aka rotation)
            const diameter = 2 * (Math.sqrt(Math.pow(this.surface.extent.x, 2.0) + Math.pow(this.surface.extent.y, 2.0)));
            const highest = Math.sqrt(2) * Math.max(diameter, diameter);

            return new THREE.Vector2(2 * highest, 2 * highest) //todo, something wrong with math, because too small until multiplied by 2.
        }
        return new THREE.Vector2(1,1);
    }

    public set dimensions(value:THREE.Vector2) {
        this._dimensions = value;
    }

    get description(): string {
        return "Filled Tiled Asset"
    }
}

export enum CBARRugShape {
    Rectangle = 'rectangle',
    Ellipse = 'ellipse',
}

export interface CBARRugAssetProperties extends CBARTiledAssetProperties {
    shape?:CBARRugShape
}

export class CBARRugAsset extends CBARSingleTile {
    public get type() : CBARAssetType {
        return CBARAssetType.Rug
    }

    get description(): string {
        return "Rug Asset"
    }
}
