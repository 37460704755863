import {
    CBAREventHandler,
    CBAREventType,
    CBARMode,
    CBARRenderContext,
    CBARSurfaceType,
    CBARToolMode, Point2D
} from "./CBARTypes";
import {CBARScene, CBARSceneProperties} from "./components/CBARScene";
import {CBServerConfig, getConfig} from "../backend";

export type ImageExportOptions = {
    format:'jpeg'|'png'
    quality?:number
}

export enum CBARFeatureTracking {
    None="none",
    World="world",
    Face="face",
    Card="card",
    Page="page",
    Classifier="classifier",
}

/** https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackConstraints/facingMode **/
export enum CBARCameraFacing {
    Default='default',
    Environment='environment',
    User='user'
}

export enum CBARCameraResolution {
    Default = 1280,
    Low = 640,
    HighDef = 2160,
    Maximum = 4096
}

export enum CBARDebug {
    None = 0,
    OpticalFlow = 1 << 0,
    HoughLines = 1 << 1,
    TrackedLines = 1 << 2,
    ColorSegmentation = 1 << 3,
    All = ~(~0 << 4)
}

export enum ZoomState {
    ZoomedIn="zoom-in",
    ZoomedOut="zoom-out",
    FitScreen="fit-screen"
}

export interface CBARReceiver {
    loadSceneAtPath:(context:CBARContext, jsonPath:string, surfaceTypes?:CBARSurfaceType[])=>Promise<CBARScene>
    loadSceneData:(context:CBARContext, json:CBARSceneProperties, surfaceTypes?:CBARSurfaceType[], room?:string|null|undefined, subroom?:string|null|undefined)=>Promise<CBARScene>
    startVideoCamera:(context:CBARContext, tracking:CBARFeatureTracking, facing:CBARCameraFacing)=>Promise<void>
    stopVideoCamera:(context:CBARContext)=>void
    captureImage:(context:CBARContext) => Promise<HTMLImageElement>
    captureScreenshot:(context:CBARContext, format?:ImageExportOptions) => Promise<string>
    loadImage:(context:CBARContext, image:HTMLImageElement) => void
    refresh:(context:CBARContext) => void
    getMode:(context:CBARContext)=>CBARMode
    getToolMode:(context:CBARContext)=>CBARToolMode
    getScene:(context:CBARContext)=>CBARScene|undefined

    getZoomScale:()=>number
    setZoomScale:(value:number)=>void
    scaleToFit:number

    getZoomState:()=>ZoomState
    setZoomState:(state:ZoomState)=>void

    pointToScreenPosition:(imagePoint:Point2D)=>Point2D
}

export function isDrawingTool(mode:CBARToolMode) {
    return mode === CBARToolMode.DrawSurface || mode === CBARToolMode.EraseSurface
}

export class CBARContext {

    constructor(public gl:CBARRenderContext, private _receiver:CBARReceiver) {
        getConfig().then(c=>{
            this._config = c
        })
    }

    public _config!:CBServerConfig

    public get config() {
        return this._config
    }

    private _handlers: {[key: number]: CBAREventHandler[]} = {}

    public addHandler(type:CBAREventType, handler:CBAREventHandler) {
        if (!this._handlers.hasOwnProperty(type)) {
            this._handlers[type] = []
        }
        if (this._handlers[type].indexOf(handler) < 0) {
            this._handlers[type].push(handler)
        }
    }

    public removeHandler(type:CBAREventType, handler:CBAREventHandler) {
        const index = this._handlers[type].indexOf(handler);
        if (index >= 0) {
            this._handlers[type].splice(index, 1)
        }
    }

    public getHandlers(type:CBAREventType) : CBAREventHandler[] {
        return this._handlers.hasOwnProperty(type) ? this._handlers[type] : [];
    }

    public captureImage() {
        return this._receiver.captureImage(this)
    }

    public captureScreenshot(format?:ImageExportOptions) {
        return this._receiver.captureScreenshot(this, format);
    }

    public loadImage(image: HTMLImageElement) {
        return this._receiver.loadImage(this, image)
    }

    public refresh() {
        return this._receiver.refresh(this)
    }

    public loadSceneAtPath(jsonPath: string, surfaceTypes?:CBARSurfaceType[]) {
        return this._receiver.loadSceneAtPath(this, jsonPath, surfaceTypes)
    }

    public loadSceneData(json: CBARSceneProperties, surfaceTypes?:CBARSurfaceType[], room?:string|null|undefined, subroom?:string|null|undefined) {
        return this._receiver.loadSceneData(this, json, surfaceTypes, room, subroom)
    }

    public get scene() {
        return this._receiver.getScene(this)
    }

    public get mode() {
        return this._receiver.getMode(this)
    }

    public get toolMode() {
        return this._receiver.getToolMode(this)
    }

    public get isDrawing() {
        return isDrawingTool(this.toolMode)
    }

    public startVideoCamera(tracking = CBARFeatureTracking.None, facing=CBARCameraFacing.Environment) {
        return this._receiver.startVideoCamera(this, tracking, facing);
    }

    public stopVideoCamera() {
        return this._receiver.stopVideoCamera(this);
    }

    public get zoomScale() {
        return this._receiver.getZoomScale();
    }

    public set zoomScale(value) {
        this._receiver.setZoomScale(value);
    }

    public get scaleToFit() {
        return this._receiver.scaleToFit;
    }

    public get zoomState() {
        return this._receiver.getZoomState();
    }

    public set zoomState(state:ZoomState) {
        this._receiver.setZoomState(state);
    }

    public pointToScreenPosition(imagePoint:Point2D) : Point2D {
        return this._receiver.pointToScreenPosition(imagePoint);
    }
}