import {CBAREvent, CBAREventType} from "../CBARTypes";
import {CBARObject3D} from "./CBARObject3D";
import * as THREE from "three";
import {CBARCollection} from "../CBARCollection";
import {RenderOptions} from "./CBARScene";


export abstract class CBARObject3DContainer<T> extends CBARCollection<T> {

    protected abstract getObject3D(event: CBAREvent) : CBARObject3D<T>|undefined

    public abstract render(options:RenderOptions) : void;

    private _draggingObject?:CBARObject3D<T>;

    private handleDragging(event: CBAREvent, obj?:CBARObject3D<T>) {
        const dragEvent = {...event};

        if (event.type == CBAREventType.TouchMove) {
            if (this._draggingObject) {
                dragEvent.type = CBAREventType.DragMove;
                this._draggingObject.handleEvent(dragEvent);
            }
        }
        else if (event.type == CBAREventType.TouchDown && obj) {
            this._draggingObject = obj;
            dragEvent.type = CBAREventType.DragStart;
            this._draggingObject.handleEvent(dragEvent);

        } else if ((event.type == CBAREventType.TouchUp || event.type == CBAREventType.TouchLeave || event.type == CBAREventType.MouseOut) && this._draggingObject) {
            dragEvent.type = CBAREventType.DragEnd;
            this._draggingObject.handleEvent(dragEvent);
            this._draggingObject = undefined;
        }
    }

    public handleEvent(event: CBAREvent) {
        const obj = this.getObject3D(event);

        this.handleDragging(event, obj);

        if (obj) {
            obj.handleEvent(event)
        }
    }

    abstract sceneLoaded():void

    abstract getEventObjects(eventType:CBAREventType) : THREE.Object3D[]
}