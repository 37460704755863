import {CBARProcessNode} from "./CBARProcessNode";
import {CBARFrame} from "../CBARFrame";
import {CBARPipeline} from "./CBARPipeline";
import {CascadeClassifier, Mat, Point, Size} from "mirada";
import {Rect} from "gammacv";
import {CBARFeatureTrackerNode} from "./CBARFeatureTrackerNode";
import {CBARTrackedObject, CBARTrackedPoint} from "./CBARTrackedObject";
import {rectsOverlap} from "../Math";

const dataFiles:string[] = [];

//const eyeClassifier = require("../includes/haarcascade_eye.xml") as string;

//Training the classifier:
//https://memememememememe.me/post/training-haar-cascades/

class CBARTrackedRegion extends CBARTrackedObject {

    public classifierScale:number;

    constructor(rect:Rect, frameIndex:number, frameTime:number, maxAge:number, scale:number) {
        const points:CBARTrackedPoint[] = [];

        points.push(new CBARTrackedPoint(new cv.Point(rect.ax,rect.ay)));
        points.push(new CBARTrackedPoint(new cv.Point(rect.bx,rect.by)));
        points.push(new CBARTrackedPoint(new cv.Point(rect.cx,rect.cy)));
        points.push(new CBARTrackedPoint(new cv.Point(rect.dx,rect.dy)));
        super(points, frameIndex, frameTime, maxAge);

        this.classifierScale = scale;
    }

    private rectFromPoints(points:Point[]):Rect {
        return new Rect(
            points[0].x, points[0].y,
            points[1].x, points[1].y,
            points[2].x, points[2].y,
            points[3].x, points[3].y,
        );
    }

    public get rect():Rect {
        return this.rectFromPoints(this.points.map(tp=>tp.point));
    }

    public get roi():Rect {
        const midpoint = this.midpoint;
        const points = this.points.map(tp=>{
            const offsetX = tp.point.x - midpoint.x;
            const offsetY = tp.point.y - midpoint.y;
            return new cv.Point(midpoint.x + offsetX * this.classifierScale, midpoint.y + offsetY * this.classifierScale);
        });
        return this.rectFromPoints(points);
    }

    isMatch(candidate: CBARTrackedRegion): boolean {
        //const ratio = Math.min(this.roi.area, candidate.roi.area) / Math.max(this.roi.area, candidate.roi.area);
        return rectsOverlap(this.roi, candidate.roi)
    }
}

export type CBARClassifierNodeConfig = {
    classifierPath:string,
    maxRegions?: number,
    updateMS?: number,
    scale?: number,
    minSize?: Size|undefined,
    maxSize?: Size|undefined,
}

const _defaults = {
    maxRegions: 1,
    updateMS: 1000,
    scale: 0.5,
}

export type _CBARClassifierNodeConfig = {
    classifierPath:string,
    maxRegions: number,
    updateMS: number,
    scale: number,
    minSize?: Size|undefined,
    maxSize?: Size|undefined,
}

export class CBARClassifierNode extends CBARProcessNode {

    public config:_CBARClassifierNodeConfig;

    constructor(context:CBARPipeline, _config:CBARClassifierNodeConfig) {
        super(context)

        this.config = {..._defaults, ..._config};

        this._classifier = new cv.CascadeClassifier();
        console.log("Loading classifier at", this.config.classifierPath);

        this._tracker = new CBARFeatureTrackerNode(this.pipeline, {
            maxFeatures:this.config.maxRegions
        });

        const fileName = this.config.classifierPath.split("/").pop() as string;

        if (dataFiles.indexOf(fileName) >= 0) {
            this._classifier.load(fileName);
        } else {
            fetch(this.config.classifierPath).then(response=>{
                response.arrayBuffer().then((buffer)=>{
                    // do something with buffer
                    let data = new Uint8Array(buffer);
                    cv.FS.createDataFile('/', fileName, data, true, false, false);
                    dataFiles.push(fileName);

                    this._classifier.load(fileName);
                    console.log("Loaded classifier file", fileName);
                });
            }).catch(()=>{
                console.error("Could not locate classifier at path", this.config.classifierPath)
            })
        }
    }

    private _classifier:CascadeClassifier;
    private _tracker:CBARFeatureTrackerNode;

    private _lastExecTime?:number;
    private _busy = false;

    update(frame:CBARFrame):void {
        const age = this._lastExecTime ? frame.time - this._lastExecTime : Number.MAX_SAFE_INTEGER;

        this._tracker.update(frame);

        if (this._classifier.empty() || this._busy || age < this.config.updateMS) return;

        this._busy = true;
        this._lastExecTime = frame.time;

        const greyscale = frame.greyscaleImage.clone();
        cv.resize(greyscale, greyscale, new cv.Size(this.config.scale * greyscale.cols,this.config.scale * greyscale.rows));
        const rescale = this.config.scale / this.pipeline.downsample;

        this.detect(greyscale, rescale, frame.index, frame.time).finally(()=>{
            greyscale.delete();
            this._busy = false;
        })
    }

    //perspective transform https://github.com/PeculiarVentures/GammaCV/issues/28
    private _featureAspectRatio:number = 1

    private async detect(greyscale:Mat, downscale:number, frameIndex:number, frameTime:number) {
        const matches = new cv.RectVector();

        if (this.config.minSize && this.config.maxSize) {
            const minSize = new cv.Size(greyscale.cols * this.config.minSize.width, greyscale.rows * this.config.minSize.height);
            const maxSize = new cv.Size(greyscale.cols * this.config.maxSize.width, greyscale.rows * this.config.maxSize.height);
            console.log(`Detecting from ${minSize.width.toFixed(2)}x${minSize.height.toFixed(2)} to ${maxSize.width.toFixed(2)}x${maxSize.height.toFixed(2)} in ${greyscale.cols}x${greyscale.rows}`);
            this._classifier.detectMultiScale(greyscale, matches, 1.1, 3, 0, minSize, maxSize);
        } else {
            console.log(`Detecting in ${greyscale.cols}, ${greyscale.rows}`);
            this._classifier.detectMultiScale(greyscale, matches, 1.1, 3, 0);
        }

        for (let i = 0; i < matches.size(); ++i) {
            let match = matches.get(i);

            this._featureAspectRatio = match.width / match.height;

            const rect = new Rect(match.x / downscale, match.y / downscale,
                (match.x + match.width) / downscale, match.y / downscale,
                (match.x + match.width) / downscale, (match.y + match.height) / downscale,
                match.x / downscale, (match.y + match.height) / downscale);

            this._tracker.track(new CBARTrackedRegion(rect, frameIndex, frameTime, this.config.updateMS * 3, 0.75));
        }
    }

    debug(canvas:HTMLCanvasElement):void {
        this._tracker.debug(canvas);
    }

    destroy() {
        this._tracker.destroy();
    }
}