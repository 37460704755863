import {CBARObject3D, CBARObject3DProperties} from "./CBARObject3D";
import {
    CBAREvent,
    CBAREventType,
    CBARHighlightState,
    CBARHistoryState,
    CBARMouseEvent,
    CBARSurfaceType,
    CBARToolMode,
    getSurfaceType
} from "../CBARTypes";
import {CBARImageCollection, CBARImageDictionary} from "./CBARImageCollection";
import {CBARSurfaceAsset} from "../assets/CBARSurfaceAsset";
import {CBARContext, isDrawingTool} from "../CBARContext";

import {CBARMaterialProperty, CBARTextureType} from "./CBARMaterial";

import * as THREE from "three";
import {LineGeometry} from "three/examples/jsm/lines/LineGeometry";
import {LineMaterial} from "three/examples/jsm/lines/LineMaterial";
import {Line2} from "three/examples/jsm/lines/Line2";
import {CBARImage} from "./CBARImage";
import {Bounds, getCanvasMat, normalizedToScreen, pointsToContour} from "../internal/Utils";
import {CBARMaskTexture} from "./CBARMaskTexture";
import {CBARStandardMaterial} from "./CBARStandardMaterial";
import {InputArray, Mat, Point} from "mirada";
import {CBARImaging, Labels} from "../internal/CBARImaging";
import {ErrorLog} from "../internal/GlobalLogger";
import {euclideanDist, euclideanDistSq} from "../internal/Math";
import {Line} from "gammacv";
import {angleBetweenLines, isOnEdge, lineBisector} from "../internal/Line";
import {RenderOptions} from "./CBARScene";

const MAX_CONTOURS = 50;

const colors = ['aqua', 'blue', 'red', 'green', 'lime', 'maroon', 'navy', 'olive',
    'purple', 'fuchsia', 'silver', 'teal', 'white', 'yellow', 'orange'];
let colorIndex = 0;

type DrawingPoint = {
    point:Point
    radius:number
    time:number
    drawn:boolean
}

const SHOW_SURFACES = false; //To reveal surface as colored object

const DEFAULT_DISTANCE_METERS = 4.0;
const DRAW_RADIUS_METERS = 0.04;
const DRAW_MAX_DISTANCE_METERS = 10;
export let DRAW_RADIUS = DRAW_RADIUS_METERS / DEFAULT_DISTANCE_METERS

const DRAWING_POINT_REFRESH = 300;
const DRAWING_POINT_TIMEOUT = DRAWING_POINT_REFRESH + 200;

const REFINE_EPSILON = 5;
const NUM_ANIMATION_FRAMES = 5

let showSurface = -1; //Set to mask index to display for debugging

export type Scalar = number[]

const getIntensity = (color:Scalar|Float64Array|number) => {
    if (typeof color === 'number') {
        return color
    }
    return color.length > 1 ? color[1] : color[0];
}

export interface CBARSurfaceProperties extends CBARObject3DProperties {
    type:CBARSurfaceType
    color?:string
    normal:Scalar
    offset:number
    axisRotation?:number
    maskIndex?:number
    images?:CBARImageDictionary
    backgroundMean?:Scalar
    backgroundStdDev?:Scalar
    lightingMean?:Scalar
    lightingStdDev?:Scalar
}

const APPROX_POLY = true;
const POLY_EPSILON = 1.0;
const MAX_PROJECTED_DISTANCE = 100.0;
const MIN_CONTOUR_AREA = 20 * 20;

export class CBARSurface extends CBARObject3D<CBARSurface> {

    _generatedColor = new THREE.Color(colors[(colorIndex ++) % colors.length]);

    public constructor(context:CBARContext, public readonly maskIndex:number) {
        super(context);

        this.material = new THREE.MeshLambertMaterial({color:this._generatedColor, transparent: true, opacity: this.baseOpacity, depthTest:false});
        this._placeholderMaterial = new CBARStandardMaterial(this.context);
        this._placeholderMaterial.threeMaterial.visible = false;

        this.setRenderObject(this._container);
    }

    private get usesPlaceholder() {
        return this.type === CBARSurfaceType.Floor;
    }

    private get hasPlaceholder() {
        return this.usesPlaceholder && !!this.context.scene?.placeholderTexture.image && this.length() === 0;
    }

    public needsUpdate() {

        this._placeholderMaterial.setMaterialProperty(CBARMaterialProperty.opacity, this.hasPlaceholder ? 1.0 : 0.0001);
        this._placeholderMaterial.threeMaterial.visible = !!this._placeholderMaterial.threeMaterial.map;

        this._placeholderMeshes.forEach(m=>m.visible = this.hasPlaceholder);

        //console.log("needsUpdate, hide placeholder?", this.hasPlaceholder);

        // const config = getConfig();
        // let opacity = 0.0;
        // if (this.selected && !this.length() && !this.context.isDrawing) {
        //     opacity = Math.max(opacity, config.selectedOutlineOpacity);
        // }
        // if (this.getState(CBARHighlightState.Hover).active) {
        //     opacity = Math.max(opacity, config.hoverOutlineOpacity);
        // }
        //
        // for (const outline of this._outlines) {
        //     const material = outline.material as THREE.Material;
        //     if (!material) break;
        //     outline.visible = opacity > 0.0
        // }
        //
        // if (this.selected && this.context.isDrawing) {
        //     this.material.opacity = this.length() ? this.baseOpacity : 0.4;
        // }
        //
        // if (this.getState(CBARHighlightState.Hover).active) {
        //     this.material.opacity = Math.max(this.material.opacity, config.hoverBGOpacity);
        // }
    }

    private _container = new THREE.Group();
    public baseOpacity = SHOW_SURFACES ? 0.5 : 0.0;
    public material:THREE.MeshLambertMaterial;

    protected images = new CBARImageCollection(this.context);

    private _assets: { [id: string]: CBARSurfaceAsset} = {};

    public get selectedColor() {
        return this.color
    }

    public get values() : { [id: string] : CBARSurfaceAsset; } {
        return this._assets
    }

    public first() {
        const length = this.length();
        if (length) {
            return this.all()[0];
        }
    }

    public last() {
        const length = this.length();
        if (length) {
            return this.all()[length-1];
        }
    }

    public all() {
        return Object.values(this._assets)
    }

    public get sorted() : CBARSurfaceAsset[] {
        return Object.values(this._assets).sort((a, b) => a.surfaceElevation - b.surfaceElevation)
    }

    private sortAssets() {
        const assets = this.sorted;//why is this changing?
        assets.forEach(asset=>{
            this.renderObject.remove(asset.renderObject);
        });
        assets.forEach(asset=>{
            this.renderObject.add(asset.renderObject);
        });

        this.needsUpdate();
    }

    public add(asset:CBARSurfaceAsset, elevation=0.0) {

        asset.surface = this;

        if (this.type === CBARSurfaceType.Floor) {
            //place in front of us on the floor, otherwise (default) the center of the surface
            const maskCenter = new THREE.Vector2(0.5,0.9);//hard coded center of floor in front and down.
            const center = this.screenToSurfacePosition(maskCenter);
            asset.setSurfacePosition(center.x, center.y);
        }

        asset.surfaceElevation = elevation;
        
        this._assets[asset.id] = asset;

        this.context.scene?.assets.add(asset);
        asset.addedToSurface(this);

        this.sortAssets();
    }

    public containsKey(key:string) : boolean {
        return this._assets.hasOwnProperty(key);
    }

    public remove(asset:CBARSurfaceAsset) {
        if (!this.containsKey(asset.id) || asset.surface !== this) return;
        this.context.scene?.assets.remove(asset);
        this.needsUpdate();
    }

    //internal: called from CBARSurfaceAsset.removeFromScene()
    private internal_removeKey(key:string) {
        delete this._assets[key]
        this.needsUpdate();
    }

    public length(): number {
        return Object.keys(this._assets).length
    }

    private _extent = new THREE.Vector2();

    public get extent() : THREE.Vector2 {
        return this._extent
    }

    public type = CBARSurfaceType.Other;

    private _planeNormal?:THREE.Vector3;
    public get planeNormal() {
        return this.plane.normal;
    }

    public set planeNormal(value:THREE.Vector3) {
        if (value) {
            this.plane.normal = value;
            this.regenerateMeshes(true);
            this.needsUpdate();
        }
    }

    private _planeOffset?: number;
    public get planeOffset() {
        return this.plane.constant;
    }

    public set planeOffset(value:number|undefined) {
        if (value) {
            this.plane.constant = value;
            this.regenerateMeshes(true);
            this.needsUpdate();
        }
    }

    public axisRotation = 0.0;

    private _raycaster = new THREE.Raycaster();

    public get color() {
        return this.material.color
    }

    public set color(value) {
        this.material.color = value
    }

    public _maskTexture?:CBARMaskTexture;

    public get maskTexture() {
        return this._isLegacyMask ? this._maskTexture : this.context.scene?.indexMaskTexture;
    }

    private _isLegacyMask = false;
    public get isLegacyMask() {
        return this._isLegacyMask;
    }

    public plane = new THREE.Plane();

    private _outlines:Line2[] = [];
    private _shapeMeshes:THREE.Mesh[] = [];
    private _placeholderMeshes:THREE.Mesh[] = [];
    private _placeholderMaterial:CBARStandardMaterial;

    // @ts-ignore
    load(basePath:string|undefined, json:CBARSurfaceProperties, _verticalAxis?:"z"|"y", groundRotation:number, room?:string|null|undefined, subroom?:string|null|undefined) : Promise<CBARSurface> {

        if (json.type ) {
            this.type = getSurfaceType(json.type)
        }

        const verticalAxis = _verticalAxis ? _verticalAxis : "z"
        //console.log("Creating surface", this.type, "axis", verticalAxis)

        if (json.color) {
            this.color = new THREE.Color(parseInt(json.color, 16))
        }

        if (json.normal) {
            this._planeNormal = verticalAxis === "y" ? new THREE.Vector3(json.normal[0], json.normal[1], json.normal[2]) : new THREE.Vector3(-json.normal[0], -json.normal[2], json.normal[1])
        }

        if (json.axisRotation) {
            this.axisRotation = verticalAxis === "y" ? json.axisRotation : -json.axisRotation;
        } else if (this.type == CBARSurfaceType.Floor || this.type == CBARSurfaceType.Ceiling) {
            this.axisRotation = this.type == CBARSurfaceType.Floor ? groundRotation : -1 * groundRotation;
        }

        if (json.backgroundMean) {
            this.backgroundMeanIntensity = getIntensity(json.backgroundMean)
        }

        if (json.backgroundStdDev) {
            this.backgroundStdDevIntensity = getIntensity(json.backgroundStdDev)
        }

        if (json.lightingMean) {
            this.lightingMeanIntensity = getIntensity(json.lightingMean)
        }

        if (json.lightingStdDev) {
            this.lightingStdDevIntensity = getIntensity(json.lightingStdDev)
        }

        // console.log(`Lighting ${this.hasCalculatedLighting ? "was pulled from json.\n" : "will be calculated.\n"}`,
        //     "backgroundMean", this.backgroundMeanIntensity, "lightingMean", this.lightingMeanIntensity);

        this._planeOffset = json.offset ? json.offset : Number.EPSILON;

        const promises:PromiseLike<any>[] = [super.load(basePath, json)];

        if (json.images) {
            promises.push(this.images.load(basePath, json.images, room, subroom));
        }

        return new Promise<CBARSurface>((resolve, reject) => {
            Promise.all(promises).then(()=>{
                if (this._planeOffset && this._planeNormal) {
                    this.plane = new THREE.Plane(this._planeNormal, this._planeOffset);
                    this.extent.x = 0.0;
                    this.extent.y = 0.0;
                }

                this._isLegacyMask = this.images.containsKey("mask");

                if (this._isLegacyMask && this.maskImage && this.maskImage.area) {
                    this._maskTexture = new CBARMaskTexture(this.context, CBARTextureType.alpha);
                    this._maskTexture.loadImage(this.maskImage);
                    this.regenerateMeshes(true);
                }

                resolve(this)
            }).catch(error=>{
                reject(error)
            })
        })
    }

    public get maskImage() : CBARImage | undefined {
        if (this.context.scene?.indexMask) {
            return this.context.scene.indexMask;
        }
        else if (this.images.containsKey("mask")) {
            return this.images.values['mask'] as CBARImage;
        }
    }

    get description() : string {
        return `${this.type} Surface ${this.maskIndex}`
    }

    public data() : any {
        const data:any = super.data();

        data.extent = [this.extent.x, this.extent.y];

        data.color = this.color.getHexString();

        return data
    }

    private drawingMaybeChanged(toolMode:CBARToolMode) {

        if (!this.maskTexture) {
            console.log("No mask texture");
            return;
        }

        if (this.maskTexture?.isEditing) {
            if  (!this.isDrawingOn || !this.selected || !isDrawingTool(toolMode)) {
                this.stoppedEditing();
            }
        } else {
            if (this.selected && this.isDrawingOn && !this.maskTexture?.isEditing) {
                this.startedEditing();
            }
        }
    }

    private _drawingPoints:DrawingPoint[] = [];
    private _drawingPointsAreErase = false;

    private drawEraseAtPoint(point: THREE.Vector2) {
        this.drawingMaybeChanged(this.context.toolMode);

        const wasErase = this._drawingPointsAreErase;
        this._drawingPointsAreErase = this.context.toolMode === CBARToolMode.EraseSurface;
        if (wasErase !== this._drawingPointsAreErase) {
            this.clearPoints()
        }

        this._drawingPoints.push({point:point, radius:DRAW_RADIUS, time:performance.now(), drawn:false});
    }

    private _setClear = false;
    private clearPoints() {
        this._setClear = true;
    }

    private _lastDraw = 0;

    private startedEditing() {
        const mask = this.maskTexture;
        if (mask) {
            mask.isEditing = true;
        }

        this._commitTimer = window.setInterval(()=>{
            const maskTexture = this.maskTexture;
            const drawingCanvas = this.maskTexture?.canvas;
            const background = (this.context.gl.scene.background as THREE.Texture).image;
            //const background = this.context.scene?.lightingTexture?.canvas;

            if (!maskTexture || !drawingCanvas || !background) return;

            const min_age = (this._lastDraw ? this._lastDraw : performance.now()) - DRAWING_POINT_TIMEOUT;
            const pointsFiltered = this._drawingPoints.filter(p=>p.time > min_age || !p.drawn);

            if ((this._setClear || pointsFiltered.length) && !this._busy) {
                this._busy = true;
                this._lastDraw = performance.now();

                if (this._setClear) {
                    this._setClear = false;
                    this._drawingPoints = [];
                }

                //set to drawn
                pointsFiltered.forEach(p=>p.drawn = true);

                const points = [...pointsFiltered];
                //const start = performance.now();
                if (points.length > 0) {
                    this.commitDrawingChanges(this._drawingPointsAreErase, points, drawingCanvas, background).then(()=>{
                        this._busy = false;
                        maskTexture.showChanges(drawingCanvas);
                    }).catch(error=>{
                        //showDebugText(error.hasOwnProperty("message") ? error.message : error);
                        ErrorLog(error);
                    })
                }
            }

        }, DRAWING_POINT_REFRESH);
    }

    private stoppedEditing() {
        //showDebugText("stopped editing");

        if (this._commitTimer) clearInterval(this._commitTimer);

        const mask = this.maskTexture;
        if (mask) {
            mask.isEditing = false;
        }

        this.clearHistory();
        this.regenerateMeshes(false);
        this.context.refresh();
        this.needsUpdate();
    }

    private _commitTimer = 0;
    private _busy=false;

    private calculateBounds = (normalizedPoints:DrawingPoint[], padding:number)=>{
        const boundsNormalized = new Bounds(1,1,0,0);

        normalizedPoints.forEach((dp)=>{
            const p = dp.point;
            boundsNormalized.x1 = Math.min(boundsNormalized.x1, p.x);
            boundsNormalized.x2 = Math.max(boundsNormalized.x2, p.x);

            boundsNormalized.y1 = Math.min(boundsNormalized.y1, p.y);
            boundsNormalized.y2 = Math.max(boundsNormalized.y2, p.y);
        });

        boundsNormalized.x1 = Math.max(0, boundsNormalized.x1-padding);
        boundsNormalized.x2 = Math.min(boundsNormalized.x2+padding, 1);
        boundsNormalized.y1 = Math.max(0, boundsNormalized.y1-padding);
        boundsNormalized.y2 = Math.min(boundsNormalized.y2+padding, 1);

        return boundsNormalized;
    }

    private async commitDrawingChanges(isErase:boolean, normalizedPoints:DrawingPoint[], maskCanvas:HTMLCanvasElement, backgroundCanvas:HTMLCanvasElement) {

        const uncertainExpand = isErase ? 1.5 : 2.0;
        const padding = normalizedPoints[normalizedPoints.length - 1].radius * uncertainExpand * 1.1;
        const boundsNormalized = this.calculateBounds(normalizedPoints, padding);

        if (!boundsNormalized.area) return;

        const imageDiagonal = Math.hypot(maskCanvas.height, maskCanvas.width);

        //prepare images
        const mask = getCanvasMat(maskCanvas, boundsNormalized);
        if (!mask) return;
        cv.cvtColor(mask, mask, cv.COLOR_RGBA2GRAY);

        const background = getCanvasMat(backgroundCanvas, boundsNormalized);
        if (!background) return;
        cv.cvtColor(background, background, cv.COLOR_RGBA2RGB);

        //Smooth the bg, preserving edges:
        // const scratch = new cv.Mat(background.rows, background.cols, background.depth());
        // cv.bilateralFilter(background, scratch, 13, 80, 80, cv.BORDER_REFLECT);
        // scratch.copyTo(background);
        // scratch.delete();
        //imShow(background);

        //const offscreen = new OffscreenCanvas(background.cols, background.rows);

        if (background.cols != mask.cols || background.rows != mask.rows) {
            cv.resize(background, background, {width:mask.cols, height:mask.rows});
        }

        const xFactor = mask.cols / boundsNormalized.width;
        const yFactor = mask.rows / boundsNormalized.height;
        const factor = Math.min(xFactor, yFactor);

        const denormalizedPoints:DrawingPoint[] = normalizedPoints.map((dp)=>{
            const p = dp.point;
            return {
                point:{x:(p.x - boundsNormalized.x1) * xFactor, y:(p.y - boundsNormalized.y1) * yFactor},
                radius:dp.radius * factor,
                time:dp.time,
                drawn:dp.drawn
            }
        });

        //const boundsDiagonal = 2.0 * factor * Math.max(boundsNormalized.width - 2 * padding, boundsNormalized.height - 2 * padding);

        const first = denormalizedPoints[0];
        const uncertainPoints = denormalizedPoints.map((p)=>{
            return {...p, distance:euclideanDist(p.point, first.point)}
        })
            //.filter(p=>p.distance > Math.min(p.radius, boundsDiagonal - p.radius))
            .map(p=>{
            //const factor = 1 + p.distance / Math.pow(p.radius,  0.8);
            //const _uncertainExpand = 1 + (uncertainExpand -1) / factor;
            return {...p, radius:p.radius * uncertainExpand};
        });

        //build markers
        let markers = new cv.Mat();
        let maskLabel:Scalar
        let nonMaskLabel:Scalar

        // export enum Labels {
        //     barrier = -1,
        //     uncertain = 0,
        //     mask = 1,
        //     nonMask = 255,
        // }

        if (this.isLegacyMask) {
            markers = cv.Mat.zeros(mask.rows, mask.cols, cv.CV_32S);
            maskLabel = CBARImaging.maskLabel;
            nonMaskLabel = CBARImaging.nonMaskLabel;

            markers.setTo(CBARImaging.nonMaskLabel);
            if (isErase) {
                markers.setTo(CBARImaging.maskLabel, mask);
                uncertainPoints.forEach(dp=>cv.circle(markers, dp.point, dp.radius, CBARImaging.uncertainLabel, cv.FILLED));
            } else {
                uncertainPoints.forEach(dp=>cv.circle(markers, dp.point, dp.radius, CBARImaging.uncertainLabel, cv.FILLED));
                markers.setTo(CBARImaging.maskLabel, mask);
            }

        } else {
            mask.convertTo(markers, cv.CV_32S, 1, 0);
            maskLabel = cv.Scalar.all(this.maskIndex)
            nonMaskLabel = CBARImaging.nonMaskLabel; //todo: invent first index not in all indices

            uncertainPoints.forEach(dp=>cv.circle(markers, dp.point, dp.radius, CBARImaging.uncertainLabel, cv.FILLED));
        }

        const radiusFactor = isErase ? 0.95 : 0.9;
        if (isErase) {
            denormalizedPoints.forEach(dp=>cv.circle(markers, dp.point, Math.min(dp.radius * radiusFactor, dp.radius - 2), nonMaskLabel, cv.FILLED));
        } else {
            denormalizedPoints.forEach(dp=>cv.circle(markers, dp.point, Math.min(dp.radius * radiusFactor, dp.radius - 2), maskLabel, cv.FILLED));
        }

        //imShow(markers);

        cv.watershed(background, markers);

        markers.convertTo(markers, cv.CV_8U);

        if (this.isLegacyMask) {
            //convert to 255 mask, anything less than mask including barriers (-1)
            cv.threshold(markers, markers, Labels.mask - 1, 0, cv.THRESH_TOZERO);
        } else {
            cv.threshold(markers, markers, 0, 0, cv.THRESH_TOZERO); //get rid of barriers (-1)
        }

        //imShow(markers);

        //console.log("markers", this.maskIndex)
        //imShowOverlay(background, markers);

        //if (1 + 1 == 2) return


        // //snap to long edges
        // if (REFINE_EPSILON) {
        //     this.refineMask(mask, imageDiagonal);
        // }

        const pad = (REFINE_EPSILON ? REFINE_EPSILON * Math.sqrt(2) : 1);

        //imShow(mask);
        const maskContext = maskCanvas.getContext("2d", {willReadFrequently:true});
        if (maskContext) {
            const dx = maskCanvas.width * boundsNormalized.x1 + pad;
            const dy = maskCanvas.height * boundsNormalized.y1 + pad;

            cv.cvtColor(markers.roi({x:pad,y:pad,width:markers.cols-2*pad, height:markers.rows-2*pad}), markers, cv.COLOR_GRAY2RGBA);
            const array = new Uint8ClampedArray(markers.data, markers.cols, markers.rows);

            const imageData = maskContext.createImageData(markers.cols, markers.rows);
            imageData.data.set(array);

            maskContext.putImageData(imageData, dx, dy);
            maskContext.save();
        }

        background.delete();
        markers.delete();
        mask.delete();
    }

    private refineMask(mask:Mat, imageDiagonal=Math.hypot(mask.rows, mask.cols), debug?:Mat, color=[255,255,0,255]) {
        const contours = new cv.MatVector();
        const hierarchy = new cv.Mat();

        cv.findContours(mask, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_NONE);

        const contoursLength = (contours.size() as any) as number;
        const minLengthSq = (imageDiagonal / 30) ** 2;

        const range = REFINE_EPSILON * 2;
        const paddedRoi = new cv.Rect(REFINE_EPSILON, range, mask.cols-2*range, mask.rows-2*range);

        for (let i = 0; i < contoursLength; i++) {
            let poly = new cv.Mat();
            const contour = contours.get(i);

            cv.approxPolyDP(contour, poly, REFINE_EPSILON, false);

            let refinedLastPoint = false;
            let lastLine:Line|undefined;
            const angleMatchThreshold =  Math.PI / 8.0;

            for (let j = 0; j < poly.data32S.length; j+=2) {
                const point = new cv.Point(poly.data32S[j], poly.data32S[j+1]);
                const nextIndex = (j+2) % poly.data32S.length;
                const nextPoint = new cv.Point(poly.data32S[nextIndex], poly.data32S[nextIndex+1]);
                const lengthSq = euclideanDistSq(point, nextPoint);
                const line = new Line(point.x, point.y, nextPoint.x, nextPoint.y);

                if (!isOnEdge(line, paddedRoi) && (lengthSq >= minLengthSq || (refinedLastPoint && lastLine && angleBetweenLines(line, lastLine) <= angleMatchThreshold))) { //then erase area

                    refinedLastPoint = true;
                    lastLine = line;

                    //find perpendicular line
                    const insideOutside = lineBisector(line, Math.sqrt(2));
                    const isInside1 = mask.data[insideOutside.y1 * insideOutside.x1] === 0;

                    const bisector = lineBisector(line, range);
                    const insidePoint = isInside1 ? {x:bisector.x1, y:bisector.y1} : {x:bisector.x2, y:bisector.y2};
                    const outsidePoint = isInside1 ? {x:bisector.x2, y:bisector.y2} : {x:bisector.x1, y:bisector.y1};

                    const maskPoints:Point[] = [];
                    maskPoints.push({x:line.x1, y:line.y1});
                    maskPoints.push({x:line.x2, y:line.y2});

                    //erase outside
                    const outsideCover = new cv.MatVector();
                    outsideCover.push_back(pointsToContour([...maskPoints, outsidePoint, maskPoints[0]]));
                    cv.drawContours(mask, outsideCover, 0, [0,0,0,0], cv.FILLED);

                    //draw inside
                    const insideCover = new cv.MatVector();
                    insideCover.push_back(pointsToContour([...maskPoints, insidePoint, maskPoints[0]]));
                    cv.drawContours(mask, insideCover, 0, [255,255,255,255], cv.FILLED);

                    if (debug) {
                        cv.drawContours(debug, outsideCover, 0, [255,0,0,127], cv.FILLED);
                        cv.drawContours(debug, insideCover, 0, [255,255,0,127], cv.FILLED);
                        //cv.line(debug, {x:line.x1 + offset.x, y:line.y1 + offset.y}, {x:line.x2 + offset.x, y:line.y2 + offset.y}, [0,0,255,255], 1);
                        //cv.circle(debug, midpoint, 5, [255,255,255,255])
                        cv.circle(debug,{x:bisector.x1, y:bisector.y1}, isInside1 ? 3 : 1, [255,255,0,255])
                        cv.circle(debug,{x:bisector.x2, y:bisector.y2}, isInside1 ? 1 : 3, [0,255,255,255])
                    }

                    outsideCover.delete();
                    //insideCover.delete();
                }
                else {
                    refinedLastPoint = false;
                }
            }

            if (debug) {
                const mat = new cv.MatVector(); mat.push_back(poly);
                cv.drawContours(debug, mat, 0, color);
                mat.delete();
            }
            poly.delete();
        }

        contours.delete();
        hierarchy.delete();
    }

    protected undoHistory:CBARHistoryState[] = [];

    protected saveState() {
        const mask = this.maskTexture;
        if (mask?.canvas) {
            this.undoHistory.push(new CBARHistoryState(mask.canvas));
        }
    }

    protected restoreState(state:CBARHistoryState, completed?:(success:boolean)=>void) {
        const mask = this.maskTexture;
        if (mask?.canvas) {
            state.restoreInto(mask.canvas, ()=>{
                this.regenerateMeshes(false);
                mask.refresh();
                this.context.refresh();
                if (completed) completed(true);
            });
        } else if (completed) {
            completed(false);
        }
    }

    public get historyLength() {
        return this.undoHistory.length;
    }

    public undoLast(completed?:(success:boolean)=>void) {
        const state = this.undoHistory.pop();
        if (state) {
            this.restoreState(state, completed);
        } else {
            console.warn("Nothing to undo");
            if (completed) completed(false);
        }
    }

    public clearHistory() {
        this.undoHistory = [];
    }

    public revertChanges(completed?:(success:boolean)=>void) {
        if (this.undoHistory.length) {
            this.restoreState(this.undoHistory[0], (success:boolean)=>{
                this.clearHistory();
                if (completed) completed(success);
            });
        } else {
            //console.warn("Nothing to revert");
            if (completed) completed(false);
        }
    }

    public commitChanges(completed?:(success:boolean)=>void) {
        if (this.historyLength) {
            this.clearHistory();
            if (completed) completed(true);
        } else {
            console.warn("Nothing to commit");
            if (completed) completed(false);
        }
    }

    public get selected() {
        return this.context.scene?.surfaceFor(CBARHighlightState.Selected) === this;
    }

    public set selected(value:boolean) {
        if (this.context.scene) {
            if (value) {
                this.context.scene.setSurfaceFor(CBARHighlightState.Selected, this);
            } else {
                const selectedSurface = this.context.scene.surfaceFor(CBARHighlightState.Selected);
                if (selectedSurface === this) {
                    this.context.scene.setSurfaceFor(CBARHighlightState.Selected, undefined);
                }
            }
        } else {
            console.warn("selected: No scene");
        }
    }

    public get isDrawingOn() {
        return isDrawingTool(this.context.toolMode) && this.selected
    }

    public handleEvent(event: CBAREvent) {
        super.handleEvent(event);

        const me = event as CBARMouseEvent;

        //update drawing size if necessary:
        const intersection = isDrawingTool(this.context.toolMode) && me.point ? this.getPlaneIntersection(me.point) : undefined;

        if (intersection) {
            const distance = Math.min(DEFAULT_DISTANCE_METERS * 4, intersection.distanceTo(this.context.gl.camera.position));

            const selectedSurface = this.context.scene?.surfaceFor(CBARHighlightState.Selected);
            if (!selectedSurface || selectedSurface === this) {
                //const aspectRatio = this.context.gl.resolution.x / this.context.gl.resolution.y;
                DRAW_RADIUS = DRAW_RADIUS_METERS / Math.min(distance, DRAW_MAX_DISTANCE_METERS);
            }
        }

        switch (event.type) {

            case CBAREventType.TouchDown:
                if (!this.selected || this.context.toolMode === CBARToolMode.None
                    || (isDrawingTool(this.context.toolMode) && !this.context.scene?.surfaceFor(CBARHighlightState.Selected))) {
                    this.selected = true;
                }

                if (this.isDrawingOn) {
                    this.saveState();
                    this.drawEraseAtPoint(me.point);
                }
                break;

            case CBAREventType.TouchUp:
                if (this.isDrawingOn) {
                    this.drawEraseAtPoint(me.point);
                }
                this.clearPoints();

                break;

            case CBAREventType.DragMove:
                if (this.isDrawingOn) {
                    this.drawEraseAtPoint(me.point);
                }
                break;

            case CBAREventType.TouchMove:
                this.highlighted = true
                break;

            case CBAREventType.MouseOver:
                if (!this.highlighted) {
                    this.highlighted = true
                }
                break;
            case CBAREventType.MouseOut:
                if (me.surface) {
                    //console.log("Mouse out", me.surface.description)
                    me.surface.highlighted = false
                }
                break;
        }
    }

    public clearAll() {
        const assets = this.values;

        for (let key in assets) {
            this.remove(assets[key])
        }
    }

    public existsAtPoint(coords:THREE.Vector2) : boolean {
        const mask = this.maskTexture;
        if (!mask?.canvas) {
            return false;
        }

        // if (this.isDrawingOn || (isDrawingTool(this.context.toolMode) && !this.context.scene?.surfaceFor(CBARHighlightState.Selected))) {
        //     return true;
        // }

        const ctx = mask.canvas.getContext("2d", {willReadFrequently:true});

        if (!ctx) return false;

        const xPos = coords.x * mask.canvas.width;
        const yPos = coords.y * mask.canvas.height;

        const pixel = ctx.getImageData(xPos, yPos, 1, 1);

        if (pixel.data.length === 0) return false

        return this._isLegacyMask ? pixel.data[0] > 0 : pixel.data[0] === this.maskIndex;
    }

    protected getPlaneIntersection(point2D: THREE.Vector2) {
        const screenPoint = normalizedToScreen(point2D);
        this._raycaster.setFromCamera(screenPoint, this.context.gl.camera);
        return this._raycaster.ray.intersectPlane(this.plane, new THREE.Vector3());
    }

    public screenToSurfacePosition(point2D:THREE.Vector2) : THREE.Vector2 {
        const point3D = this.getPlaneIntersection(point2D);
        return point3D ? this.getSurfacePosition(point3D) : new THREE.Vector2();
    }

    private _pointTransform = new THREE.Matrix4().identity();

    public getSurfacePosition(point3d:THREE.Vector3) : THREE.Vector2 {
        const point4D = new THREE.Vector4(point3d.x, point3d.y, point3d.z, 1.0).applyMatrix4(this._pointTransform);
        return new THREE.Vector2(point4D.x, point4D.y);
    }

    private removeMeshes() {

        this._outlines.forEach(m=>this._container.remove(m));
        this._outlines = [];

        this._shapeMeshes.forEach(m=>this._container.remove(m));
        this._shapeMeshes = [];

        this._placeholderMeshes.forEach(m=>this._container.remove(m));
        this._placeholderMeshes = [];
    }

    private _menuPoint = new THREE.Vector2(0,0)
    public get menuPoint() {
        return this.context.pointToScreenPosition(this._menuPoint)
    }

    private _mask?:Mat;

    public get mask() {

        if (!this._mask) {

            if (this.context.scene?.cvIndexMask) {
                //if not equal to value, set to 0
                this._mask = new cv.Mat();
                cv.threshold(this.context.scene.cvIndexMask, this._mask, this.maskIndex, 0, cv.THRESH_TOZERO_INV); //if greater than threshold, set to zero
                cv.threshold(this._mask, this._mask, this.maskIndex - 1, 0, cv.THRESH_TOZERO); //if less than thresh, set to zero
            } else if (this.context?.scene?.cvBackgroundSmall && this.maskTexture?.canvas) {
                this._mask = cv.imread(this.maskTexture.canvas);
                if (this._mask.channels() == 4) {
                    cv.cvtColor(this._mask, this._mask, cv.COLOR_RGBA2GRAY, 0)
                } else if (this._mask.channels()==3) {
                    cv.cvtColor(this._mask, this._mask, cv.COLOR_RGB2GRAY, 0)
                }
                //mobile-only issue with artifacts for some unknown reason, causing contour counts to go into the thousands
                cv.threshold(this._mask, this._mask, 0, 255, cv.THRESH_TOZERO | cv.THRESH_OTSU);
            }

            this._mask?.setTo(cv.Scalar.all(255), this._mask)
        }

        return this._mask;
    }

    private _smallMask?:Mat;
    public get smallMask() {

        if (!this._smallMask && this.context.scene?.smallScale && this.context.scene.smallScale < 1.0 && this._mask && this.context.scene.cvBackgroundSmall) {
            this._smallMask = new cv.Mat();
            cv.resize(this._mask, this._smallMask, this.context.scene.cvBackgroundSmall.size(), 0, 0);
        }

        return this._smallMask;
    }

    private calculateLighting(background:InputArray, lighting:InputArray, mask:InputArray) {
        console.log("Recalculating lighting");
        const maskSmall = new cv.Mat()
        cv.resize(mask, maskSmall, background.size(), 0,0, cv.INTER_NEAREST);

        let insideM = new cv.Mat();
        let insideS = new cv.Mat();
        cv.meanStdDev(background, insideM, insideS, maskSmall);

        this.backgroundMeanIntensity = getIntensity(insideM.data64F);
        this.backgroundStdDevIntensity = getIntensity(insideS.data64F);

        cv.meanStdDev(lighting, insideM, insideS, maskSmall);

        this.lightingMeanIntensity = getIntensity(insideM.data64F);
        this.lightingStdDevIntensity = getIntensity(insideS.data64F);

        insideM.delete();
        insideS.delete();
        maskSmall.delete();
    }

    private findContours(calculateTransform:boolean) {

        if (!this.mask) {
            return;
        } //nothing to do yet.

        const contours = new cv.MatVector();
        const hierarchy = new cv.Mat();
        const pad = 1;
        const paddedMask = cv.Mat.zeros(this.mask.rows + 2 * pad, this.mask.cols + 2 * pad, cv.CV_8U);
        this.mask.copyTo(paddedMask.roi({x:pad,y:pad,width:this.mask.cols, height:this.mask.rows}))
        cv.findContours(paddedMask, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);
        const contoursLength = (contours.size() as any) as number; //hack: typedefs are wrong inside mirada. regenerate using opencv please (it is incorporated now)
        hierarchy.delete();

        const threeContours: THREE.Vector2[][] = [];

        const menuPadding = 0.05
        this._menuPoint.x = menuPadding
        this._menuPoint.y = 1 - menuPadding


        for (let i = 0; i < Math.min(contoursLength, MAX_CONTOURS); i++) {
            const contour = contours.get(i);

            let poly = new cv.Mat();
            if (APPROX_POLY) {
                cv.approxPolyDP(contour, poly, POLY_EPSILON, false)
            } else {
                poly = contour
            }

            const area = cv.contourArea(poly);
            if (area < MIN_CONTOUR_AREA) continue;

            const threeContour:THREE.Vector2[] = [];
            for (let j = 0; j < poly.data32S.length; j+=2) {
                const point = new THREE.Vector2((poly.data32S[j]-pad)/this.mask?.cols, (poly.data32S[j+1]-pad)/this.mask?.rows);
                threeContour.push(point);

                if (point.y < this._menuPoint.y && point.x > this._menuPoint.x) {
                    this._menuPoint = point
                }
            }
            threeContours.push(threeContour);
            poly.delete();
        }
        
        contours.delete();

        this.maskTexture?.refresh();

        this.removeMeshes();

        this._menuPoint.x = Math.min(Math.max(this._menuPoint.x, menuPadding), 1-menuPadding);
        this._menuPoint.y = Math.min(Math.max(this._menuPoint.y, menuPadding), 1-menuPadding);

        const mx = new THREE.Matrix4().lookAt(this.plane.normal, new THREE.Vector3(), new THREE.Vector3(0,1,0));
        const _rotation = new THREE.Quaternion().setFromRotationMatrix(mx);

        const euler = new THREE.Euler().setFromQuaternion(_rotation);
        const rotation = new THREE.Quaternion().setFromEuler(new THREE.Euler(euler.x, euler.y, this.axisRotation));

        this.castContours(threeContours, rotation, calculateTransform);

        this._container.rotation.setFromQuaternion(rotation);
    }

    public lightingMeanIntensity = 0;
    public lightingStdDevIntensity = 0;

    public backgroundMeanIntensity = 0;
    public backgroundStdDevIntensity = 0;

    private projectPointsOntoPlane(contours: THREE.Vector2[][], contours3D:THREE.Vector3[][]) {
        const totalPoint = new THREE.Vector3();
        let numPoints = 0;

        for (let i = 0; i < contours.length; i++) {
            const contour = contours[i];
            const points3D: THREE.Vector3[] = [];
            for (let j = 0; j < contour.length; j++) {

                const point3D = this.getPlaneIntersection(contour[j]);

                if (point3D && point3D.length() <= MAX_PROJECTED_DISTANCE) {
                    points3D.push(point3D);
                    totalPoint.x += point3D.x;
                    totalPoint.y += point3D.y;
                    totalPoint.z += point3D.z;
                    numPoints += 1
                }
            }

            if (points3D.length > 2) {
                points3D.push(points3D[0]);//join start and end
                contours3D.push(points3D);
            }
        }

        return new THREE.Vector3(
            totalPoint.x / numPoints,
            totalPoint.y / numPoints,
            totalPoint.z / numPoints);
    }

    private castContours(contours: THREE.Vector2[][], rotation:THREE.Quaternion, calculateTransform:boolean) {

        const contours3D:THREE.Vector3[][] = [];
        const center3D = this.projectPointsOntoPlane(contours, contours3D);

        if (calculateTransform) {
            this._pointTransform = new THREE.Matrix4().compose(center3D, rotation, new THREE.Vector3(1,1,1)).invert()
        }

        const min = new THREE.Vector2(Number.MAX_SAFE_INTEGER,Number.MAX_SAFE_INTEGER);
        const max = new THREE.Vector2(Number.MIN_SAFE_INTEGER,Number.MIN_SAFE_INTEGER);
        const contoursPlane = [];

        let index = 0;

        for (const contour of contours3D) {
            const translatedContour = [];

            for (const point3D of contour) {
                const point2D = this.getSurfacePosition(point3D);
                translatedContour.push(point2D);
                min.x = Math.min(min.x, point2D.x);
                min.y = Math.min(min.y, point2D.y);
                max.x = Math.max(max.x, point2D.x);
                max.y = Math.max(max.y, point2D.y);
            }
            translatedContour.push(translatedContour[0]); //close loop

            const shape = new THREE.Shape();
            shape.setFromPoints(translatedContour);
            contoursPlane.push(shape);

            //add outside lines
            const lineMaterial = new LineMaterial({
                vertexColors: false,
                transparent:true,
                opacity: 0.0,
                color:new THREE.Color("blue").getHex(),
                linewidth: 8.0,
                blending:THREE.AdditiveBlending,
                resolution: new THREE.Vector2(this.context.gl.renderer.domElement.width * window.devicePixelRatio, this.context.gl.renderer.domElement.height * window.devicePixelRatio),
                dashed: false,
                depthTest:false
            });

            const positions = [];
            const colors = [];
            for (const point of translatedContour) {
                positions.push(point.x, point.y, 0);
                colors.push( this.color.r, this.color.g, this.color.b )
            }

            const lineGeometry = new LineGeometry();
            lineGeometry.setPositions(positions);
            lineGeometry.setColors(colors);

            const line = new Line2(lineGeometry, lineMaterial );
            line.computeLineDistances();
            line.scale.set( 1, 1, 1 );
            line.visible = true;
            line.renderOrder = 10000;

            this._outlines.push(line);
            this._container.add(line);

            index += 1;
        }

        //add each shape
        for (const shape of contoursPlane) {
            //add shape
            const geometry = new THREE.ShapeBufferGeometry(shape);
            const mesh = new THREE.Mesh(geometry, this.material);
            mesh.renderOrder = 10000;
            this._container.add(mesh);
            this._shapeMeshes.push(mesh);

            //const shadowMaterial = new THREE.ShadowMaterial({opacity:0.01});
            if (this.usesPlaceholder) {
                const placeholderShape = new THREE.Shape();
                const halfWidth = 50; //infinite-ish
                placeholderShape.moveTo( -halfWidth, -halfWidth);
                placeholderShape.lineTo( halfWidth, -halfWidth);
                placeholderShape.lineTo( halfWidth, halfWidth);
                placeholderShape.lineTo( -halfWidth, halfWidth);
                placeholderShape.lineTo( -halfWidth, -halfWidth);

                const placeholderGeometry = new THREE.ShapeBufferGeometry(placeholderShape);
                const placeholderMesh = new THREE.Mesh(placeholderGeometry, this._placeholderMaterial.threeMaterial);

                // if (this.context.scene) {
                //     placeholderMesh.rotateZ(this.context.scene.groundRotation);
                //     //console.log("rotation", this.context.scene.groundRotation)
                // }

                placeholderMesh.receiveShadow = this.length() > 0;
                placeholderMesh.castShadow = false;
                this._container.add(placeholderMesh);
                this._placeholderMeshes.push(placeholderMesh);
            }
        }

        //calc extents
        const xSpan = 2 * Math.max(max.x - min.x, 2);
        const ySpan = 2 * Math.max(max.y - min.y, 2);

        this._extent = new THREE.Vector2(xSpan, ySpan);
        if (calculateTransform) {
            this._container.position.set(center3D.x, center3D.y, center3D.z);
        }
    }

    private get hasCalculatedLighting() {
        return this.backgroundMeanIntensity && this.backgroundStdDevIntensity && this.lightingMeanIntensity && this.lightingStdDevIntensity;
    }

    private regenerateMeshes(calculateTransform:boolean) {

        const mask = this.maskTexture

        if (this.context.scene && mask?.threeTexture) {
            this._placeholderMaterial.threeMaterial.map = this.context.scene.placeholderTexture.threeTexture;
            this._placeholderMaterial.threeMaterial.alphaMap = mask.threeTexture;
            this._placeholderMaterial.threeMaterial.lightMap = this.context.scene.lightingTexture.threeTexture;
            this._placeholderMaterial.repeat = new THREE.Vector2(1,1);
            this.findContours(calculateTransform);
        }
        // else {
        //     console.log(`regenerateMeshes ignored for ${this.description}`, !!this.maskTexture?.canvas, !!this.context.scene);
        // }

        const background = this.context.scene?.cvBackgroundSmall;
        const lighting = this.context.scene?.cvLightingSmall;

        if (!this.hasCalculatedLighting && background && lighting && this.mask) {
            this.calculateLighting(background, lighting, this.mask)
        }

        this.sortAssets();
    }

    private _highlighted = false;
    private _finalOpacityValue = 0;

    public set highlighted(value:boolean) {
        if (this._highlighted == value) return;

        this._highlighted = value;
        this._finalOpacityValue = value ? 1.0 : 0;

        this._outlines.forEach(o=>{
            o.visible = true;
            o.material.opacity = value ? 0 : 1;
            o.material.needsUpdate = true;
        })

        const duration = 1000 * NUM_ANIMATION_FRAMES / 30;
        this.context.scene?.requestAnimation(duration).then(()=>{
            this._outlines.forEach(o=>{
                o.visible = value;
                o.material.opacity = this._finalOpacityValue;
                o.material.needsUpdate = true;
            })
        })
    }

    public get highlighted() {
        return this._highlighted;
    }

    removeFromScene() : void {
        if (this._commitTimer) {
            window.clearInterval(this._commitTimer);
        }
        this.removeMeshes();
        super.removeFromScene();
    }

    sceneLoaded() {
        this.regenerateMeshes(true);
        this.needsUpdate();
    }

    public get receivesEvents() : boolean {
        return true
    }

    public get hasOutlines() {
        return this.all().length === 0;
    }

    public render(options:RenderOptions) {
        super.render(options)
        
        this._outlines.forEach(o=>{
            o.material.opacity = (o.material.opacity * (NUM_ANIMATION_FRAMES - 1) + this._finalOpacityValue) / NUM_ANIMATION_FRAMES
            o.material.needsUpdate = true;
            o.visible = this.hasOutlines && !options.isScreenshot;
        })
    }
}

