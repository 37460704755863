import * as THREE from "three";
import {CBARContext} from "./CBARContext";
import {CBARScene} from "./components/CBARScene";
import {CBARObject3D} from "./components/CBARObject3D";
import {CBARSurfaceAsset} from "./assets";
import {CBARSurface} from "./components";

export enum CBARMode {
    None,
    Video,
    Image,
}

export class CBARRenderContext {

    constructor(
        public camera:THREE.PerspectiveCamera,
        public scene:THREE.Scene,
        public renderer:THREE.WebGLRenderer,
        public renderTarget:THREE.WebGLRenderTarget,
        public resolution:Point2D
    ) {

    }
}

export enum CBAREventType {

    ContextMenu,
    Wheel,

    TouchDown,
    TouchMove,
    TouchUp,
    TouchLeave,

    Rotate,
    Translate,

    //computed
    DragStart,
    DragMove,
    DragEnd,

    MouseOver,
    MouseOut,
}

export enum CBARHighlightState {
    None = 'none',
    Hover = 'hover',
    Selected = 'selected',
    Drag = 'drag',
}

export type CBARIntersection = {
    intersection:THREE.Intersection
    object:CBARObject3D<any>
}

export enum CBARToolMode {
    None='',
    Rotate='rotate',
    Translate='translate',
    DrawSurface='draw',
    EraseSurface='erase'
}

export type Point2D = {
    x: number;
    y: number;
};

export type Rectangle = {x:number, y:number, width:number, height:number}

export class CBAREvent {
    constructor(public type:CBAREventType,
                public context:CBARContext,
                public scene:CBARScene,
                public toolMode:CBARToolMode) {
    }
}

export class CBARMouseEvent extends CBAREvent {
    constructor(type:CBAREventType,
                context:CBARContext,
                scene:CBARScene,
                toolMode:CBARToolMode,
                public mouseEvent:any,
                public point:THREE.Vector2,
                public intersections:CBARIntersection[]) {
        super(type, context, scene, toolMode)
    }

    public get screenPoint() : Point2D {
        return {x:this.mouseEvent.clientX, y:this.mouseEvent.clientY}
    }

    protected get assetIntersections() {
        return this.intersections.filter(x => x.object instanceof CBARSurfaceAsset);
    }

    private _surface:CBARSurface|null|undefined

    public get surface() : CBARSurface | undefined {
        if (this._surface === undefined) {
            const result = this.intersections.find(x => x.object instanceof CBARSurface);
            if (this.asset?.surface) {
                this._surface = this.asset.surface
            }
            else if (result?.object instanceof CBARSurface) {
                this._surface = result.object
            }
            else {
                this._surface = null
            }
        }
        return this._surface ? this._surface : undefined
    }

    private _asset:CBARSurfaceAsset|null|undefined

    public get asset() : CBARSurfaceAsset | undefined {
        if (this._asset === undefined) {
            this._asset  = this.assetIntersections.length > 0 ? this.assetIntersections.sort((a:CBARIntersection,b:CBARIntersection)=>{
                const assetA = a.object as CBARSurfaceAsset;
                const assetB = b.object as CBARSurfaceAsset;
                if (assetA.type === assetB.type) return 0;
                return assetA.type === CBARAssetType.Rug ? -1 : 1;
            })[0].object as CBARSurfaceAsset : null
        }
        return this._asset ? this._asset : undefined
    }

    public _overwrite(surface:CBARSurface|undefined|null, asset:CBARSurfaceAsset|undefined|null) {
        this._surface = surface ? surface : null
        this._asset = asset ? asset : null
    }
}

export type CBAREventHandler = (event:CBAREvent)=>void
export type CBARMouseEventHandler = (event:CBARMouseEvent)=>void

export type CBARVector = number[]

export enum CBARSurfaceType {
    None = 'None',
    Floor = 'Floor',
    OnFloor = 'OnFloor',
    Ceiling = 'Ceiling',
    OnCeiling = 'OnCeiling',
    Wall = 'Wall',
    OnWall = 'OnWall',
    Other = 'Other',
}

export const getSurfaceType = (surfaceJSON:any) => {
    if (!surfaceJSON) {
        //console.log("Null surface type", surfaceJSON)
        return CBARSurfaceType.None
    }
    const surfaceType = `${surfaceJSON}`.toLowerCase()
    switch (surfaceType) {
        case "floor": return CBARSurfaceType.Floor
        case "onfloor": return CBARSurfaceType.OnFloor
        case "wall": return CBARSurfaceType.Wall
        case "onwall": return CBARSurfaceType.OnWall
        case "ceiling": return CBARSurfaceType.Ceiling
        case "onceiling": return CBARSurfaceType.OnCeiling
        case "other": return CBARSurfaceType.Other
    }
    console.log("cannot find", surfaceType)
    return CBARSurfaceType.None
}

export enum CBARAssetType {
    Model = 'model',
    TiledSurface = 'tiled-surface',
    SurfaceModel = 'surface-model',

    PaintSurface = 'paint-surface',
    TiledRectangle = 'tiled-rectangle',

    Rug = 'tiled-rug',
}

export class CBMaterialProperties {

    constructor(public readonly ppi:number,
                public readonly diffuseUrl:string,
                public readonly normalsUrl?:string,
                public readonly specularUrl?:string) {

    }
}

export enum CBARServerFile {
    Background = "main",
    Lighting = "lighting",
    IndexMask = "index_mask",
}

export const CBARUploadNames = {
    Preview: "preview",
    Pinterest: "pinterest",
    Brand: "brand",
};

export type CBARSuperpixelData = {
    pixels: [number, number][]
    center: [number, number]
    meanRadius: number
}

export class CBARHistoryState {
    private readonly data:string;
    constructor(source:HTMLCanvasElement) {
        this.data = source.toDataURL("image/png")
        //console.log(`History is " ${Math.ceil(this.data.length / 1024)} kb`)
    }

    public restoreInto(source:HTMLCanvasElement, completed:()=>void) {
        const toCtx = source.getContext("2d");
        if (toCtx) {
            const img = new Image();
            img.addEventListener("load", function () {
                toCtx.drawImage(img, 0, 0);
                if (completed) completed();
            });
            img.setAttribute("src", this.data);
        }
    }
}