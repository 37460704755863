import {CBARFrame} from "../CBARFrame";
import {CBARPipeline} from "./CBARPipeline";

export abstract class CBARProcessNode {

    public enabled = true;

    protected constructor(public readonly pipeline:CBARPipeline) {

    }

    abstract update(frame:CBARFrame):void;

    abstract debug(canvas:HTMLCanvasElement):void;

    abstract destroy():void;
}