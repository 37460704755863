import {SceneConfig} from "cambrian-base";
import {DataItem} from "./DataItem";
import {SwatchItem} from "./SwatchItem";
import {SceneCollection} from "./SceneCollection";
import {ProductBrand} from "./ProductBrand";

export class SceneInfo extends DataItem implements SwatchItem {

    constructor(dataUrl?:string) {
        super(dataUrl);
        this.description = "scene";
    }

    public collection!: SceneCollection;

    public get brand(): ProductBrand {
        return this.collection.brand
    }

    public get parent():DataItem|undefined {
        return this.collection
    }

    public get hasColumns(): boolean {
        return true
    }

    public get path(): string {
        if (this.dataUrl && this.dataUrl?.indexOf("http") == 0) {
            return this.dataUrl; //direct path
        }
        return `${this.collection.path}/${this.dataUrl ? this.dataUrl : this.code}`
    }

    public load(json:SceneConfig) {
        super.load(json)
    }
}