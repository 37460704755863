import {CBARProcessNode} from "./CBARProcessNode";
import {CBARFrame} from "../CBARFrame";
import * as gm from "gammacv";
import {Line} from "gammacv";
import {angleBetweenLines, clipLine, mergeLines} from "../Line";
import {CBARPipeline} from "./CBARPipeline";
import {CBARFeatureTrackerNode} from "./CBARFeatureTrackerNode";
import {CBARTrackedObject, CBARTrackedPoint} from "./CBARTrackedObject";
import {euclideanDistSq} from "../Math";
import {toRadians} from "../Utils";
import {getConfig} from "../../../backend";
import {CBARDebug} from "../../CBARContext";

export type CBARLineFinderResult = {
    lines:gm.Line[]
    frameIndex:number
}

const matchThresholdDistanceSq = 7 * 7;
const matchAngleThreshold = toRadians(3);

class CBARTrackedLine extends CBARTrackedObject {

    constructor(line:Line, frameIndex:number, frameTime:number, maxAge:number) {
        const points:CBARTrackedPoint[] = [];
        points.push(new CBARTrackedPoint(new cv.Point(line.x1,line.y1)));
        points.push(new CBARTrackedPoint(new cv.Point(line.x2,line.y2)));
        super(points, frameIndex, frameTime, maxAge);
    }

    public get line():Line {
        return new Line(this.points[0].point.x, this.points[0].point.y, this.points[1].point.x, this.points[1].point.y);
    }

    public set line(value) {
        this.points[0].point = new cv.Point(value.x1, value.y1);
        this.points[1].point = new cv.Point(value.x2, value.y2);
    }

    isMatch(candidate: CBARTrackedLine): boolean {
        return angleBetweenLines(this.line, candidate.line) < matchAngleThreshold
            && ((euclideanDistSq(this.midpoint, candidate.midpoint) < matchThresholdDistanceSq) || !!Line.Intersection(this.line, candidate.line))
    }

    merge(obj:CBARTrackedLine): void {
        if (!this.confirmations || this.confirmations < 10) {
            super.merge(obj)
        }
    }
}

export class CBARLineFinderNode extends CBARProcessNode {

    constructor(context:CBARPipeline, private maxLines = 40, private maxAge = 7) {
        super(context)
    }

    private _tracker:CBARFeatureTrackerNode = new CBARFeatureTrackerNode(this.pipeline)

    frequency = 4;

    update(frame:CBARFrame):void {

        if (frame.index % this.frequency > 0 || !frame.pcLinesOutput) return;

        const cvOutput = frame.pcLinesOutput;

        this._debugLines = [];

        let houghLines = [];
        for (let i = 0; i < cvOutput.size / 4; i += 1) {
            const y = Math.floor(i / cvOutput.shape[1]);
            const x = i - (y * cvOutput.shape[1]);
            const value = cvOutput.get(y, x, 0);
            const x0 = cvOutput.get(y, x, 1);
            const y0 = cvOutput.get(y, x, 2);

            if (value > 0.0) {
                houghLines.push([value, x0, y0]);
            }
        }

        houghLines = houghLines.sort((b, a) => a[0] - b[0]);
        houghLines = houghLines.slice(0, this.maxLines);
        const maxP = Math.max(this.pipeline.input.shape[0], this.pipeline.input.shape[1]);

        for (let i = 0; i < houghLines.length; i++) {

            const hLine = houghLines[i];
            const line = new Line();

            line.fromParallelCoords(
                hLine[1] * frame.context.downsample, hLine[2] * frame.context.downsample,
                this.pipeline.input.shape[1], this.pipeline.input.shape[0], maxP, maxP / 2,
            );

            //break each infinite line apart into segments (clip line to screen and break it apart)
            if (clipLine({height:this.pipeline.input.shape[0], width:this.pipeline.input.shape[1]}, line)) {
                this._tracker.track(new CBARTrackedLine(line, frame.index, frame.time, this.maxAge));

                if (CBARDebug.HoughLines === (this.pipeline.config.debug & CBARDebug.HoughLines)) {
                    this._debugLines.push(line);
                }
            }
        }

        this._tracker.update(frame);

        // this.context.completeTask<CBARLineFinderResult>(CBARPipelineTask.HoughLines, ()=>{
        //     return {lines, frameIndex:frame.index};
        // })
    }

    private _debugLines:Line[] = [];

    debug(canvas:HTMLCanvasElement):void {
        this._debugLines.forEach(line=>{
            gm.canvasDrawLine(canvas, line, 'rgba(0, 255, 0, 1.0)');
        })
        this._tracker.debug(canvas);
    }

    destroy() {
        this._tracker.destroy();
    }
}