import * as THREE from "three";
import {CBARObject} from "./CBARObject";
import {CBARCameraIntrinsics, CBARCameraProperties} from "./CBARCameraIntrinsics";
import {CBARSceneGeometry, CBARSceneGeometryProperties} from "./CBARSceneGeometry";
import {CBARImageCollection, CBARImageDictionary} from "./CBARImageCollection";
import {CBARContext, isDrawingTool} from "../CBARContext";
import {
    CBAREvent,
    CBAREventType,
    CBARHighlightState,
    CBARServerFile,
    CBARSurfaceType,
    CBARToolMode
} from "../CBARTypes";
import {CBARAssetCollection} from "./CBARAssetCollection";
import {CBARTexture} from "./CBARTexture";
import {CBARTextureType} from "./CBARMaterial";

import {CBARAsset} from "../assets";
import {CBARSurface} from "./CBARSurface";
import {RGBELoader} from "three/examples/jsm/loaders/RGBELoader";
import {CBARImage} from "./CBARImage";
import {CBARError} from "../CBARError";
import {getConfig, usleep} from "../../backend";
import {InputArray} from "mirada/dist/src/types/opencv/_types";
import {PerformanceLogger} from "../internal/GlobalLogger";
import {getEnumValues} from "../CBARUtils";
import {MathUtils} from "three";
import generateUUID = MathUtils.generateUUID;
import {CBARMaskTexture} from "./CBARMaskTexture";

window.cv = require('../includes/opencv.js')

let RENDER_DEPTH = false;

//TODO: remove this global variable. state callbacks should have worked but did not
let currentScene:CBARScene|undefined = undefined;

export interface CBARSceneProperties {
    version?:string
    id:string,
    name?:string,
    camera?:CBARCameraProperties,
    geometry?:CBARSceneGeometryProperties,
    assets?:any,
    floorRotation?:number
    lightingFactor?:number
    lightingOffset?:number
    images?:CBARImageDictionary,
    environment?:string
}

export interface CBScenePropertiesLegacy {
    cameraPosition: [number, number, number],
    cameraRotation: [number, number, number],
    floorRotation: number,
    fov: number,
    images: any
}

export interface RenderOptions {
    isScreenshot?:boolean
}

const DOWN_SIZE = 200

export class CBARScene extends CBARObject<CBARScene> {

    public name = "surface";
    public cameraProperties:CBARCameraIntrinsics;
    public geometry:CBARSceneGeometry;

    public assets = new CBARAssetCollection(this.context);
    public images = new CBARImageCollection(this.context);
    public imageSize = new THREE.Vector2();

    public readonly indexMaskTexture:CBARMaskTexture;
    public readonly lightingTexture:CBARTexture;
    public readonly placeholderTexture:CBARTexture;

    public groundRotation = 0.0;

    public lightingFactor = 1.0;
    public lightingOffset = 0.0;

    private _surfaceTypes?:CBARSurfaceType[]
    public get surfaceTypes() {
        if (!this._surfaceTypes) {
            const all = getEnumValues(CBARSurfaceType) as CBARSurfaceType[]
            return all.filter(s=>s !== CBARSurfaceType.None)
        }
        return this._surfaceTypes
    }

    constructor(context:CBARContext) {
        super(context);
        this.cameraProperties = new CBARCameraIntrinsics(context);
        this.geometry = new CBARSceneGeometry(context);

        this.indexMaskTexture = new CBARMaskTexture(context, CBARTextureType.alpha);
        this.lightingTexture = new CBARTexture(context, CBARTextureType.lightMap);
        this.placeholderTexture = new CBARTexture(context, CBARTextureType.albedo);

        this.prepare()
    }

    private convertLegacyScene(legacyScene:CBScenePropertiesLegacy) : CBARSceneProperties {
        const scene:CBARSceneProperties = {
            id:generateUUID(),
            name:"generated",
            version: "4.0",
            camera: {
                fov: legacyScene.fov,
                position: [0,legacyScene.cameraPosition[1], 0],  //intentionally drop translation that's not elevation.
                rotation: legacyScene.cameraRotation
            },
        };

        scene.images = {
            "main": legacyScene.images[CBARServerFile.Background],
            "lighting": legacyScene.images['lighting']
        };

        //-json.normal[0], -json.normal[2], json.normal[1]
        scene.geometry = {
            verticalAxis:"y",
            surfaces:[
                {
                    type: CBARSurfaceType.Floor,
                    name: "Floor",
                    normal: [0, 1, 0],
                    offset: 0,
                    axisRotation:-legacyScene.floorRotation,
                    backgroundMean: [159.69641203703702],
                    backgroundStdDev: [50.28374538680241],
                    lightingMean: [230.61737060546875],
                    lightingStdDev: [32.248200233034275],
                    images:{
                        "mask": legacyScene.images["masks"]["floor"]
                    }
                }
            ]
        };

        console.log("Legacy scene converted.");
        console.log(JSON.stringify(scene, null, 4));
        return scene;
    };

    private _isEditable = false;

    public get isEditable() {
        return this._isEditable
    }

    load(basePath:string|undefined, _json:any, surfaceTypes?:CBARSurfaceType[], room?:string|null|undefined, subroom?:string|null|undefined) {

        let version:string = _json.formatVersion;
        version = version ? version : _json.version;
        version = version ? version : "2.0";
        version = `${version}`

        const versionParts = version.split(".").map(s=>Number.parseInt(s));
        const versionMajor = versionParts.length > 0 && versionParts[0] ? versionParts[0] : 2

        const json = versionMajor == 2.0 ? this.convertLegacyScene(_json) : _json as CBARSceneProperties;

        if (!json.version) {
            json.version = version;
        }

        if (!basePath) {
            basePath = `${this.context.config.hostingUrl}/${json.id}`
        }

        this._isEditable = basePath.indexOf("s3.") >= 0;//todo: this is really a bad indicator

        return new Promise<CBARScene>((resolve, reject) => {

            if (json.name) {
                this.name = json.name
            }

            if (surfaceTypes) {
                this._surfaceTypes = surfaceTypes
            }

            const promises:any[] = [];

            if (json.camera) {
                promises.push(this.cameraProperties.load(basePath, json.camera))
            }

            if (json.floorRotation) {
                this.groundRotation = -1.0 * json.floorRotation; //deprecated and was z up
            }

            if (json.geometry) {
                promises.push(this.geometry.load(basePath, json.geometry, this.groundRotation, surfaceTypes, room, subroom))
            }

            if (json.assets) {
                promises.push(this.assets.load(basePath, json.assets))
            }

            if (json.images) {
                if (json.images.hasOwnProperty("roomId")) {
                    delete json.images["roomId"];
                }
                if (!json.images.hasOwnProperty("main") && Object.values(json.images).length) {
                    const firstPath = Object.values(json.images)[0];
                    json.images["main"] = `${firstPath.substr(0, firstPath.lastIndexOf("/"))}/background`;
                }
                promises.push(this.images.load(basePath, json.images))
            }

            this.lightingFactor = json.lightingFactor ? json.lightingFactor : 1.0;
            this.lightingOffset = json.lightingOffset ? json.lightingOffset : 0.0;

            if (json.environment) {
                const url = `${basePath}/${json.environment}`;

                promises.push(new Promise((resolve, reject)=>{
                    console.log(`Loading HDR environment: ${url}`);
                    new RGBELoader()
                        .setDataType(THREE.UnsignedByteType)
                        .load(url, (texture)=>{
                            console.log("Successfully loaded HDR environment: ", url);
                            const envMap = pmremGenerator.fromEquirectangular( texture ).texture;
                            this.context.gl.scene.environment = envMap;

                            texture.dispose();
                            pmremGenerator.dispose();

                            resolve(this)
                        }, undefined, ()=>{
                            reject(new Error("Could not load HDR environment"))
                        });

                    const pmremGenerator = new THREE.PMREMGenerator( this.context.gl.renderer );
                    pmremGenerator.compileEquirectangularShader();
                }))
            }
            getConfig().then(config=>{

                if (config.placeholderPath) {
                    promises.push(this.placeholderTexture.load(undefined, config.placeholderPath));
                }

                Promise.all(promises).then(()=>{

                    if (this.backgroundImage) {
                        this.imageSize = new THREE.Vector2(this.backgroundImage.image.width, this.backgroundImage.image.height)
                    } else {
                        reject(new CBARError("No background image found."));
                    }

                    //optional. Old api-s 3.0 and lower use individual plane_masks/mask.png
                    if (this.indexMask) {
                        this.indexMaskTexture.loadImage(this.indexMask);
                    }

                    if (this.lightingImage) {
                        this.lightingTexture.loadImage(this.lightingImage, true);
                    } else {
                        reject(new CBARError("No lighting image found."));
                    }

                    resolve(this)
                }).catch(error=>{
                    reject(error)
                })
            })
        })
    }

    public get backgroundImage() : CBARImage | undefined {
        if (this.images.containsKey(CBARServerFile.Background)) {
            return this.images.values[CBARServerFile.Background] as CBARImage;
        }
    }

    public get lightingImage() : CBARImage | undefined {
        if (this.images.containsKey(CBARServerFile.Lighting)) {
            return this.images.values[CBARServerFile.Lighting] as CBARImage;
        }
    }

    public get indexMask() : CBARImage | undefined {
        if (this.images.containsKey(CBARServerFile.IndexMask)) {
            return this.images.values[CBARServerFile.IndexMask] as CBARImage;
        }
    }

    private _cvIndexMask?:InputArray;
    get cvIndexMask() : InputArray | undefined {
        return this._cvIndexMask;
    }

    private _cvBackground?:InputArray;
    get cvBackground() : InputArray | undefined {
        return this._cvBackground;
    }

    private _cvLightingSmall?:InputArray;
    get cvLightingSmall() : InputArray | undefined {
        return this._cvLightingSmall;
    }

    private _cvBackgroundSmall?:InputArray;
    get cvBackgroundSmall() : InputArray | undefined {
        return this.smallScale < 1.0 ? this._cvBackgroundSmall : this._cvBackground;
    }

    private _smallScale?:number;

    get smallScale() : number {
        return this._smallScale ? this._smallScale : 1.0;
    }

    private async analyzeImages() {
        if (!this.backgroundImage?.image || !this.lightingImage?.image) return

        const bg = this.backgroundImage.image;
        const lighting = this.lightingImage.image;
        const indexMask = this.indexMask?.image;

        PerformanceLogger("analyzeImages", ()=>{
            this._cvBackground = cv.imread(bg);
            this._cvLightingSmall = cv.imread(lighting);
            cv.cvtColor(this._cvLightingSmall, this._cvLightingSmall, cv.COLOR_RGBA2GRAY, 0); //todo: fix on server

            if (indexMask) {
                this._cvIndexMask = cv.imread(indexMask);

                //this._cvIndexMask = extractChannel(this._cvIndexMask, 0)

                if (this._cvIndexMask.channels() == 4) {
                    cv.cvtColor(this._cvIndexMask, this._cvIndexMask, cv.COLOR_RGBA2GRAY, 0)
                } else if (this._cvIndexMask.channels()==3) {
                    cv.cvtColor(this._cvIndexMask, this._cvIndexMask, cv.COLOR_RGB2GRAY, 0)
                }
            }

            //keep them small for rapid access.
            this._smallScale = Math.max(DOWN_SIZE / this._cvBackground.cols, DOWN_SIZE / this._cvBackground.rows);

            if (this._smallScale < 1.0) {
                //resize everything to small size
                const downsize = new cv.Size(this._smallScale * this._cvBackground.cols, this._smallScale * this._cvBackground.rows);

                cv.cvtColor(this._cvBackground, this._cvBackground, cv.COLOR_RGBA2RGB, 0);
                this._cvBackgroundSmall = new cv.Mat();
                cv.resize(this._cvBackground, this._cvBackgroundSmall, downsize);

                cv.resize(this._cvLightingSmall, this._cvLightingSmall, downsize); //not a mistake: for lighting, there is no regular size, only small
            }
        })
    }

    public data() : any {
        return {
            id:this.id,
            camera: this.cameraProperties.data(),
            geometry: this.geometry.data(),
            assets:this.assets.data(),
            images:this.images.data()
        }
    }

    public unload() {
        this.geometry.clearAll();
        this.assets.clearAll();
        this.images.clearAll();

        if (this._cvBackground) {
            this._cvBackground.delete();
            this._cvBackground = undefined;
        }

        if (this._cvBackgroundSmall) {
            this._cvBackgroundSmall.delete();
            this._cvBackgroundSmall = undefined;
        }

        if (this._cvLightingSmall) {
            this._cvLightingSmall.delete();
            this._cvLightingSmall = undefined;
        }

        if (this._cvIndexMask) {
            this._cvIndexMask.delete();
            this._cvIndexMask = undefined;
        }
    }

    public envMap?:THREE.Texture;

    private prepare() {
        if (currentScene) {
            currentScene.unload()
        }
        currentScene = this

        //console.log("OpenCV version info", cv.getBuildInformation())

        //https://hdrihaven.com/
        //const url = 'https://threejs.org/examples/textures/piz_compressed.exr'
    }

    public render(options:RenderOptions={}) {
        this.context.gl.renderer.clear();

        this.assets.render(options);
        this.geometry.render(options);

        if (RENDER_DEPTH) {
            this.context.gl.renderer.setRenderTarget(this.context.gl.renderTarget);
            this.context.gl.renderer.render(this.context.gl.scene, this.context.gl.camera);
            this.context.gl.renderer.setRenderTarget(null);
        }

        this.context.gl.renderer.render(this.context.gl.scene, this.context.gl.camera);
    }

    private _assetsByState: Map<string, CBARAsset|undefined> = new Map([ ]);

    public assetFor(state:CBARHighlightState) : CBARAsset|undefined {
        return this._assetsByState.get(state);
    }

    public setAssetFor(state:CBARHighlightState, asset:CBARAsset|undefined, event?:CBAREvent) {
        if (this.context.isDrawing && (state === CBARHighlightState.Selected || state === CBARHighlightState.Drag)) return; //ignore

        const existing = this.assetFor(state);
        if (existing) {
            existing.setState(state, false, event);
            this._assetsByState.set(state, existing);
        }
        if (asset) {
            asset.setState(state, true, event);
        }
        this._assetsByState.set(state, asset);
    }

    private _surfacesByState: Map<string, CBARSurface|undefined> = new Map([ ]);

    public surfaceFor(state:CBARHighlightState) : CBARSurface|undefined {
        return this._surfacesByState.get(state);
    }

    public setSurfaceFor(state:CBARHighlightState, surface: CBARSurface|undefined, event?:CBAREvent) {

        const existing = this.surfaceFor(state);
        if (existing) {
            existing.setState(state, false, event);
            this._surfacesByState.set(state, existing);
        }
        if (surface) {
            surface.setState(state, true, event);
        }
        this._surfacesByState.set(state, surface);
    }

    public handleEvent(event: CBAREvent) {

        this.assets.handleEvent(event);
        this.geometry.handleEvent(event);

        if (!isDrawingTool(this.context.toolMode)) {
            window.setTimeout(()=>{
                event.context.getHandlers(event.type).forEach(handler=>handler(event))
            }, 0);
        }
    }

    toolModeChanged(mode:CBARToolMode) {
        this.assets.toolModeChanged(mode);
        this.geometry.toolModeChanged(mode);
    }

    sceneLoaded() {
        this.analyzeImages().then();
        PerformanceLogger("Scene loading", ()=>{
            this.assets.sceneLoaded();
            this.geometry.sceneLoaded();
        });
    }

    get description() : string {
        return "Scene"
    }

    getEventObjects(eventType:CBAREventType) : THREE.Object3D[] {
        return this.assets.getEventObjects(eventType).concat(this.geometry.getEventObjects(eventType))
    }

    private _animationEnd = 0;

    public requestAnimation(duration=1000) : Promise<void> {
        this._animationEnd = Math.max(Date.now() + duration + 100, this._animationEnd)
        return usleep(duration)
    }

    public get animating() {
        return this._animationEnd - Date.now() > 0;
    }
}
